import { useCallback, useEffect, useState } from 'react';

import axiosPrivate from 'config/axiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminOrderDisplaySelector,
  updateAffectedTextAmount,
} from 'store/Admin/OrderDisplay';

import { CustomAutocomplete } from 'components/shared/DropDown/CustomAutocomplete';
import { Loader } from 'components/shared/Loader';
import { ProgressBar } from 'components/shared/ProgressBar';
import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { disabledInput } from 'components/shared/disabledClasses';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

import { ROLES } from 'utils/Constants';
import { lower } from 'utils/StringUtils';

import ErrorComponent from 'components/shared/ErrorComponent';
import { useTranslation } from 'react-i18next';
import {
  removeOrderLine,
  setOrderLineList,
  updateOrderLine,
} from 'store/Admin/OrderDisplay';

const API = {
  deleteOrderLine: '/commandLine',
  updateOrderLine: '/commandLine',
  getAllWriters: '/user/all',
};

const columns = [
  { label: 'Rédacteurs', field: 'writers' },
  { label: 'Deadline', field: 'deadline' },
  { label: 'Tarif/Mot', field: 'price' },
  { label: 'Min/Max mots', field: 'minMax' },
  { label: 'Nb textes', field: 'textAmount' },
  { label: 'Nb mots/texte', field: 'wordsManagedNumber' },
  { label: 'Au réel', field: 'actualBilling' },
  { label: 'Livrés', field: 'deliveredTextsAmount' },
  { label: 'Validés', field: 'validatedTextsAmount' },
  { label: 'Coût d’achat', field: 'cost' },
  { label: 'Total facturé', field: 'totalBill' },
  { label: 'Supprimer', field: 'delete' },
];

export const OrderLineDetails = (props) => {
  let {
    order,
    orderId,
    orderLineList,
    remainingTextAmount,
    isOrderLineLoading,
    isOrderLineError,
  } = useSelector(adminOrderDisplaySelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addWriterDisabled, setAddWriterDisabled] = useState(false);
  const [handleSubmitDisabled, setHandleSubmitDisabled] = useState(false);

  const [selectedOrderLine, setSelectedOrderLine] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredAddedOrders, setFilteredAddedOrders] = useState([]);

  const [allTextAmount, setAllTextAmount] = useState(0);
  // const [textAmount, setTextAmount] = useState(0);

  const [writersList, setWritersList] = useState([]);
  const [addedOrderLineList, setAddedOrderLineList] = useState([]);
  const [isTextAmountChangeDisabled, setIsTextAmountChangeDisabled] =
    useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    axiosPrivate
      .get(API.getAllWriters, { params: { role: ROLES.WRITER } })
      .then((res) => {
        const writers = res.data.map((writer) => {
          return {
            id: writer.id,
            mail: writer.mail,
            isAvailable: writer.isAvailable,
          };
        });
        setWritersList(
          !order.governed
            ? writers
            : writers.filter(
                (writer) => writer.mail === 'Arne.Leen@rajapack.be'
              )
        );
      });
  }, []);

  useEffect(() => {
    setAllTextAmount(
      orderLineList
        .concat(addedOrderLineList)
        .reduce((a, b) => parseInt(a) + (parseInt(b['textAmount']) || 0), 0)
    );
  }, [orderLineList, addedOrderLineList]);

  useEffect(() => {
    setAddWriterDisabled(
      parseInt(remainingTextAmount) - parseInt(allTextAmount) <= 0
    );
  }, [allTextAmount, remainingTextAmount]);

  useEffect(() => {
    if (props.searchValue && props.searchValue.length > 0) {
      setFilteredOrders(
        orderLineList.filter((orderLine) => {
          return lower(orderLine.userMailDTO.mail).includes(
            lower(props.searchValue)
          );
        })
      );
    } else {
      setFilteredOrders([...orderLineList]);
    }
  }, [props.searchValue, orderLineList]);

  useEffect(() => {
    if (props.searchValue && props.searchValue.length > 0) {
      setFilteredAddedOrders(
        addedOrderLineList.filter((orderLine) => {
          return (
            orderLine.userMailDTO === null ||
            lower(orderLine.userMailDTO.mail).includes(lower(props.searchValue))
          );
        })
      );
    } else {
      setFilteredAddedOrders([...addedOrderLineList]);
    }
  }, [props.searchValue, addedOrderLineList]);

  const checkValidForm = useCallback(() => {
    if (addedOrderLineList.concat(orderLineList).length <= 0) {
      setHandleSubmitDisabled(true);
      return;
    }

    if (parseInt(remainingTextAmount) - parseInt(allTextAmount) < 0) {
      setHandleSubmitDisabled(true);
      return;
    }

    if (
      addedOrderLineList.length === 0 &&
      orderLineList.filter((x) => x.userMailDTO).length > 0
    ) {
      let invalid = orderLineList
        .filter((x) => x.userMailDTO)
        .some(
          (x) =>
            !x.deadline ||
            x.minWords === '0' ||
            x.maxWords === '0' ||
            x.minWords > x.maxWords ||
            (!order.governed && parseFloat(x.price) >= 1) ||
            (!order.governed && parseFloat(x.price) <= 0) ||
            (x.actualBilling === false && parseInt(x.wordsManagedNumber) === 0)
        );
      setHandleSubmitDisabled(invalid);
      return;
    }

    if (addedOrderLineList.filter((x) => x.userMailDTO).length > 0) {
      let invalid = addedOrderLineList
        .filter((x) => x.userMailDTO)
        .some(
          (x) =>
            !x.deadline ||
            x.minWords === '0' ||
            x.maxWords === '0' ||
            x.minWords > x.maxWords ||
            (!order.governed && parseFloat(x.price) >= 1) ||
            (!order.governed && parseFloat(x.price) <= 0) ||
            parseInt(x.textAmount) === 0 ||
            (x.actualBilling === false && parseInt(x.wordsManagedNumber) === 0)
        );
      setHandleSubmitDisabled(invalid);
      return;
    }

    let invalid =
      orderLineList.some((x) => !x.userMailDTO) ||
      addedOrderLineList.some((x) => !x.userMailDTO);

    setHandleSubmitDisabled(invalid);
  }, [addedOrderLineList, allTextAmount, orderLineList, remainingTextAmount]);

  const checkTextAmount = useCallback(() => {
    if (parseInt(allTextAmount) > parseInt(remainingTextAmount)) {
      setIsTextAmountChangeDisabled(true);
    } else {
      setIsTextAmountChangeDisabled(false);
    }
  }, [allTextAmount, remainingTextAmount]);

  useEffect(() => {
    checkValidForm();
  }, [
    addedOrderLineList,
    orderLineList,
    allTextAmount,
    remainingTextAmount,
    checkValidForm,
  ]);

  useEffect(() => {
    checkTextAmount();
  }, [allTextAmount, checkTextAmount, remainingTextAmount]);

  const updateValue = (id, option, value) => {
    dispatch(updateOrderLine({ id, option, value }));
  };

  const updateAddedValue = (index, option, value) => {
    let array = [...addedOrderLineList];
    array[index][option] = value;
    setAddedOrderLineList(array);
  };

  const removeAddedOrderLine = (index) => {
    const array = [...addedOrderLineList];
    array.splice(index, 1);
    setAddedOrderLineList([...array]);
  };

  const addWriterOrder = () => {
    setAddedOrderLineList([
      ...addedOrderLineList,
      {
        actualBilling: false,
        deadline: '',
        estimatedCost: '0',
        maxWords: '0',
        minWords: '0',
        price: '0',
        realCost: '0',
        textAmount: 0,
        totalBill: '0',
        userMailDTO: null,
        deliveredTextsAmount: '0',
        validatedTextsAmount: '0',
        wordsManagedNumber: '0',
        writerCommandId: null,
      },
    ]);
  };

  const deleteOrderLine = () => {
    axiosPrivate
      .delete(API.deleteOrderLine + '/' + selectedOrderLine.writerCommandId)
      .then(() => {
        setConfirmation(false);
        dispatch(removeOrderLine(selectedOrderLine.writerCommandId));

        setIsSuccess(true);

        setErrorSuccessMsg(
          `${t('La ligne de commande pour')} ${
            selectedOrderLine.userMailDTO.mail
          } ${t('a été supprimé avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              t(
                'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré'
              )
            );
            break;
          default:
            setErrorSuccessMsg(
              t("Une erreur s'est produite, veuillez réessayer")
            );
            break;
        }
        setErrorSuccess(true);
      });
  };

  function getEligibleWritersList(assignedWriter) {
    let array = [
      ...writersList.filter(
        (item) =>
          !orderLineList
            .concat(addedOrderLineList)
            .some((item2) => item2.userMailDTO?.mail === item.mail)
      ),
    ];

    if (assignedWriter) array = [assignedWriter, ...array];

    return array;
  }

  const handleSubmit = () => {
    let body = orderLineList
      .concat(addedOrderLineList.filter((x) => x.userMailDTO))
      .map((orderLine) => {
        return {
          actualBilling: orderLine.actualBilling,
          deadline: new Date(orderLine.deadline).toISOString(),
          maxWords: orderLine.maxWords,
          minWords: orderLine.minWords,
          price: orderLine.price,
          textAmount: orderLine.textAmount,
          userMailDTO: {
            id: orderLine.userMailDTO.id,
            mail: orderLine.userMailDTO.mail,
          },
          wordsManagedNumber: orderLine.actualBilling
            ? null
            : orderLine.wordsManagedNumber,
          writerCommandId: orderLine.writerCommandId,
        };
      });

    axiosPrivate
      .put(API.updateOrderLine + '/' + orderId, body)
      .then((res) => {
        dispatch(setOrderLineList(res.data));
        dispatch(updateAffectedTextAmount(res.data));
        setAddedOrderLineList([]);
        setErrorSuccessMsg(
          t('Les lignes de commandes ont été mises à jour avec succès !')
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              t('Mise à jour non autorisée car des textes ont été livrés')
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  if (isOrderLineLoading) return <Loader />;

  if (isOrderLineError) return <ErrorComponent />;

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="box-tabs-content">
            <div className="box-wrapper" style={{ marginTop: 15 }}>
              <div className="table-responsive">
                <table className="table-lists table-form">
                  <thead>
                    <tr>
                      {columns.map(({ label, field }, index) => {
                        return index === 6 &&
                          filteredAddedOrders.length === 0 ? null : (
                          <th
                            key={field}
                            style={{
                              cursor: 'pointer',
                              width: field === 'writers' ? '18%' : '',
                            }}
                          >
                            {t(label)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrders.map((orderLine, index) => (
                      <tr key={index}>
                        <td>
                          {orderLine.deliveredTextsAmount > 0 ? (
                            <span>{orderLine.userMailDTO.mail}</span>
                          ) : (
                            <CustomAutocomplete
                              disabled={writersList.length <= 0}
                              list={getEligibleWritersList(
                                orderLine.userMailDTO
                              )}
                              getOptionDisabled={(option) =>
                                option.isAvailable === false
                              }
                              getOptionSelected={(option) =>
                                option.mail === orderLine.userMailDTO.mail
                              }
                              value={orderLine.userMailDTO}
                              setValue={(option) =>
                                updateValue(
                                  orderLine.writerCommandId,
                                  'userMailDTO',
                                  option
                                )
                              }
                              groupBy={(option) => false}
                              getOptionLabel={(option) => {
                                return option.mail;
                              }}
                            />
                          )}
                        </td>
                        <td>
                          <div className="wrapper-date wd-175">
                            <input
                              type="date"
                              className="form-control form-ct"
                              value={orderLine.deadline}
                              onChange={(e) =>
                                updateValue(
                                  orderLine.writerCommandId,
                                  'deadline',
                                  e.target.value
                                )
                              }
                            />
                            <div
                              id="date-button"
                              style={{ backgroundColor: 'white' }}
                              className="box-icon-calendar"
                            >
                              <img
                                src="Images/icon-calendar.png"
                                alt="calendar-icon"
                              />{' '}
                            </div>
                          </div>
                        </td>
                        <td>
                          {!order.governed ? (
                            <div className="number-wrapper wd-90">
                              <input
                                type="number"
                                min="0"
                                className="form-control form-ct"
                                value={orderLine.price}
                                onChange={(e) =>
                                  updateValue(
                                    orderLine.writerCommandId,
                                    'price',
                                    e.target.value
                                  )
                                }
                              />
                              <UpDownInputArrows disabled={false} />
                            </div>
                          ) : (
                            '_'
                          )}

                          {parseFloat(orderLine.price) >= 1 ? (
                            <div className="error-note">
                              {' '}
                              {t(
                                'Ce champs doit être sup à 0 et inférieur à 1'
                              )}
                            </div>
                          ) : null}
                        </td>
                        <td style={{ verticalAlign: 'top' }}>
                          <div
                            className="number-wrapper wd-80"
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            <input
                              type="number"
                              className="form-control form-ct"
                              value={orderLine.minWords}
                              min="0"
                              onChange={(e) =>
                                updateValue(
                                  orderLine.writerCommandId,
                                  'minWords',
                                  e.target.value
                                )
                              }
                            />
                            <UpDownInputArrows disabled={false} />
                          </div>
                          <div
                            className="number-wrapper wd-80"
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            <input
                              type="number"
                              className="form-control form-ct"
                              min={orderLine.maxWords}
                              value={orderLine.maxWords}
                              onChange={(e) =>
                                updateValue(
                                  orderLine.writerCommandId,
                                  'maxWords',
                                  e.target.value
                                )
                              }
                            />
                            <UpDownInputArrows disabled={false} />
                          </div>
                        </td>
                        <td>
                          <div className="number-wrapper wd-80">
                            <input
                              type="number"
                              className="form-control form-ct"
                              value={orderLine.textAmount}
                              min="0"
                              onChange={(e) => {
                                // setTextAmount(parseInt(e.target.value));
                                updateValue(
                                  orderLine.writerCommandId,
                                  'textAmount',
                                  e.target.value
                                );
                              }}
                            />
                            <UpDownInputArrows disabled={false} />
                          </div>
                          {orderLine.deliveredTextsAmount >
                            orderLine.textAmount ||
                          isTextAmountChangeDisabled ? (
                            <div className="error-note">
                              {' '}
                              <span>
                                {t(
                                  'Ce champs doit être sup ou égale nombre de texte'
                                )}
                                <br />
                                {t(
                                  'livré et inférieur au nombre de texte non affecté'
                                )}
                              </span>
                            </div>
                          ) : null}
                        </td>

                        {orderLine.actualBilling === true ? (
                          <td>
                            <span>{t('Au réel')} </span>
                          </td>
                        ) : (
                          <td>
                            <div className="number-wrapper wd-80">
                              <input
                                min="0"
                                type="number"
                                className="form-control form-ct"
                                value={orderLine.wordsManagedNumber}
                                onChange={(e) =>
                                  updateValue(
                                    orderLine.writerCommandId,
                                    'wordsManagedNumber',
                                    e.target.value
                                  )
                                }
                              />
                              <UpDownInputArrows disabled={false} />
                            </div>
                          </td>
                        )}
                        {filteredAddedOrders.length > 0 && (
                          <td>
                            <span></span>
                          </td>
                        )}

                        <td>
                          <ProgressBar
                            amount={orderLine.deliveredTextsAmount}
                            total={orderLine.textAmount}
                          />
                        </td>
                        <td>
                          <ProgressBar
                            amount={orderLine.validatedTextsAmount}
                            total={orderLine.textAmount}
                          />
                        </td>
                        <td>
                          {' '}
                          {!order.governed ? (
                            orderLine.actualBilling ? (
                              <span>{orderLine.estimatedCost.toFixed(2)}€</span>
                            ) : (
                              <span style={{ width: '100px' }}>
                                {orderLine.estimatedCost}/{orderLine.realCost} €
                              </span>
                            )
                          ) : (
                            '_'
                          )}
                        </td>
                        <td>
                          {!order.governed ? (
                            <span>{orderLine.totalBill.toFixed(2)}€</span>
                          ) : (
                            '_'
                          )}
                        </td>
                        <td>
                          {orderLine.deliveredTextsAmount > 0 ? (
                            <span>
                              <img
                                src="Images/icon-trash-gray.png"
                                alt="trash-icon"
                              />
                            </span>
                          ) : (
                            <span
                              style={{
                                cursor: 'pointer',
                                height: '20px',
                                width: '20px',
                              }}
                              className="bt-circle-wh"
                              onClick={() => {
                                setSelectedOrderLine(orderLine);
                                setConfirmationMsg(
                                  `${t(
                                    'Voulez-vous vraiment supprimer la ligne de commande pour'
                                  )} ${orderLine.userMailDTO.mail} ?`
                                );
                                setConfirmation(true);
                              }}
                            >
                              <img
                                src="Images/icon-trash.png"
                                alt="trash-icon"
                              />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}

                    {filteredAddedOrders.map((orderLine, index) => (
                      <tr key={index}>
                        <td>
                          {
                            <CustomAutocomplete
                              disabled={writersList.length <= 0}
                              list={getEligibleWritersList(
                                orderLine.userMailDTO
                              )}
                              getOptionDisabled={(option) =>
                                option.isAvailable === false
                              }
                              getOptionSelected={(option) =>
                                option.mail === orderLine.userMailDTO.mail
                              }
                              value={orderLine.userMailDTO}
                              groupBy={(option) => false}
                              setValue={(option) =>
                                updateAddedValue(index, 'userMailDTO', option)
                              }
                              getOptionLabel={(option) => {
                                return option.mail;
                              }}
                            />
                          }
                        </td>
                        <td>
                          <div className="wrapper-date wd-175">
                            <input
                              type="date"
                              className="form-control form-ct"
                              value={orderLine.deadline}
                              onChange={(e) =>
                                updateAddedValue(
                                  index,
                                  'deadline',
                                  e.target.value
                                )
                              }
                            />
                            <div
                              id="date-button"
                              style={{ backgroundColor: 'white' }}
                              className="box-icon-calendar"
                            >
                              <img
                                src="Images/icon-calendar.png"
                                alt="icon-calendar"
                              />{' '}
                            </div>
                          </div>
                        </td>

                        <td>
                          {!order.governed ? (
                            <div className="number-wrapper wd-90">
                              <input
                                type="number"
                                min="0"
                                className="form-control form-ct"
                                value={orderLine.price}
                                onChange={(e) => {
                                  updateAddedValue(
                                    index,
                                    'price',
                                    e.target.value
                                  );
                                }}
                              />
                              <UpDownInputArrows disabled={false} />
                            </div>
                          ) : (
                            '_'
                          )}

                          {!order.governed &&
                          parseFloat(orderLine.price) >= 1 ? (
                            <div className="error-note">
                              {' '}
                              {t(
                                'Ce champs doit être sup à 0 et inférieur à 1'
                              )}
                            </div>
                          ) : null}
                        </td>
                        <td style={{ verticalAlign: 'top' }}>
                          <div
                            className="number-wrapper wd-80"
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            <input
                              type="number"
                              className="form-control form-ct"
                              value={orderLine.minWords}
                              min="0"
                              onChange={(e) =>
                                updateAddedValue(
                                  index,
                                  'minWords',
                                  e.target.value
                                )
                              }
                            />
                            <UpDownInputArrows disabled={false} />
                          </div>
                          <div
                            className="number-wrapper wd-80"
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            <input
                              type="number"
                              className="form-control form-ct"
                              min={orderLine.minWords}
                              value={orderLine.maxWords}
                              onChange={(e) =>
                                updateAddedValue(
                                  index,
                                  'maxWords',
                                  e.target.value
                                )
                              }
                            />
                            <UpDownInputArrows disabled={false} />
                          </div>
                        </td>
                        <td>
                          <div className="number-wrapper wd-80">
                            <input
                              type="number"
                              className="form-control form-ct"
                              min="0"
                              max={
                                parseInt(remainingTextAmount) -
                                parseInt(allTextAmount)
                              }
                              value={orderLine.textAmount}
                              onChange={(e) =>
                                updateAddedValue(
                                  index,
                                  'textAmount',
                                  e.target.value
                                )
                              }
                            />
                            <UpDownInputArrows disabled={false} />
                          </div>
                        </td>
                        <td>
                          <div className="number-wrapper wd-80">
                            <input
                              type="number"
                              min="0"
                              className="form-control form-ct"
                              value={orderLine.wordsManagedNumber}
                              readOnly={orderLine.actualBilling}
                              style={
                                orderLine.actualBilling ? disabledInput : {}
                              }
                              onChange={(e) =>
                                updateAddedValue(
                                  index,
                                  'wordsManagedNumber',
                                  e.target.value
                                )
                              }
                            />
                            <UpDownInputArrows
                              disabled={orderLine.actualBilling}
                            />
                          </div>
                        </td>
                        <td>
                          {!order.governed ? (
                            <div className="box-checkbox">
                              <input
                                className="form-check-input"
                                value={orderLine.actualBilling}
                                type="checkbox"
                                onChange={(e) =>
                                  updateAddedValue(
                                    index,
                                    'actualBilling',
                                    e.target.checked
                                  )
                                }
                              />
                            </div>
                          ) : (
                            '_'
                          )}
                        </td>
                        <td>
                          <ProgressBar
                            amount={orderLine.deliveredTextsAmount}
                            total={orderLine.textAmount}
                          />
                        </td>
                        <td>
                          <ProgressBar
                            amount={orderLine.validatedTextsAmount}
                            total={orderLine.textAmount}
                          />
                        </td>
                        <td>
                          {!order.governed ? (
                            <span>
                              {orderLine.estimatedCost}/{orderLine.realCost}€
                            </span>
                          ) : (
                            '_'
                          )}
                        </td>
                        <td>
                          {!order.governed ? (
                            <span>{orderLine.totalBill}€</span>
                          ) : (
                            '_'
                          )}
                        </td>
                        <td>
                          <span
                            style={{ cursor: 'pointer' }}
                            className="bt-circle-wh"
                            onClick={() => removeAddedOrderLine(index)}
                          >
                            <img src="Images/icon-trash.png" alt="icon-trash" />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row row-page">
              <div className="col-12 col-md-6">
                <div style={{ marginTop: 10 }}>
                  <span style={{ marginRight: 30 }}>
                    {t('Nombre de textes restants')} :
                    {parseInt(remainingTextAmount) - parseInt(allTextAmount)}{' '}
                  </span>

                  <span>
                    {t('Nombre de textes ajoutés')} :{parseInt(allTextAmount)}{' '}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="box-pagination">
                  {addWriterDisabled ? (
                    <button className="bt-submit-disabled-y me-3">
                      {t('Ajouter un rédacteur')}
                    </button>
                  ) : (
                    <button
                      className="bt-submit-y me-3"
                      onClick={addWriterOrder}
                    >
                      {t('Ajouter un rédacteur')}
                    </button>
                  )}

                  {handleSubmitDisabled ? (
                    <button className="bt-submit-disabled-y">
                      {t('Sauvegarder')}
                    </button>
                  ) : (
                    <button className="bt-submit-y" onClick={handleSubmit}>
                      {t('Sauvegarder')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={deleteOrderLine}
      />
    </div>
  );
};
