import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { clientOrderDisplaySelector } from 'store/Client/OrderDisplay';
import { consultantOrderDisplaySelector } from 'store/Consultant/ConsultantOrderDisplay';
import { writerOrderDisplaySelector } from 'store/Writer/OrderDisplay';

import { UserSessionSelector } from 'store/UserSession';

import {
  CustomAutocomplete,
  CustomAutocompleteDisabled,
} from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { disabledInput } from 'components/shared/disabledClasses';

import { Loader } from 'components/shared/Loader';

import axiosPrivate from 'config/axiosPrivate';
import { ROLES } from 'utils/Constants';
import ClientEvaluation from 'components/Client/Evaluation/ClientEvaluation';
import axiosEvaluation from 'config/axiosEvaluation';

const API = {
  getAllThematics: '/thematic/all',
  getAllLanguages: '/language/all',
};
function OrderDetails(props) {
  const { t } = useTranslation();

  const [availableLanguagesList, setAvailableLanguagesList] = useState([]);
  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [title, setTitle] = useState('');
  const [consultant, setConsultant] = useState(null);

  const [textAmount, setTextAmount] = useState(0);
  const [minWords, setMinWords] = useState(0);
  const [maxWords, setMaxWords] = useState(0);
  const [deadline, setDeadline] = useState(null);
  const [price, setPrice] = useState(0);
  const [orderLanguage, setOrderLanguage] = useState(null);
  const [orderThematic, setOrderThematic] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [clientMail, setClientMail] = useState('');
  const [clientWebSite, setClientWebSite] = useState('');

  const [entitled, setEntitled] = useState('');

  const [detailedBrief, setDetailedBrief] = useState('');
  const [semanticGap, setSemanticGape] = useState('');
  const [clientDocumentation, setClientDocumentation] = useState('');
  const [editor, setEditor] = useState('');
  const [isAllValidated, setIsAllValidated] = useState(true);

  const { user } = useSelector(UserSessionSelector);

  const { order, isLoading } = useSelector(
    user && user.role === ROLES.WRITER
      ? writerOrderDisplaySelector
      : user && user.role === ROLES.CLIENT
      ? clientOrderDisplaySelector
      : consultantOrderDisplaySelector
  );

  // const checkAllValidated = useCallback(async () => {
  //   try {
  //     const res = await axiosEvaluation.get(
  //       `/client/evaluations/allValidatedTexts?command=${order.id}`
  //     );
  //     setIsAllValidated(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [order.id]);
  // useEffect(() => {
  //   if (user.role === ROLES.CLIENT) {
  //     checkAllValidated();
  //   }
  // }, [checkAllValidated, user.role]);

  useEffect(() => {
    axiosPrivate
      .get(API.getAllLanguages)
      .then((res) => setAvailableLanguagesList(res.data));
    axiosPrivate
      .get(API.getAllThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (user && user.role === ROLES.CONSULTANT) {
        setClientList(order.clients?.map((elem) => elem.mail));
        setClientMail(order.clients[0]?.mail);
      }
      if (user && user.role !== ROLES.CONSULTANT) {
        setEditor(order.editorialContact);
      }
      setConsultant(order.consultant);
      setEntitled(order.titled);
      let index = order.title.indexOf('-', order.title.indexOf('-') + 1);
      setTitle(order.title);

      setTextAmount(order.textAmount);
      setDetailedBrief(order.detailedBrief);
      setClientDocumentation(order.clientDocumentation);
      setSemanticGape(order.semanticGap);

      setMinWords(order.minWords);
      setMaxWords(order.maxWords);
      setDeadline(order.deadline ? order.deadline.slice(0, 10) : '');
      setPrice(order.price);
      setOrderLanguage(order.language);
      setOrderThematic(order.thematics);
      setClientWebSite(order.website.urlSite);
    }
  }, [order, isLoading, user]);

  const callbackFromMultiSelect = (newList) => {
    setOrderThematic(newList);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="box-tabs-content">
        <div className="box-wrapper ">
          <div className="row row-mb">
            <div className="col-12 col-md-12">
              <div className="label-title">{t('Titre de la commande')}</div>
              <div className="form-control form-ct h-75" style={disabledInput}>
                {title}
              </div>
            </div>
          </div>
          <div className="row row-mb">
            <div className="row row-mb">
              {user &&
              user.role === ROLES.WRITER &&
              editor &&
              editor.length > 0 ? (
                <div className="col-12 col-md-6">
                  <div className="label-title">{t('Chef edito')}</div>
                  <input
                    style={disabledInput}
                    type="text"
                    value={editor}
                    readOnly
                    className="form-control form-ct"
                  />
                </div>
              ) : (
                <div className="col-12 col-md-6">
                  <div className="label-title">{t('Url du site')}</div>
                  <div className="number-wrapper input-group input-gr number-group">
                    <input
                      value={clientWebSite}
                      style={disabledInput}
                      disabled
                      readOnly
                      className="form-control form-ct"
                    />
                  </div>
                </div>
              )}

              {user &&
              user.role === ROLES.ADMIN &&
              user.role === ROLES.CLIENT ? (
                <div className="col-12 col-md-6">
                  <div className="label-title">{t('Consultant')}</div>
                  <input
                    style={disabledInput}
                    type="text"
                    disabled
                    value={consultant ? consultant?.mail : ''}
                    readOnly
                    className="form-control form-ct"
                  />
                </div>
              ) : (
                ''
              )}
              {user && user.role === ROLES.CONSULTANT ? (
                <div className="col-12 col-md-6">
                  <div className="label-title">{t('Mail client')}</div>
                  <CustomAutocompleteDisabled
                    disabled
                    readOnly
                    list={clientList}
                    value={clientMail}
                    onInputChange={(event, value) => setClientMail(value)}
                    setValue={(value) => {
                      const mail = clientList.find((x) => x === value);
                      if (mail) setClientMail(mail);
                      // const index = clientList.findIndex((x) => x === value);
                      // setIndex(index);
                    }}
                    getOptionLabel={(option) => {
                      return option;
                    }}
                  />
                </div>
              ) : (
                ''
              )}

              <div className="col-12 col-md-6">
                <div className="label-title">
                  {t('Nombre de mots par texte')}
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="number-wrapper input-group input-gr number-group">
                      <input
                        value={minWords}
                        style={disabledInput}
                        disabled
                        type="number"
                        className="form-control form-ct"
                      />
                      <UpDownInputArrows disabled={true} />
                      <span style={disabledInput} className="input-group-text">
                        Min.
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="number-wrapper input-group input-gr number-group">
                      <input
                        style={disabledInput}
                        type="number"
                        disabled
                        value={maxWords}
                        readOnly
                        className="form-control form-ct"
                      />
                      <UpDownInputArrows disabled={true} />
                      <span style={disabledInput} className="input-group-text">
                        Max.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-mb">
              {user?.role === ROLES.CLIENT &&
                editor !== null &&
                editor?.length > 0 && (
                  <div className="col-12 col-md-3">
                    <div className="label-title">{t('Chef edito')}</div>
                    <input
                      style={disabledInput}
                      type="text"
                      value={editor}
                      readOnly
                      className="form-control form-ct"
                    />
                  </div>
                )}
              <div className="col-12 col-md-3">
                <div className="label-title">{t('Langue')}</div>
                <CustomAutocomplete
                  disabled={true}
                  readOnly
                  list={availableLanguagesList}
                  value={orderLanguage}
                  setValue={(option) => setOrderLanguage(option)}
                  groupBy={(option) => false}
                  getOptionSelected={(option) =>
                    option.language === orderLanguage.language
                  }
                  getOptionDisabled={(option) => false}
                  getOptionLabel={(option) => t(option.language)}
                />
              </div>
              <div className="col-12 col-md-3">
                <div className="label-title">{t('Nombre de textes')}</div>
                <div className="number-wrapper">
                  <input
                    readOnly
                    type="number"
                    className="form-control form-ct"
                    value={textAmount}
                    disabled
                    style={disabledInput}
                  />
                  <UpDownInputArrows disabled={true} />
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="label-title">{t('Deadline')}</div>
                <div className="wrapper-date">
                  <input
                    readOnly
                    type="text"
                    className="form-control form-ct"
                    disabled
                    style={disabledInput}
                    value={deadline ? deadline : ''}
                  />
                  <div id="date-button" className="box-icon-calendar">
                    <img
                      src="/Images/icon-calendar-no-background.png"
                      alt="icon-calendar"
                    />
                  </div>
                </div>
              </div>
              {order.governed ||
              (user && user.role === ROLES.CLIENT) ||
              user.role === ROLES.CONSULTANT ? null : (
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Tarif au mot')}</div>
                  <div className="number-wrapper input-group input-grl">
                    <span style={disabledInput} className="input-group-text">
                      €
                    </span>
                    <input
                      readOnly
                      type="number"
                      className="form-control form-ct"
                      disabled
                      style={disabledInput}
                      value={price || ''}
                    />
                    <UpDownInputArrows disabled={true} />
                  </div>
                </div>
              )}
            </div>
            <div className="row row-mb">
              <div className="col-12 col-md-4">
                <div className="label-title">{t('Brief détaillé')}</div>
                <div>
                  <input
                    readOnly
                    type="text"
                    style={disabledInput}
                    className="form-control  form-ct"
                    placeholder="Lien drive..."
                    value={detailedBrief}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="label-title">{t('Gap sémantique')}</div>
                <div>
                  <input
                    readOnly
                    type="text"
                    style={disabledInput}
                    className="form-control  form-ct"
                    placeholder="Lien drive..."
                    value={semanticGap}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="label-title">{t('Documentation')}</div>
                <div>
                  <input
                    readOnly
                    type="text"
                    style={disabledInput}
                    className="form-control  form-ct"
                    placeholder="Lien drive"
                    value={clientDocumentation}
                  />
                </div>
              </div>
            </div>
            <div className="row row-mb">
              <div
                className={
                  editor && (editor !== null || editor?.length > 0)
                    ? 'col-12 col-md-6'
                    : 'col-12 col-md-12'
                }
              >
                <div className="label-title">
                  {t('Thématiques d’expertise')}
                </div>
                <CustomChipsAutocomplete
                  callbackFromMultiSelect={callbackFromMultiSelect}
                  value={orderThematic}
                  disabled={true}
                  list={availableThematicsList}
                  getOptionLabel={(option) => t(option.thematic) || ''}
                />
              </div>
              {editor && (editor !== null || editor?.length > 0) ? (
                <div className="col-12 col-md-6">
                  <div className="label-title">{t('Url du site')}</div>
                  <div className="number-wrapper input-group input-gr number-group">
                    <input
                      readOnly
                      value={clientWebSite}
                      style={disabledInput}
                      disabled
                      className="form-control form-ct"
                    />
                  </div>
                </div>
              ) : (
                ''
              )}

              {user &&
                user.role === ROLES.CLIENT &&
                isAllValidated === true && (
                  <>
                    <ClientEvaluation orderId={order.id} user={user} t={t} />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
