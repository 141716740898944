export const germanTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Die Bestellungen wurden erfolgreich aktualisiert!',
  'Le texte a été enregistré avec succès':
    'Der Text wurde erfolgreich aufgenommen',
  'La traduction a été enregistrée avec succès':
    'Die Übersetzung wurde erfolgreich gespeichert',
  'Texte + Traduction enregistré avec succès':
    'Text + Übersetzung wurde erfolgreich gespeichert',
  'Nombre de mots': 'Anzahl der Wörter',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Vorname/Name des Klienten',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'Das Datum muss später als heute sein.',
  'Contenu rédigé': 'Verfasster Inhalt',
  'Nom/prénom': 'Name/Vorname',
  'Mot de passe incorrect': 'Falsches Passwort',
  Institutions: 'Institutionen',
  'Veuillez donner un descriptif au document téléchargé':
    'Bitte geben Sie dem hochgeladenen Dokument eine Beschreibung',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Weiter zu',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Trennen Sie die Schlüsselwörter durch ein "enter".',
  Allemand: 'Deutsch',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'eine E-Mail mit einem Link zum Zurücksetzen des Passworts an Ihre E-Mail-Adresse gesendet wurde',
  'Chef edito': 'Leiter Edito',
  Croate: 'Kroatisch',
  'Nb de textes': 'Anz. Texte',
  Violet: 'Violett',
  'Ce champs doit être sup ou égale nombre de texte':
    'Dieses Feld muss größer oder gleich der Anzahl der Texte sein.',
  'a été activé avec succès': 'erfolgreich aktiviert wurde',
  Népalais: 'Nepalesisch',
  'Nombre de FAQ': 'Anzahl der FAQs',
  Urdu: 'Urdu',
  'Analyses restantes': 'Verbleibende Analysen',
  Indonésien: 'Indonesisch',
  Breton: 'Bretonisch',
  Livrés: 'Geliefert',
  Associations: 'Vereinigungen',
  'Le client': 'Der Klient',
  'Révision demandée': 'Beantragte Revision',
  "Une erreur s'est produite, veuillez réessayer":
    'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Unerlaubte Aktualisierung, da Texte geliefert wurden',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Um einen Text hinzuzufügen (Optimierung eines bestehenden Inhalts)',
  Gaélique: 'Gälisch',
  'La ligne de commande pour': 'Die Befehlszeile für',
  'Business/Management': 'Business/Management',
  Serbe: 'Serbisch',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Bitte geben Sie unten Ihre Änderungswünsche an und berücksichtigen Sie dabei unsere Bügelbedingungen.',
  'rédacteurs trouvés': 'Autoren gefunden',
  'Kirghiz ; Kirghiz': 'Kirgisisch; Kirgisisch',
  Différence: 'Differenz',
  'en cours': 'in Arbeit',
  'Sami du Nord': 'Sami aus dem Norden',
  Comparer: 'Vergleichen Sie',
  Lituanien: 'Litauisch',
  'Typix - Mon tableau de bord': 'Typix - Mein Anzeiger',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Mindestens 8 Zeichen mit einem Großbuchstaben, einer Zahl oder einem Sonderzeichen haben und nicht mit dem Namen identisch sein.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Bitte geben Sie gültige Felder ein',
  Basque: 'Baskisch',
  'Exporter .csv': '.csv exportieren',
  'Confirmer le nouveau mot de passe': 'Bestätigen Sie das neue Passwort',
  "Pas d'options": 'Keine Optionen',
  'Banque/Assurance': 'Banken/Versicherungen',
  'Vos demandes': 'Ihre Anfragen',
  'Coût d\u2019achat': 'Kosten für den Kauf',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'Der Text wurde erfolgreich gelöscht',
  'Mes Commandes': 'Meine Bestellungen',
  Zoulou: 'Zulu',
  Afficher: 'Anzeigen',
  Texte: 'Text',
  Islandais: 'Isländisch',
  Russe: 'Russisch',
  'Tous les textes': 'Alle Texte',
  Czech: 'Czech',
  'Comparaison hors ligne': 'Offline-Vergleich',
  Téléphonie: 'Telefonie',
  'Valider le texte': 'Text bestätigen',
  'Votre text ne de doit pas dépasser 500 mots':
    'Ihr Text sollte nicht mehr als 500 Wörter umfassen.',
  'Coquilles (double espace, « s »\u2026)':
    'Muscheln (doppeltes Leerzeichen, "s"...)',
  'le consultant': 'der Berater',
  Coréen: 'Koreanisch',
  'Nb de Silo': 'Anzahl der Silos',
  Validés: 'Validiert',
  'non attribuées': 'nicht zugewiesen',
  Néerlandais: 'Niederländisch',
  'Voulez-vous vraiment supprimer': 'Möchten Sie wirklich löschen',
  Rundi: 'Rundi',
  Regional: 'Regional',
  'Compte client': 'Kundenkonto',
  Global: 'Global',
  'Tous les rôles': 'Alle Rollen',
  Musique: 'Musik',
  Perso: 'Persönlich',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Es scheint, als hättest du kein Analyseguthaben mehr',
  Bambara: 'Bambara',
  Sortir: 'Ausgehen',
  optionel: 'optional',
  'Temps restant': 'Verbleibende Zeit',
  Ukrainien: 'Ukrainisch',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Ihr Überprüfungsantrag wurde erfolgreich abgeschickt.',
  'Typix - Créer rédacteur': 'Typix - Autor',
  "n'existe pas": 'existiert nicht',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'kann nicht entfernt werden, da er mit Webseiten verknüpft ist',
  'Commandes en cours': 'Laufende Bestellungen',
  'Textes livrés': 'Gelieferte Texte',
  Validation: 'Validierung',
  'Non attribuées': 'Nicht zugeteilt',
  'Voulez-vous vraiment désactiver': 'Möchten Sie wirklich deaktivieren',
  'Catalan ; Valencien': 'Katalanisch; Valencianisch',
  'Nombre de textes restants': 'Anzahl der verbleibenden Texte',
  'Ne doit pas contenir votre nom': 'Darf Ihren Namen nicht enthalten',
  Documentation: 'Dokumentation',
  Kazakh: 'Kasachisch',
  'Numéro invalide': 'Ungültige Nummer',
  'Veuillez saisir un texte ou importer un fichier.':
    'Bitte geben Sie einen Text ein oder importieren Sie eine Datei.',
  'Nb de FAQ': 'Nr. der FAQs',
  Analyser: 'Analysieren',
  Turc: 'Türkisch',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Alle Wünsche, die im Briefing nicht spezifiziert wurden',
  Wallon: 'Wallonisch',
  Birmane: 'Burmesisch',
  Irlandais: 'Irisch',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Ihr Passwort zurücksetzen',
  Cuisine: 'Küche',
  'Haïtien ; créole haïtien': 'Haitianisch; Haitianisches Kreolisch',
  'Vous ne pouvez pas supprimer le rédacteur':
    'Sie können den Autor nicht löschen',
  Validé: 'Freigegeben',
  "cet email n'existe pas": 'diese E-Mail existiert nicht',
  Historique: 'Geschichte',
  Confirmer: 'Bestätigen',
  'à cocher si le nombre de mots est différent par texte':
    'ankreuzen, wenn die Anzahl der Wörter pro Text unterschiedlich ist',
  'Fiche du site web ': 'Karteikarte der Website',
  Désactiver: 'Deaktivieren',
  'Travaux/BTP': 'Bauarbeiten/Bauwesen',
  'La fiche du website': 'Der Steckbrief der Website',
  'Si le contenu ne respecte pas les règles du brief':
    'Wenn der Inhalt nicht den Regeln des Briefings entspricht',
  Norvégien: 'Norwegisch',
  '0 rédacteurs': '0 Autoren',
  indisponible: 'nicht verfügbar',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Überarbeitete Texte',
  'Status texte': 'Status Text',
  'textes livrés en attente de validation':
    'gelieferte Texte, die auf ihre Freigabe warten',
  'Typix - Mon Profile': 'Typix - Mein Profil',
  'le client': 'der Klient',
  Romanche: 'Romanche',
  'URL du site': 'URL der Seite',
  'nouvelles notifications': 'neue Benachrichtigungen',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maledivisch',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Sind Sie sicher, dass Sie den Text speichern wollen',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Bitte fügen Sie einen Anfangstext hinzu, damit Sie den Text als Entwurf speichern können.',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Der Vertrag für den Kunden',
  rédacteurs: 'Autoren',
  'Ce champ est obligatoire': 'Dieses Feld ist obligatorisch',
  'Tarif/Mot': 'Tarif/Wort',
  Tadjik: 'Tadschikisch',
  'Réf devis': 'Ref Kostenvoranschlag',
  'Rechercher un rédacteur': 'Nach einem Autor suchen',
  Albanais: 'Albanisch',
  Galicien: 'Galizisch',
  Disponibilité: 'Verfügbarkeit',
  'Les deux mots de passe ne sonts pas identiques':
    'Die beiden Passwörter sind nicht identisch.',
  Tibétain: 'Tibetisch',
  'Typix - Gestion des utilisateurs': 'Typix - Benutzerverwaltung',
  'Ossétien ; Ossétique': 'Ossetisch; Ossetisch',
  Tswana: 'Tswana',
  'Ajouter un client': 'Einen Klienten hinzufügen',
  'Séparez les mots-clés par un " Enter ".':
    'Trennen Sie die Stichwörter durch ein "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Themen',
  'Nb mots/texte': 'Anz. Wörter/Text',
  Commerce: 'Handel',
  'Voir les commandes': 'Bestellungen ansehen',
  'Nombre de mots par texte': 'Anzahl der Wörter pro Text',
  'Lien drive...': 'Link drive...',
  'Hollandais ; Flamand': 'Niederländisch; Flämisch',
  'Mon profil': 'Mein Profil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Dieses Feld muss größer als 0 sein, da die Rechnungsstellung nicht real erfolgt.',
  'Le texte a été validé avec succès': 'Der Text wurde erfolgreich validiert',
  Jeux: 'Spiele',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Möchten Sie wirklich die Befehlszeile für',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Nord; Ndebele Nord',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Kirchenslawisch; Altkirchenslawisch; Kirchenslawisch; Altbulgarisch; Altkirchenslawisch',
  Swahili: 'Suaheli',
  Voyage: 'Reise',
  'Nombre de mot à ajouter': 'Anzahl der Wörter, die hinzugefügt werden sollen',
  'Liste des rédacteurs': 'Liste der Autoren',
  'le rédacteur': 'der Autor',
  Kanuri: 'Kanuri',
  rédacteur: 'Autor',
  Tatar: 'Tatar',
  'Roumain ; Moldave ; Moldovan': 'Rumänisch; Moldawisch; Moldovan',
  'Le texte initial de': 'Der ursprüngliche Text von',
  'Mail clients': 'Mail Kunden',
  'Le contrat de': 'Der Vertrag von',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'mindestens ein Kleinbuchstabe und ein Großbuchstabe,ein Sonderzeichen und eine Zahl',
  Transport: 'Transport',
  'Nombre total de mots (existants et ajoutés) :':
    'Gesamtanzahl der Wörter (vorhandene und hinzugefügte) :',
  'Rechercher par mail, prénom, nom, site...':
    'Suche nach E-Mail, Vorname, Name, Website...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Anzahl der hinzuzufügenden Wörter (Min/Max) :',
  Compte: 'Konto',
  'Tourisme/Loisirs': 'Tourismus/Freizeit',
  'Nouveau texte': 'Neuer Text',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; Grönländisch',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Ups...!Es gibt einen/mehrere Fehler im Fenster des semantischen Gaps. Bitte überprüfen Sie den Inhalt, um ihn/sie zu korrigieren, und reichen Sie Ihren Text erneut ein.',
  'ne peut pas être supprimé pour le moment':
    'kann derzeit nicht gelöscht werden',
  'Le texte': 'Der Text',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Ein Benutzer existiert bereits mit der E-Mail',
  Cree: 'Cree',
  Prénom: 'Vorname',
  'commandes prête': 'bestellungen bereit',
  Réinitialiser: 'Zurücksetzen',
  'Veuillez fournir une date valide': 'Bitte geben Sie ein gültiges Datum an',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxemburgisch; Letzeburgesch',
  Suédois: 'Schwedisch',
  'Confirmer le mot de passe': 'Bestätigen Sie das Passwort',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Dieses Feld ist obligatorisch und kann nur Zahlen enthalten.',
  Sciences: 'Wissenschaft',
  Tchétchène: 'Tschetschenisch',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'ist der einzige Client für eine Site, es müssen weitere hinzugefügt werden, um ihn entfernen zu können',
  'Le mot de passe de confirmation ne correspond pas':
    'Das Bestätigungspasswort stimmt nicht überein',
  Swati: 'Swati',
  Danois: 'Dänisch',
  Sauvegarder: 'Speichern',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Bitte ändern Sie die folgenden Informationen: Titel und/oder Schlüsselwort',
  'importer un document': 'ein Dokument importieren',
  Maltais: 'Malteser',
  'Nb de mots': 'Anzahl der Wörter',
  'Les données fournies sont invalides':
    'Die bereitgestellten Daten sind ungültig',
  'Livraison estimée': 'Geschätzte Lieferung',
  'Grec moderne (1453-)': 'Neugriechisch (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Gesamtanzahl der hinzugefügten Wörter',
  'le contenu présente des inexactitudes':
    'der Inhalt Ungenauigkeiten aufweist',
  Finnois: 'Finnisch',
  'Ajouter un admin': 'Admin hinzufügen',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Berater ändern',
  'Liste des clients': 'Liste der Klienten',
  'Nom client': 'Name Kunde',
  Vert: 'Grün',
  'Mots clés manquants': 'Fehlende Schlüsselwörter',
  Lao: 'Lao',
  Somali: 'Somali',
  'Nb de silo par texte': 'Anz. Silos pro Text',
  Italien: 'Italienisch',
  'La commande a été mis à jour avec succès !':
    'Die Bestellung wurde erfolgreich aktualisiert!',
  Bosniaque: 'Bosnisch',
  'Thématiques d\u2019expertise': 'Themenbereiche der Expertise',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Dieses Passwort ist das Passwort, das Sie vergessen haben',
  'a été désactivé avec succès': 'wurde erfolgreich deaktiviert',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Auf die Bestellung posten',
  'mot-clé': 'Stichwort',
  Abkhazien: 'Abchasisch',
  Mongolien: 'Mongolisch',
  'Typix - Fiche site web': 'Typix - Website-Steckbrief',
  'Mail envoyé': 'Gesendete Mail',
  Géorgien: 'Georgisch',
  'Si des phrases ne sont pas dans un français correct':
    'Wenn Sätze nicht in korrektem Französisch sind',
  'En cours': 'In Arbeit',
  voir: 'siehe',
  "Date d'ajout": 'Datum der Hinzufügung',
  'Tarif au mot': 'Tarif pro Wort',
  Thai: 'Thai',
  'Détails du texte': 'Textdetails',
  'Commandes en retard': 'Überfällige Bestellungen',
  disponible: 'verfügbar',
  Droit: 'Recht',
  Azerbaïdjanais: 'Aserbaidschanisch',
  'Ajouter un consultant': 'Berater hinzufügen',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Beim Bügeln von Inhalten werden nur die folgenden Elemente übernommen',
  'doit comprendre au moins 8 charactères':
    'muss mindestens 8 Zeichen umfassen',
  'Titre de la commande': 'Titel der Bestellung',
  'Langues bihari': 'Bihari-Sprachen',
  'Livraison estimé au': 'Geschätzte Lieferung bis',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) Autor(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Die Anzahl der Texte in der Bestellung kann nicht geringer sein als die Anzahl der Texte, die dem/den Redakteur(en) zugewiesen wurden. Bitte ändern Sie zunächst die Informationen in der Befehlszeile',
  'Date de livraison': 'Datum der Lieferung',
  'Voulez-vous vraiment supprimer le contrat de':
    'Möchten Sie wirklich den Vertrag von',
  'Facturation au réel': 'Tatsächliche Abrechnung',
  'Gap sémantique': 'Semantische Lücke',
  Estonien: 'Estnisch',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Max. Kaufkosten/Wort',
  'Détail de la commande': 'Detail der Bestellung',
  'Export en cours de téléchargement\u2026':
    'Export während des Herunterladens...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Dieses Feld ist obligatorisch und muss kleiner oder gleich der Gesamtanzahl der Textedes Auftrags sein.',
  Arabe: 'Arabisch',
  Bislama: 'Bislama',
  'Mail client': 'Kundenmail',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Titel, Schlüsselwörter, Url, Tag, Meta-Description)',
  'Typix - Nouvelle commande': 'Typix - Neuer Auftrag',
  'Révision en attente': 'Revision anstehend',
  'Le consultant': 'Der Berater',
  Entreprise: 'Unternehmen',
  Aragonais: 'Aragonesisch',
  Kashmiri: 'Kashmiri',
  'a été activé': 'aktiviert wurde',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Internationaler Verband der Hilfssprachen)',
  Actualités: 'Nachrichten',
  'Votre décision a été prise en compte avec succès':
    'Ihre Entscheidung wurde erfolgreich berücksichtigt',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'inhalt wartet auf kundenprüfung',
  'Fiche du rédacteur': 'Steckbrief des Autor',
  Outillage: 'Werkzeuge',
  'Typix - Détails de la commande': 'Typix - Details zur Bestellung',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Fügen Sie unten Ihren Inhalt hinzu oder ergänzen Sie ihn :',
  Ewe: 'Ewe',
  'contenu en attente de repasse rédacteur': 'inhalt wartet auf bügel Autor',
  'La commande a été supprimé avec succès':
    'Der Befehl wurde erfolgreich gelöscht',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Deutsche Ausdrücke oder Sätze, die Ihnen aber nicht zusagen',
  Corse: 'Korsika',
  Modifier: 'Bearbeiten',
  'Nb de GMB': 'Anz. GMB',
  Oromo: 'Oromo',
  Polish: 'Polish',
  'Insérez votre gap sémantique avec l’occurrence':
    'Fügen Sie Ihren semantischen Gap mit dem Vorkommen ein',
  'Nb de textes à optimiser': 'Anzahl der zu optimierenden Texte',
  Mode: 'Modus',
  Akan: 'Akan',
  'Révisions effectués': 'Durchgeführte Revisionen',
  'Modifier l\u2019admin': 'Admin bearbeiten',
  'Télécharger le document': 'Dokument herunterladen',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Anmerkungen der Autoren',
  Quechua: 'Quechua',
  Féroïen: 'Färöisch',
  'Modifier le rédacteur': 'Redakteur bearbeiten',
  Mail: 'Mail',
  Rédacteurs: 'Autoren',
  'Typix - Détails du texte': 'Typix - Textdetails',
  Hongrois: 'Ungarisch',
  'Bon pour livraison': 'Gutschein für die Lieferung',
  'Au réel': 'In Wirklichkeit',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebräisch',
  'Nombre total de mots ajoutés :': 'Gesamtanzahl der hinzugefügten Wörter :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Muss kleiner sein als die Höchstzahl',
  'Nom du texte': 'Name des Textes',
  'Ajouter un rédacteur': 'Redakteur hinzufügen',
  Ou: 'Oder',
  Utilisateurs: 'Nutzer',
  Heures: 'Stunden',
  'Santé/Bien-être': 'Gesundheit/Wellness',
  'Interlingue ; Occidental': 'Interlingual; Okzidentalisch',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Lettisch',
  Anglais: 'Englisch',
  Envoyer: 'Senden Sie',
  'Tableau de bord': 'Anzeiger',
  'Demande de révision': 'Antrag auf Überprüfung',
  'Résultat sémantique': 'Semantisches Ergebnis',
  'Chercher un rédacteur': 'Einen Autor suchen',
  'Ce champs est obligatoire': 'Dieses Feld ist obligatorisch',
  Rédaction: 'Redaktion',
  'Modifier le client': 'Client bearbeiten',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Möchten Sie den Autor wirklich löschen',
  Titre: 'Titel',
  Langue: 'Sprache',
  'Les demandes suivantes ne seront pas prises en compte':
    'Folgende Anträge werden nicht berücksichtigt',
  'délai dépassé': 'Frist überschritten',
  Tchouvache: 'Tschuwaschisch',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Bereits bestehender Vertrag, bitte ändern Sie die Informationen',
  Igbo: 'Igbo',
  Yiddish: 'Yiddish',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgisch',
  'Commandes validées': 'Bestätigte Bestellungen',
  'listeRedacteur-': 'listeRedakteur-',
  Étape: 'Schritt',
  'Energie/Environnement': 'Energie/Umwelt',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom':
    'darf Ihren Namen/Vornamen nicht enthalten',
  'Typix - Fiche rédacteur': 'Typix - Redaktionsblatt',
  'révisions effectuées en attentes de validation':
    'Revisionen, die in Erwartung der Validierung durchgeführt wurden',
  'Textes validés': 'Bestätigte Texte',
  Art: 'Kunst',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Vielseitig',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Gesamtanzahl der Texte',
  'Nombre de mots gérés': 'Anzahl der verwalteten Wörter',
  "L'émail": 'Der Zahnschmelz',
  'Afficher les résultats': 'Ergebnisse anzeigen',
  'Frison occidental': 'Westfriesisch',
  'Modification effectuée avec succès': 'Änderung erfolgreich durchgeführt',
  'Balise titre': 'Titel-Tag',
  'Typix - Mon profil': 'Typix - Mein Profil',
  'email invalide': 'ungültige E-Mail',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Bitte fügen Sie eine Url hinzu, damit Sie den Text als Entwurf speichern können.',
  Macédonien: 'Mazedonisch',
  'Votre candidature a bien été envoyée':
    'Ihre Bewerbung wurde erfolgreich versendet',
  'E-mail': 'E-Mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Fügen Sie unten Ihren Inhalt hinzu oder ergänzen Sie ihn',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Hinzufügen von Schlüsselwörtern mit der verbleibenden Häufigkeit',
  'Fiche du site web': 'Karteikarte der Website',
  Contacter: 'Kontaktieren Sie',
  Fidjien: 'Fidschianisch',
  Déconnexion: 'Abmelden',
  email: 'E-Mail',
  'Nombre de textes': 'Anzahl der Texte',
  'Enregistrer et informer client': 'Kunde registrieren und informieren',
  'Date de validation': 'Datum der Validierung',
  'Tonga (Îles Tonga)': 'Tonga (Tonga-Inseln)',
  Javanais: 'Javanisch',
  Portugais: 'Portugiesisch',
  Biélorusse: 'Weißrussisch',
  'Pour ajouter un nouveau texte': 'So fügen Sie einen neuen Text hinzu',
  'Enfant/Puéri': 'Kind/Puper',
  'Mots clés': 'Schlüsselwörter',
  Emploi: 'Beschäftigung',
  'Voulez-vous vraiment supprimer la commande de':
    'Möchten Sie wirklich die Bestellung von',
  'Mot clé': 'Schlüsselwort',
  Consultants: 'Berater',
  Volapük: 'Volapük',
  Services: 'Dienste',
  'mot-clé (fréquence)': 'Schlüsselwort (Häufigkeit)',
  'Ne plus afficher ce message': 'Diese Nachricht nicht mehr anzeigen',
  Consultant: 'Berater',
  "Une erreur s'est produite": 'Ein Fehler ist aufgetreten',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Mindestens 8 Zeichen mit einem Großbuchstaben, einer Zahl oder einem Sonderzeichen haben',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Französisch',
  validées: 'validiert',
  'Url du site': 'Url der Seite',
  Précédent: 'Vorherige',
  'car celui-ci a déjà rédigé des textes':
    'da dieser bereits Texte verfasst hat',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Entwürfe',
  Ido: 'Ido',
  'Contenu disponible': 'Verfügbarer Inhalt',
  Enseignement: 'Unterricht',
  'a été livré avec succès': 'wurde erfolgreich geliefert',
  Chinois: 'Chinesisch',
  Vietnamien: 'Vietnamesisch',
  'Nouveau client': 'Neuer Klient',
  'Votre profil a été mis à jour avec succès.':
    'Ihr Profil wurde erfolgreich aktualisiert.',
  Consignes: 'Hinweise',
  'Mot de passe': 'Passwort',
  Annuler: 'Abbrechen',
  Non: 'Nicht',
  Nom: 'Name',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'es gibt Bestellungen, die mit der Webseite verbunden sind',
  Score: 'Punktzahl',
  'Mot de passe mis à jour avec succès': 'Passwort erfolgreich aktualisiert',
  Latin: 'Lateinisch',
  Technologie: 'Technologie',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'wurde erfolgreich erstellt',
  Roumain: 'Rumänisch',
  Assamais: 'Assamais',
  'Khmer central': 'Zentral-Khmer',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Pandschabi; Punjabi',
  'Analyse/Limite': 'Analyse/Grenzwert',
  Grec: 'Griechisch',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Meine Projekte',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n...',
  'ajouter un texte': 'einen Text hinzufügen',
  'Détail de la prestation': 'Detail der Leistung',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Sie haben die Möglichkeit, den Bericht für eine, oder mehrere Bestellungen zu exportieren. Um alle Bestellungen eines Kunden zu exportieren, klicken Sie einfach auf das gelbe Download-Symbol',
  'Gestion des utilisateurs': 'Verwaltung von Nutzern',
  'livré et inférieur au nombre de texte non affecté':
    'geliefert und kleiner als die Anzahl der nicht zugewiesenen Texte',
  'Date deadline validation client': 'Date deadline validation client',
  Aymara: 'Aymara',
  Validées: 'Validiert',
  Notes: 'Anmerkungen',
  'Nombre de GMB': 'Anzahl der GMB',
  'résultats sur': 'Ergebnisse auf',
  'Prénom / Nom du client': 'Vorname / Name des Klienten',
  'Tout télécharger': 'Alle herunterladen',
  'Champs obligatoires': 'Obligatorische Felder',
  Éditer: 'Bearbeiten',
  'Coller le texte initial dans le champs ci-dessous':
    'Fügen Sie den ursprünglichen Text in das Feld unten ein',
  'ID rédacteur': 'ID Autor',
  Arménien: 'Armenisch',
  'Grande distribution': 'Großer Einzelhandel',
  Welsh: 'Welsh',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Semantische Anreicherung',
  'Détail de la prestation du contenu': 'Detail der Inhaltsleistung',
  Noir: 'Schwarz',
  'Ajouter un texte': 'Text hinzufügen',
  Traduction: 'Übersetzung',
  'Immo/Defisc': 'Immo/Defisc',
  'Brief détaillé': 'Ausführlicher Brief',
  'Séparez les mots-clés par un Enter .':
    'Trennen Sie die Stichwörter durch ein Enter .',
  Prix: 'Preis',
  'mot de passe oublié': 'Passwort vergessen',
  'Ndebele, South ; South Ndebele': 'Ndebele, Süd; Süd Ndebele',
  'Demander une révision': 'Eine Überprüfung beantragen',
  'Demander une repasse globale': 'Eine umfassende Überprüfung beantragen',
  'Sotho, Southern': 'Sotho, Southern',
  'Le titre de la commande existe déjà':
    'Der Titel des Auftrags existiert bereits',
  'Félicitations !': 'Herzlichen Glückwunsch!',
  Kurde: 'Kurdisch',
  Adresse: 'Adresse',
  Espagnol: 'Spanisch',
  Ndonga: 'Ndonga',
  'En retard': 'Überfällig',
  'a été ajouté avec succès': 'wurde erfolgreich hinzugefügt',
  Commandes: 'Bestellungen',
  'a été mis en brouillon': 'wurde in Draft gesetzt',
  'Rédacteur attribué': 'Zugewiesener Autor',
  Rechercher: 'Suche',
  'Nouvelle commande': 'Neue Bestellung',
  'Commandes non attribuées': 'Nicht zugewiesene Bestellungen',
  'Le texte a été mis en brouillon': 'Der Text wurde in den Entwurf gesetzt',
  'Contact edito': 'Kontakt edito',
  Langues: 'Sprachen',
  Orange: 'Orange',
  'Nynorsk norvégien': 'Norwegischer Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmenisch',
  'contenu repassé, en attente validation client':
    'Inhalt gebügelt, wartet auf Kundenfreigabe',
  'Nombre de mots rédigés': 'Anzahl der geschriebenen Wörter',
  Malay: 'Malay',
  'Nombre de Silo': 'Anzahl der Silos',
  Oui: 'Ja',
  Automobile: 'Automobil',
  Résultats: 'Ergebnisse',
  Afar: 'Afar',
  'Tâche - id tâche': 'Aufgabe - id Aufgabe',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Dieses Feld ist obligatorisch muss größer als 0 sein',
  'Vous êtes sûr': 'Sind Sie sicher',
  'Réinitialiser votre mot de passe': 'Ihr Passwort zurücksetzen',
  Sindhi: 'Sindhi',
  Bengali: 'Bengali',
  'Bokmål, norvégien ; Bokmål norvégien':
    'Bokmål, norwegisch; Bokmål norwegisch',
  'Nombre de Jours': 'Anzahl der Tage',
  'Min/Max mots': 'Min/Max Wörter',
  Slovène: 'Slowenisch',
  Site: 'Website',
  'Sinhala ; Cingalais': 'Sinhala; Singhalesisch',
  Bulgare: 'Bulgarisch',
  'Liste des commandes': 'Liste der Bestellungen',
  "L'utilisateur": 'Der Nutzer',
  Uzbek: 'Usbekisch',
  'Titre du texte': 'Titel des Textes',
  Postuler: 'Bewerben',
  'Nb de textes par Silo': 'Anzahl der Texte pro Silo',
  'Nombre total de mots (existants et ajoutés)':
    'Gesamtanzahl der Wörter (vorhandene und hinzugefügte)',
  'Max.': 'Max.',
  Beauté: 'Schönheit',
  Humanitaire: 'Humanitär',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Suche nach Autor oder Text',
  'Balise meta-description': 'Meta-Description-Tag',
  Malgache: 'Madagassisch',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Dieses Feld muss größer als 0 und kleiner als 1 sein.',
  'Le rédacteur': 'Der Autor',
  'a été mis à jour avec succès': 'wurde erfolgreich aktualisiert',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Mindestens 8 Zeichen mit einem Großbuchstaben, einer Zahl oder einem Symbol haben und nicht mit dem Namen identisch sein.',
  'textes en plus': 'Zusatztexte',
  'Format souhaité': 'Gewünschtes Format',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Detail Befehlszeile',
  'Veuillez préciser la date': 'Bitte geben Sie das Datum an',
  Pali: 'Pali',
  Clients: 'Kundinnen und Kunden',
  'Total facturé': 'In Rechnung gestellter Gesamtbetrag',
  'contenu validé par le client': 'vom Kunden freigegebener Inhalt',
  'Occitan (post 1500)': 'Okzitanisch (nach 1500)',
  Japonais: 'Japanisch',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Möchten Sie wirklich aktivieren',
  Sanskrit: 'Sanskrit',
  'Toutes les notifications': 'Alle Benachrichtigungen',
  Marathi: 'Marathi',
  Persan: 'Perser',
  'La commande pour le client': 'Die Bestellung für den Kunden',
  'Intitulé de la commande': 'Titel der Bestellung',
  Samoan: 'Samoanisch',
  Autres: 'Andere',
  Sport: 'Sport',
  "il y'a des commandes liés au site web ":
    'es gibt Bestellungen, die mit der Webseite verbunden sind',
  'Ajout de mots-clé avec la fréquence restante':
    'Hinzufügen von Schlüsselwörtern mit der verbleibenden Häufigkeit',
  'a été supprimé avec succès': 'wurde erfolgreich gelöscht',
  Postulé: 'Postuliert',
  Literie: 'Bettwäsche',
  'Édité par le client': 'Vom Kunden editiert',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Texte',
  'Soumettre un nouveau texte': 'Einen neuen Text einreichen',
  Téléphone: 'Telefon',
  Deadline: 'Deadline',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe oder versuchen Sie es später noch einmal.',
  Marshallais: 'Marshallais',
  "La demande d'ajout de crédit est envoyée":
    'Der Antrag auf Hinzufügung eines Guthabens wird gesendet',
  'Le rédacteur ': 'Der Autor',
  'Demander des crédits': 'Kredite beantragen',
  "Cette commande n'existe pas": 'Dieser Befehl existiert nicht',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitianisch',
  Admins: 'Admins',
  'Mettez des mots-clés avec fréquence':
    'Setzen Sie Schlüsselwörter mit Häufigkeit',
  Notifications: 'Benachrichtigungen',
  'en retard': 'verspätet',
  "l'admin": 'der Admin',
  'Mail rédacteurs': 'Mail Autoren',
  Amharique: 'Amharisch',
  Societe: 'Gesellschaft',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Anzahl der hinzugefügten Texte',
  Société: 'Unternehmen',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'Der Text wurde erfolgreich geliefert',
  'Mettez votre texte': 'Setzen Sie Ihren Text',
  "Oups....! Une erreur s'est produite": 'Ups....! Ein Fehler ist aufgetreten',
  'Prénom/Nom': 'Vorname/Name',
  'Max doit être sup à Min': 'Max muss größer als Min sein',
  'Importer un autre document': 'Ein anderes Dokument importieren',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Ihr Antrag ist abgelaufen ... Bitte stellen Sie einen neuen Antrag.',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Bitte korrigieren Sie das Format Ihre semantische Gap, damit Sie den Text als Entwurf speichern können.',
  Exemple: 'Beispiel',
  Slovaque: 'Slowakisch',
  Finance: 'Finanzen',
  Réactiver: 'wieder aktivieren',
  'Nb textes': 'Nb Texte',
  'Intitulé commande': 'Titel der Bestellung',
  Supprimer: 'Löschen',
  Brouillon: 'Entwurf',
  'Mots clés restants': 'Verbleibende Schlüsselwörter',
  Date: 'Datum',
  Rôle: 'Rolle',
  'Ce champs doit être sup à 0': 'Dieses Feld muss größer als 0 sein',
  'Maison/Déco': 'Haus/Deko',
  'Vous avez': 'Sie haben',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Diese Befehlszeile kann nicht gelöscht werden, da ein Teil ihres Textes ausgeliefert wurde',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'überprüfen Sie Ihre E-Mail und klicken Sie auf den Link, um fortzufahren!',
  Sarde: 'Sardisch',
  'Note rédacteur': 'Anmerkung Autor',
  'Uighur ; Uyghur': 'Uighur; Uyghur',
  'Commandes intelligentes': 'Smart Bestellungen',
  'Commandes avec révision': 'Smart + review Bestellungen',
  'Type de contenu': 'Inhaltstyp',
  'Fiche produit': 'Produktblatt',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Premium-Schreiben',
  'Rédaction avec révision': ' Smart + review Schreiben',
  'Rédaction intelligente': 'Smart Schreiben',
  PREMIUM: 'PREMIUM',
  Type: 'Typ',
  Editer: 'Bearbeiten',
  'Tous les types': 'Alle Typen',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Bearbeitete Rechnungen',
  'Factures à valider': 'Zu überprüfende Rechnungen',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Rechnungserstellung nicht möglich! Bitte versuchen Sie es erneut',
  'Récapitulatif de facturation': 'Rechnungszusammenfassung',
  'Demander une modification': 'Änderung anfordern',
  'Valider et envoyer la facture': 'Rechnung bestätigen und senden',
  'Demande de modification': 'Änderungsanfrage',
  'La facture a été validé avec succès!':
    'Die Rechnung wurde erfolgreich validiert!',
  "Oups un erreur c'est produit!": 'Ups, es ist ein Fehler aufgetreten!',
  'Nombre de texte': 'Anzahl der Texte',
  'Nombre de mot par texte :': 'Wortanzahl pro Text:',
  Paiement: 'Zahlung',
  Virement: 'Überweisung',
  Délai: 'Frist',
  'Référence de votre facture': 'Ihre Rechnungsreferenz',
  'Coût au mot': 'Kosten pro Wort',
  'Montant global de la commande': 'Gesamtbetrag der Bestellung',
  'Votre facture': 'Ihre Rechnung',
  'Importer votre facture': 'Ihre Rechnung importieren',
  'Pas de fichier disponible': 'Keine Datei verfügbar',
  Télécharger: 'Herunterladen',
  Relancer: 'Erneut senden',
  'Générer la facture': 'Rechnung generieren',

  Payée: 'Bezahlt',
  'En cours de paiement': 'Zahlung in Bearbeitung',
  'En cours de validation': 'Validierung in Bearbeitung',
  'A envoyer': 'Zu senden',
  'Modification demandée': 'Änderung angefordert',
  'Référence de facture est obligatoire': 'Rechnungsreferenz ist erforderlich',
  'Le coût au mot doit être un nombre':
    'Die Kosten pro Wort müssen eine Zahl sein',
  'Le coût au mot doit être un nombre positif':
    'Die Kosten pro Wort müssen eine positive Zahl sein',
  'Le coût au mot est obligatoire': 'Die Kosten pro Wort sind erforderlich',
  'Le montant global de la commande doit être un nombre':
    'Der Gesamtbetrag der Bestellung muss eine Zahl sein',
  'Le montant global de la commande doit être un nombre positif':
    'Der Gesamtbetrag der Bestellung muss eine positive Zahl sein',
  'Le montant global de la commande est obligatoire':
    'Der Gesamtbetrag der Bestellung ist erforderlich',
  'Bill reference existe déja , veuillez réessayer':
    'Rechnungsreferenz existiert bereits, bitte versuchen Sie es erneut',
  'La facture a été envoyée avec succès!':
    'Die Rechnung wurde erfolgreich versendet!',
  'Montant estimé': 'Geschätzter Betrag',
  Action: 'Aktion',
  Facture: 'Rechnung',
  Statut: 'Status',
  Factures: 'Rechnungen',
  'Valider la facture': 'Rechnung bestätigen',
  'La relance a été envoyée avec succès !':
    'Die Erinnerung wurde erfolgreich gesendet!',
  'La demande de modification a été envoyée avec succès !':
    'Die Änderungsanfrage wurde erfolgreich gesendet!',
  'Le cout est trop élevé': 'Die Kosten sind zu hoch',
  'Le montant global de la commande est trop élevé ':
    'Der Gesamtbetrag der Bestellung ist zu hoch',
  jours: 'Tage',
  'Factures à envoyer': 'Rechnungen zu senden',
  'Valider le paiement': 'Zahlung bestätigen',
  'Générer une facture': 'Rechnung generieren',
  'Montant rédacteur': 'Autorenhonorar',
  'Montant estimé par Typix': 'Geschätzter Betrag von Typix',
  'Voir la modification': 'Änderung anzeigen',

  'Tag de Titre': 'Titel-Tag',
  'Meta description': 'Meta-Beschreibung',
  'Nom du produit': 'Produktname',
  Avantages: 'Vorteile',
  'Description détaillée': 'Ausführliche Beschreibung',
  'En cours de modification': 'In Bearbeitung',
  'Mes Factures': 'Meine Rechnungen',
  'En attente de validation': 'In Bearbeitung',
  'les valeurs négatives ne sont pas autorisées':
    'Negative Werte sind nicht erlaubt.',
  'Texte Seulement': 'Nur Text',
  'Traduction Seulement': 'Nur Übersetzung',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Die Erinnerung kann 20 Tage nach Rechnungseinreichung gesendet werden',
  Instructions: 'Anweisungen',
  'Votre avis pour la commande': 'Ihre Meinung zur Bestellung',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Ihre Bewertung ist wertvoll für uns! Danke!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Bei der Übermittlung Ihrer Bewertung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut!',
  Evaluer: 'Bewerten',
  Commentaire: 'Kommentar',
  'Votre commantaire': 'Ihr Kommentar',
  Note: 'Bewertung',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Ihr Feedback ist wertvoll für uns und hilft uns, unsere Dienstleistungen zu verbessern.',
  'Verifier la facture': 'Überprüfen Sie die Rechnung',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Ihr Feedback ist für uns wertvoll und hilft uns, unsere Dienstleistungen zu verbessern. Vielen Dank, dass Sie Ihre Bewertung mit uns teilen!',
  'Notes rédacteur': 'Autorenanmerkungen',
  'Avis client': 'Kundenbewertung',
  'Pas de commantaire': 'Kein Kommentar',
  "Qu'avez vous pensé de votre commande?":
    'Was haben Sie von Ihrer Bestellung gehalten?',
  'Evaluation de la commande': 'Bestellbewertung',
  'Rédiger un commentaire': 'Kommentar schreiben',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Tragen Sie dazu bei, dass wir Ihnen durch das Teilen Ihrer Kommentare Dienstleistungen von höchster Qualität anbieten können.',
  'Que pouvons-nous améliorer?': 'Was können wir verbessern?',
  Continuer: 'Fortsetzen',
  'Le dernier texte a été validé avec succès':
    'Der letzte Text wurde erfolgreich validiert',
  'Rechercher par rédacteur, commandes, et montant':
    'Nach Autor, Bestellungen und Betrag suchen',
  obligatoire: 'obligatorisch',
  'Note admin': 'Admin-Hinweis',
  'Note client': 'Kundenhinweis',
  Partie: 'Teil',
  'Vous venez de valider tous les contenus rédigés par ':
    'Sie haben gerade alle Inhalte genehmigt, die von geschrieben wurden ',
  "Qu'en avez vous pensé": 'Was haben Sie davon gehalten',
  'La facture a été générée avec succès !':
    'Die Rechnung wurde erfolgreich generiert!',
  'La taille du titre et/ou la méta-description est trop courte':
    'Die Länge des Titels und/oder der Meta-Beschreibung ist zu kurz',

  'Demande de révision globale': 'Kræver en global revision',
  Valider: 'Bestätigen',
  'Obligatoire en cas d’optimisation ou traduction':
    'Erforderlich bei Optimierung oder Übersetzung',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Ihr Kommentar...',
  'Ex : "Contenus de très bonne qualité': 'Bsp.: "Sehr gute Qualitätsinhalte"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Könnten Sie diesen Autor bitte für zukünftige Bestellungen berücksichtigen?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Diese Schaltfläche dient dazu, den Kunden darüber zu informieren, dass der ursprüngliche Text bereits Keyword-Spamming enthielt',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Text bearbeiten',
  Ajouter: 'Hinzufügen',
  'Demande examen': 'Prüfungsanfrage',
  'Recherche de mots clés à haute densité': 'Keyword stuffing Recherche',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Oops, es scheint, dass in Ihrem Text Keyword Stuffing vorhanden ist: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Oops, es scheint, dass im Originaltext Keyword Stuffing (zu viele Vorkommen eines oder mehrerer Schlüsselwörter) vorhanden ist.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Bitte stellen Sie sicher, dass die Keyword-Dichte für SEO optimiert ist (maximal alle 50 Wörter eine Vorkommen).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Für dies, bitte bearbeiten Sie den bestehenden Text nicht und senden Sie freundlicherweise eine Prüfungsanfrage an den Support.',
  'Votre demande a été bien envoyée !':
    'Ihre Anfrage wurde erfolgreich versendet!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Es gab ein Problem beim Senden Ihrer Anfrage.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Bitte warten Sie auf die Keyword Stuffing-Analyse.',
  'Inserez le maillage interne': 'Fügen Sie das interne Verknüpfung ein',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Bitte stellen Sie sicher, dass die Schlüsselwortdichte für SEO optimiert ist (maximal alle 50 Wörter eine Vorkommnis).\nWenn Sie auf einen Begriff stoßen, der mehrere Bedeutungen haben kann (Beispiel: Former / Formation / Form oder Matelas / Matelassé), können Sie eine Überprüfung beim Support anfordern.',
  'Mots clés à haute densité dans le texte actuel':
    'Schlüsselwörter mit hoher Dichte im aktuellen Text',
  'Mots clés à haute densité dans le texte initial':
    'Schlüsselwörter mit hoher Dichte im ursprünglichen Text',
  'Texte en cours de rédaction !': 'Text wird verfasst !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Sind Sie sicher, dass Sie den Text bestätigen möchten? Stellen Sie sicher, dass Sie die Änderungen zuerst gespeichert haben.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Last-Minute-Information! Dein Kreditguthaben wird bald aufgebraucht sein :( Um vorauszuplanen, zögere nicht, jetzt mehr Credits anzufordern, um deine Bestellung abzuschließen.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Möchten Sie die aktuelle URL bestätigen oder ändern?',
  'Veuillez fournir une URL valide': 'Bitte geben Sie eine gültige URL ein.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Bitte geben Sie unten die URL der Seite an, auf der der Inhalt integriert wurde:',
  'Contenu en ligne': 'Online-Inhalt',
  'Télécharger le document de révision':
    'Das Überarbeitungsdokument herunterladen',
  'Date d’envoi': 'Sendedatum',
  Information: 'Information',
  Enregistrer: 'Speichern',
  'Textes en ligne': 'Texte online',
  'Livrer les textes': 'Die Texte liefern',
  Indisponible: 'Nicht verfügbar',
  Disponible: 'Verfügbar',
  'Les textes ont été livrés avec succès.':
    'Die Texte wurden erfolgreich geliefert.',
  'Le texte a été livré avec succès.': 'Der Text wurde erfolgreich geliefert.',
  'Les textes ont été validés avec succès.':
    'Die Texte wurden erfolgreich validiert.',
  'Le texte a été validé avec succès.': 'Der Text wurde erfolgreich validiert.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Möchten Sie wirklich die Texte der Bestellung validieren?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Möchten Sie wirklich die Texte der Bestellung liefern?',
  'Fiche produit longue': 'Langes Produktdatenblatt',
  'Fiche produit courte': 'Kurzes Produktdatenblatt',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Ein Text ist zu lang und kann nicht nach Excel exportiert werden.',
  'Mettre un texte en brouillon en livré':
    'Einen Text von Entwurf zu geliefert ändern',
  'Remettre un texte validé à livré':
    'Einen validierten Text in geliefert ändern',
  'Invalider le texte': 'Den Text ungültig machen',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Ich möchte mein Passwort ändern: wie mache ich das?',
  'Comment mettre un contenu en bon pour livraison ?':
    'Wie stelle ich den Inhalt für die Lieferung bereit?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'Ich kann meinen Inhalt nicht als "Entwurf" speichern',
  'Typix n’a pas enregistré mes dernières modifications':
    'Hat Typix meine letzten Änderungen nicht gespeichert',
  'Comment rédiger une création de contenu ?':
    'Wie schreibt man eine Inhaltserstellung?',
  'Comment rédiger une optimisation de contenu ?':
    'Wie schreibt man eine Inhaltsoptimierung?',
  'Je ne vois plus ma commande – que faire ?':
    'Ich sehe meine Bestellung nicht mehr – was tun?',
  'Comment générer une facture ?': 'Wie erstellt man eine Rechnung?',
  'Je ne peux pas generer ma facture : que faire ?':
    'Ich kann meine Rechnung nicht erstellen: was tun?',
  'Tuto process Typix global': 'Globaler Typix-Prozess - Tutorial',
  'Tuto mise en ligne des contenus': 'Wie man Inhalte hochlädt - Tutorial',
  'Modification mot de passe': 'Passwortänderung',
  'Le test ne me convient pas': 'Der Test passt mir nicht',
  'Comment valider un contenu dans Typix ?':
    'Wie man Inhalte in Typix validiert?',
  'Tuto Facturation': 'Rechnungserstellung-Tutorial',
  'Le statut du contenu a bien été modifié':
    'Der Status des Inhalts wurde erfolgreich geändert',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Die semantische Lücke dieses Schlüsselworts wurde noch nicht validiert, bitte kontaktieren Sie den Typix-Support: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Dieses Schlüsselwort existiert nicht auf MONETORING, bitte überprüfen Sie mit dem Typix-Support: support-typix@pixalione.com',
  'Récupérer le Gap': 'Lücke abrufen',
  'Le statut du contenu a bien été modifié':
    'Der Status des Inhalts wurde erfolgreich geändert',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Die semantische Lücke dieses Schlüsselworts wurde noch nicht validiert, bitte kontaktieren Sie den Typix-Support: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Dieses Schlüsselwort existiert nicht auf MONETORING, bitte überprüfen Sie mit dem Typix-Support: support-typix@pixalione.com',
  'Récupérer le Gap': 'Lücke abrufen',
  'Traduction premium': 'Premium-Übersetzung',
  'Rédaction et/ou Traduction intelligente':
    'Intelligentes Schreiben und/oder Übersetzung',
  'Rédaction et/ou Traduction premium':
    'Premium-Schreiben und/oder Übersetzung',
  'Rédaction et/ou Traduction avec révision':
    'Schreiben und/oder Übersetzung mit Überarbeitung',
  'Rédaction et/ou Traduction intelligente':
    'Intelligentes Schreiben und/oder Übersetzung',
  'Traduction avec révision': 'Übersetzung mit Überarbeitung',
  'Traduction intelligente': 'Intelligente Übersetzung',
  'Le nombre de mots commandé est supérieur à celui vendu':
    'Die Anzahl der bestellten Wörter ist höher als die verkauften',
  'Rédaction et/ou Traduction premium':
    'Premium-Schreiben und/oder Übersetzung',
  'Rédaction et/ou Traduction smart + review':
    'Intelligentes Schreiben und/oder Übersetzung + Überprüfung',
  'Rédaction et/ou Traduction smart':
    'Intelligentes Schreiben und/oder Übersetzung',
  'Nom Silo': 'Name Silo',
  'Remettre en brouillon': 'Zurück in den Entwurf',
  'Bon pour Livraison Vérifié': 'Zur Lieferung bestätigt',
  'Voulez-vous vraiment remettre en brouillon les Textes de la commande':
    'Möchten Sie die Texte der Bestellung wirklich zurück in den Entwurf setzen',
  'La vérification du texte a été annulée.':
    'Die Textüberprüfung wurde abgebrochen.',
  'Voulez-vous vraiment annuler la vérification du texte?':
    'Möchten Sie die Textüberprüfung wirklich abbrechen?',
  'Etes-vous sûr(e) de vouloir regénérer tous les contenus ?':
    'Sind Sie sicher, dass Sie alle Inhalte neu generieren möchten?',
  'Je suis sûr(e)': 'Ich bin sicher',
  'Non, revenir en arrière': 'Nein, zurückgehen',
  'à cocher si la commande a l’intention d’avoir un rédacteur exclusif pour le client':
    'ankreuzen, wenn die Bestellung beabsichtigt, einen exklusiven Redakteur für den Kunden zu haben',
  Gouvernance: 'Steuerung',
  'Modification à valider': 'Änderung zur Validierung',
  Rédacteur: 'Redakteur',
};
