import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSessionSelector } from 'store/UserSession';
import { useSelector } from 'react-redux';

import PdfReaderModal from 'components/shared/modal/PdfReaderModal';

const WriterFAQ = () => {
  const { user } = useSelector(UserSessionSelector);
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedText, setSelectedText] = useState('');
  const [openPdfModal, setOpenPdfModal] = useState(false);

  const handlePdfDownload = (pdfUrl) => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadIcon = (url, title) => (
    <div>
      <span
        className="bt-download-circle"
        onClick={() => handlePdfDownload(url)}
      />
    </div>
  );

  const faqItems = {
    connectionFaq: [
      {
        text: t('Je souhaiterai modifier mon mot de passe : comment faire ?'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Comment modifier mon mot de passe.pdf',
      },
    ],
    contentFaq: [
      {
        text: t('Comment mettre un contenu en bon pour livraison ?'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Rédacteur/Comment_mettre_un_contenu_en_bon_pour_livraison.pdf',
      },
      {
        text: t('Je ne peux pas mettre mon contenu en « brouillon »'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Rédacteur/Je ne peux pas enregistrer en brouillon.pdf',
      },
      {
        text: t('Typix n’a pas enregistré mes dernières modifications'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Rédacteur/Typix na pas enregistre mes modifications.pdf',
      },
      {
        text: t('Comment rédiger une création de contenu ?'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Rédacteur/Comment rédiger une création de contenu.pdf',
      },
      {
        text: t('Comment rédiger une optimisation de contenu ?'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Rédacteur/Comment rédiger une optimisation de contenu.pdf',
      },
      {
        text: t('Je ne vois plus ma commande – que faire ?'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Rédacteur/Je ne vois plus ma commande que faire .pdf',
      },
    ],
    billingFaq: [
      {
        text: t('Comment générer une facture ?'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Rédacteur/Comment_facturer_sur_Typix.pdf',
      },
      {
        text: t('Je ne peux pas generer ma facture : que faire ?'),
        url: 'https://typixwebsitedocs.blob.core.windows.net/faq/Rédacteur/Je ne peux pas generer ma facture.pdf',
      },
    ],
  };

  const renderFaqSection = (title, items) => (
    <div className="col-12 col-md-8">
      <h2>{title}</h2>
      <div className="box-wrapper">
        <table className="table-lists table-commandes">
          <tbody>
            {items.map((item, index) => (
              <tr key={index} style={{ cursor: 'pointer' }}>
                <td
                  style={{ width: '90%' }}
                  onClick={() => {
                    setOpenPdfModal(true);
                    setSelectedFile(item.url);
                    setSelectedText(item.text);
                  }}
                >
                  {item.text}
                </td>
                <td>{downloadIcon(item.url)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="content-center ">
      <div className=" box-info-header row b-child-1">
        <h1>FAQ</h1>
      </div>
      <div className="row" style={{ position: 'relative' }}>
        <div className="box-img-bg bg-4-1" />
        <div className="box-img-bg bg-5-1" />
        <div className="box-img-bg bg-6" />
        {renderFaqSection(t('Connexion'), faqItems.connectionFaq)}
        {renderFaqSection(t('Contenu'), faqItems.contentFaq)}
        {user?.mail !== 'Arne.Leen@rajapack.be' &&
          renderFaqSection(t('Facturation'), faqItems.billingFaq)}
      </div>
      <PdfReaderModal
        open={openPdfModal}
        handleClose={() => setOpenPdfModal(false)}
        file={selectedFile}
        title={selectedText}
      />
    </div>
  );
};

export default WriterFAQ;
