import React from 'react';
import { Link } from 'react-router-dom';

import Card from './Card';
import { useTranslation } from 'react-i18next';

function UserOrderProgressCards({
  inProgressOrders,
  delayedOrders,
  validatedOrders,
  nonAttributedCommands,
  smartWithReviewOrders,
  smartOrders,
  path,
  isLoading,
  user,
}) {
  const { t } = useTranslation();

  let cardProps = [
    {
      title: t('Commandes non attribuées'),
      icon: 'bt-icon-attr',
      boxBgColor: 'box-pd-orange',
      color: 'bg-orange',
      link: path.nonAttributedCommands,
      length: nonAttributedCommands.length,
    },
    {
      title: t('Commandes en cours'),
      icon: 'bt-icon-en-cour',
      boxBgColor: 'box-pd-blue',
      color: 'bg-blue',
      link: path.inProgressOrders,
      length: inProgressOrders.length,
    },
    {
      title: t('Commandes en retard'),
      icon: 'bt-icon-en-retard',
      boxBgColor: 'box-pd-darkorange',
      color: 'bg-darkorange',
      link: path.delayedOrders,
      length: delayedOrders.length,
    },

    {
      title: t('Commandes validées'),
      icon: 'bt-icon-valid',
      boxBgColor: 'box-pd-green',
      color: 'bg-green',
      link: path.validatedOrders,
      length: validatedOrders.length,
    },
  ];

  if (user?.mail === 'Arne.Leen@rajapack.be') {
    cardProps = cardProps.filter(
      (card) => card.title !== t('Commandes non attribuées')
    );
  }
  return (
    <div className="col-12 col-md-5">
      <div className="row">
        {cardProps.map((card, index) => (
          <div key={index} className="col-12 col-md-6">
            <Link to={card.link}>
              <Card
                title={card.title}
                icon={card.icon}
                boxBgColor={card.boxBgColor}
                color={card.color}
              >
                {!isLoading ? card.length : 'XXX'}
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserOrderProgressCards;
