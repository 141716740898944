import React from 'react';

export default function CheckBox({ name, tick, onCheck }) {
  return (
    <div className="box-checkbox pe-1 d-flex align-items-center">
      <input
        className="form-check-input"
        // className={
        //   name === 'gouvernance-gray'
        //     ? 'form-check-input-governed'
        //     : 'form-check-input'
        // }
        type="checkbox"
        name={name}
        checked={tick}
        onChange={onCheck}
        disabled={name === 'gouvernance-gray' ? true : false}
      />
    </div>
  );
}
