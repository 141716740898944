import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';

import { clientOrderDisplaySelector } from 'store/Client/OrderDisplay';
import { consultantOrderDisplaySelector } from 'store/Consultant/ConsultantOrderDisplay';
import { UserSessionSelector } from 'store/UserSession';
import { writerOrderDisplaySelector } from 'store/Writer/OrderDisplay';

import {
  setOrderId,
  setOrderSiloName,
  setTextType as writerSetTextType,
  updateForm,
} from 'store/Writer/Analyser';
import { fetchWriterText } from 'store/Writer/Analyser/features';

import {
  setClientOrderId,
  setTextType as clientSetTextType,
  setWriterCommandId,
} from 'store/Client/ClientAnalyser';
import { fetchClientText } from 'store/Client/ClientAnalyser/features';

import {
  setConsultantOrderId,
  setTextType as consultantSetTextType,
} from 'store/Consultant/ConsultantAnalyser';
import { fetchConsultantText } from 'store/Consultant/ConsultantAnalyser/features';

import { PAGENUMBERSTINYLIMIT } from 'utils/Constants';

import { ROLES } from 'utils/Constants';
import { lower } from 'utils/StringUtils';
import {
  shouldShowRevisionCheckBox,
  shouldShowValidationCheckBox,
  handleRevisionCheck,
  handleValidationCheck,
} from 'helpers/CheckBoxHelper';

import { Box } from '@mui/material';
import CheckBox from 'components/shared/CheckBox';

import {
  DownloadOneTextWordType,
  DownloadTextsListWordType,
} from 'components/shared/DownloadFile/DownloadTextWordType';
import ErrorComponent from 'components/shared/ErrorComponent';
import { Loader } from 'components/shared/Loader';
import PageNbOptions from 'components/shared/PageNbOptions';
import SearchComponent from 'components/shared/SearchBar/SearchComponent';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import TextCheckBox from 'components/shared/TextCheckBox';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { GenerateBillModal } from 'components/shared/modal/GenerateBill/GenerateBillModal';

import GlobalRevisionRequestModal from '../modal/GlobalRevisionRequestModal';
import DownloadModal from 'components/shared/modal/DownloadModal';

import CardTextIcon from 'icons/CardTextIcon';
import TranslateIcon from 'icons/TranslateIcon';
import { filterTextByStatus, filterTextByType } from 'utils/Constants';

import { getTextColor } from 'helpers/TextColorHelper';
import { textFilterByStatus } from 'helpers/FilterOrders';
import { GlobalTextsValidation } from 'helpers/GlobalTextValidationHelper';

import { useTextFilter } from 'utils/hooks/useTextFilter';

import CustomFilterMenu from '../Filter/CustomFilterMenu';
import { animationUrl } from 'config/endpoints';
import axiosFacturation from 'config/axiosFacturation';

export const OrderTextsDetailsList = (props) => {
  const { user } = useSelector(UserSessionSelector);
  const { t } = useTranslation();

  const { order, textList, isTextLoading, isTextError } = useSelector(
    user && user.role === ROLES.WRITER
      ? writerOrderDisplaySelector
      : user && user.role === ROLES.CLIENT
      ? clientOrderDisplaySelector
      : consultantOrderDisplaySelector
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedStatus, setSelectedStatus] = useState(filterTextByStatus[0]);
  const [selectedType, setSelectedType] = useState(filterTextByType[0]);

  const [searchValue, setSearchValue] = useState('');

  const [currentTexts, setCurrentTexts] = useState([]);
  const [filteredTexts, setFilteredTexts] = useState([]);
  const [sortedText, setOrdredText] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [textsPerPage, setTextsPerPage] = useState(10);

  const [selectedTextsForRevision, setSelectedTextsForRevision] = useState([]);

  const [requestText, setRequestText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [confirmation, setConfirmation] = useState(false);

  const [isTranslate, setIsTranslate] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isCombo, setIsCombo] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [selectedTextsForValidation, setSelectedTextsForValidation] = useState(
    []
  );
  const [factureOpen, setFactureOpen] = useState(false);
  const [isBillGenerated, setIsBillGenerated] = useState(false);
  const [showDownloadModal, setShowDownloadModel] = useState(false);

  const {
    isTextEligibleForValidation,
    availbleRevisionNumber,
    availableValidationNumber,
    isTextEligibleForRevision,
    isTranslateOnly,
    isTextOnly,
    isCombined,
    isAllTextesDelivered,
  } = useTextFilter(sortedText);
  const filterTextByStatusArr = filterTextByStatus;
  const remainingTextAmount = order?.textAmount - textList.length;

  useEffect(() => {
    setOrdredText([
      ...filteredTexts.filter((text) => text['validated'] === false),
      ...filteredTexts.filter((text) => text['validated'] === true),
    ]);
  }, [filteredTexts]);

  useEffect(() => {
    const filtered = textFilterByStatus(textList, selectedStatus);
    setCurrentPage(1);
    setFilteredTexts(filtered);
  }, [selectedStatus, textList]);

  useEffect(() => {
    if (selectedType.field.length > 0) {
      setCurrentPage(1);
      setFilteredTexts(
        textList.filter(
          (text) =>
            (isCombo && text.type === 'COMBINED') ||
            (isTranslate && text.type === 'TRANSLATED') ||
            (isText && text.type === 'NON_TRANSLATED') ||
            (!isCombo && !isTranslate && !isText)
        )
      );
    } else {
      setFilteredTexts([...textList]);
    }
  }, [selectedType, textList]);

  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      setCurrentPage(1);
      setFilteredTexts(
        textList.filter((x) => {
          return lower(x.keyword).includes(lower(searchValue));
        })
      );
    } else {
      setFilteredTexts([...textList]);
    }
  }, [searchValue, textList]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * textsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - textsPerPage;
    setCurrentTexts(sortedText.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(sortedText.length / textsPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, sortedText, textsPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleSearchValueChange = (newValue) => {
    setSearchValue(newValue);
  };

  useEffect(() => {
    if (user?.role !== ROLES.WRITER) return;
    CheckIfBillGenerated();
  }, [order.id, user]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getPath(user, role, orderId, textId, writerCommandId, texteType) {
    if (!user) return;
    switch (role) {
      case ROLES.WRITER:
        dispatch(setOrderId(orderId));
        dispatch(fetchWriterText(textId));
        dispatch(writerSetTextType(texteType));
        dispatch(setOrderSiloName(order.nomSilo));
        history.push('/Redacteur/TextDetails');
        break;
      case ROLES.CLIENT:
        dispatch(setClientOrderId(orderId));
        dispatch(fetchClientText(textId));
        dispatch(setWriterCommandId(writerCommandId));
        dispatch(clientSetTextType(texteType));
        history.push('/Client/TextDetails');
        break;

      case ROLES.CONSULTANT:
        dispatch(setConsultantOrderId(orderId));
        dispatch(fetchConsultantText(textId));
        dispatch(consultantSetTextType(texteType));
        history.push('/Consultant/TextDetails');
        break;

      default:
        break;
    }
  }

  const handleValidation = async () => {
    await GlobalTextsValidation(
      selectedTextsForValidation,
      order.id,
      props.orderTextAmount,
      null,
      user?.role,
      setConfirmation,
      setIsSuccess,
      setErrorSuccessMsg,
      setErrorSuccess,
      t,
      dispatch
    );
  };

  const CheckIfBillGenerated = async () => {
    const response = await axiosFacturation.get(
      `/writer/checkIfExist?command id=${order.id}&writer%20id=${user.id}`
    );
    setIsBillGenerated(response.data);
  };

  if (isTextLoading) return <Loader />;

  if (isTextError) return <ErrorComponent />;

  return (
    <div>
      <GlobalRevisionRequestModal
        textIds={selectedTextsForRevision}
        showRevisionModal={showRevisionModal}
        requestText={requestText}
        selectedFile={selectedFile}
        handleClose={() => {
          setShowRevisionModal(false);
          setRequestText(null);
          setSelectedFile(null);
        }}
        setRequestText={(value) => setRequestText(value)}
        setSelectedFile={(value) => setSelectedFile(value)}
        orderId={order.id}
      />

      <div
        className="box-wrapper"
        style={{ height: user.role === ROLES.CONSULTANT ? '525px' : '458px' }}
      >
        <div className="box-flex-cols">
          <div>
            {t('Textes livrés')} : {textList.filter((x) => x.delivered).length}/
            {props.orderTextAmount}
          </div>
          <div>
            {t('Textes validés')} : {textList.filter((x) => x.validated).length}
            /{props.orderTextAmount}
          </div>
        </div>
        <Box display="flex" alignItems="center" marginTop={2}>
          <CustomFilterMenu
            selectedStatus={selectedStatus}
            handleClick={handleClick}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            isTranslateOnly={isTranslateOnly}
            isTextOnly={isTextOnly}
            isCombined={isCombined}
            t={t}
            isText={isText}
            isTranslate={isTranslate}
            isCombo={isCombo}
            setIsTranslate={setIsTranslate}
            setIsText={setIsText}
            setIsCombo={setIsCombo}
            setSelectedStatus={setSelectedStatus}
            filterTextByStatusArr={filterTextByStatusArr}
            filterTextByType={filterTextByType}
            setSelectedType={setSelectedType}
            flag={false}
          />

          <div>
            {shouldShowRevisionCheckBox(
              order?.type,
              user?.role,
              availbleRevisionNumber,
              order.revisionDisabled
            ) && (
              <CheckBox
                name="select-all"
                tick={selectedTextsForRevision.length > 1}
                onCheck={(e) =>
                  handleRevisionCheck(
                    e,
                    sortedText,
                    setSelectedTextsForRevision
                  )
                }
              />
            )}
            {shouldShowValidationCheckBox(
              user?.role,
              availableValidationNumber,
              order?.type
            ) && (
              <CheckBox
                name="select-all"
                tick={
                  selectedTextsForValidation.length ===
                  availableValidationNumber
                }
                onCheck={(e) =>
                  handleValidationCheck(
                    e,
                    sortedText,
                    setSelectedTextsForValidation,
                    isTextEligibleForValidation
                  )
                }
              />
            )}
          </div>

          <SearchComponent
            placeholder={t('Rechercher')}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />

          <div className="ms-2">
            {sortedText.filter((text) => text.delivered).length === 0 ? (
              <span className=" bt-download-circle float-end disabled"></span>
            ) : (
              <span
                className="  bt-download-circle float-end"
                onClick={() =>
                  DownloadTextsListWordType(
                    sortedText.filter((text) => text.delivered),
                    user,
                    setShowDownloadModel
                  )
                }
              ></span>
            )}
          </div>
        </Box>

        <div className="box-wrap-sm">
          <div className="box-list-li">
            {currentTexts.map((text, idx) => {
              return (
                <div key={text.index} className="box-list-n">
                  <div className="col-md-1 ps-1">
                    {user?.role === ROLES.CLIENT && (
                      <TextCheckBox
                        orderType={order.type}
                        text={text}
                        selectedTextsForRevision={selectedTextsForRevision}
                        selectedTextsForValidation={selectedTextsForValidation}
                        setSelectedTextsForRevision={
                          setSelectedTextsForRevision
                        }
                        setSelectedTextsForValidation={
                          setSelectedTextsForValidation
                        }
                        isEligibleForRevision={isTextEligibleForRevision(
                          text,
                          order.revisionDisabled,
                          order.type
                        )}
                        isEligibleForValidation={isTextEligibleForValidation(
                          text
                        )}
                      />
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (
                        user &&
                        user.role === ROLES.CLIENT &&
                        !text.delivered
                      ) {
                        setErrorSuccessMsg(t(`Texte en cours de rédaction !`));
                        setErrorSuccessImg(
                          animationUrl +
                            '/03_Commande_en_cours_de_rédaction.gif'
                        );
                        setIsSuccess(true);
                        setIsInfo(true);
                        setErrorSuccess(true);
                      } else {
                        getPath(
                          user,
                          user.role,
                          order.id,
                          text.id,
                          text.writerCommandId,
                          text.type
                        );
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                    className="col-12 col-md-8"
                  >
                    <span>
                      <span className={`${getTextColor(text)}`}>
                        {(currentPage - 1) * textsPerPage + idx + 1}.{'  '}
                        {(text.type === 'NON_TRANSLATED' ||
                          text.type === 'COMBINED') && <CardTextIcon />}
                        {text.type === 'COMBINED' ? ' + ' : ''}
                        {(text.type === 'TRANSLATED' ||
                          text.type === 'COMBINED') && <TranslateIcon />}
                        {'  '}
                        {text.keyword}
                      </span>
                    </span>
                  </div>
                  <div className="col-md-3 d-flex justify-content-end">
                    {user?.role === ROLES.CONSULTANT &&
                      (text.revisionSent || text.globalRevisionSent) && (
                        <div className=" me-3">
                          <Tooltip title={t('Révision demandée')}>
                            <span>
                              <img
                                src="/Images/eye.png"
                                alt="check icon"
                                style={{ height: '20px' }}
                              />
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    {user?.role === ROLES.CONSULTANT && (
                      <div className=" me-3">
                        {text.deployed && (
                          <Tooltip title={t('Contenu en ligne')}>
                            <span>
                              <img
                                src="/Images/check-mark.png"
                                alt="check icon"
                                style={{ height: '20px' }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    )}
                    <div>
                      <span
                        className="bt-download-circle"
                        onClick={() => DownloadOneTextWordType(text.id, user)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        {user.role === ROLES.CLIENT &&
          availableValidationNumber > 0 &&
          order.type === 'SMART' && (
            <button
              className={
                selectedTextsForValidation.length < 1 ||
                availableValidationNumber < 1
                  ? 'bt-submit-disabled-y'
                  : 'bt-submit-y'
              }
              type="submit"
              onClick={() => {
                setConfirmationMsg(
                  `${t(
                    'Voulez-vous vraiment valider les Textes de la commande'
                  )} ${order.title} ? `
                );
                setConfirmation(true);
              }}
              disabled={
                selectedTextsForValidation.length < 1 ||
                availableValidationNumber < 1
              }
            >
              {t('Valider les textes')}
            </button>
          )}
        {user &&
          user.role === ROLES.CLIENT &&
          order.type !== 'SMART' &&
          availbleRevisionNumber > 1 &&
          !order.revisionDisabled && (
            <button
              className={
                selectedTextsForRevision.length < 2
                  ? 'bt-submit-disabled-y'
                  : 'bt-submit-y'
              }
              type="submit"
              onClick={() => setShowRevisionModal(true)}
              disabled={selectedTextsForRevision.length < 2}
            >
              {t('Demander une repasse globale')}
            </button>
          )}
        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
          {props.orderType !== 'SMART' && (
            <>
              {user?.role === ROLES.WRITER &&
                user?.mail !== 'Arne.Leen@rajapack.be' &&
                isAllTextesDelivered(sortedText) &&
                remainingTextAmount === 0 && (
                  <>
                    <button
                      className={
                        !isBillGenerated
                          ? 'bt-submit-y me-1'
                          : 'bt-submit-disabled-y me-1'
                      }
                      onClick={() => {
                        setFactureOpen(true);
                      }}
                      disabled={isBillGenerated}
                    >
                      {t('Générer une facture')}
                    </button>
                    <GenerateBillModal
                      isOpen={factureOpen}
                      commandID={order.id}
                      handleClose={() => {
                        setFactureOpen(false);
                        setIsBillGenerated(true);
                      }}
                      visualize={false}
                    />
                  </>
                )}
              <div>
                {(order.textType !== 'EDITORIAL' &&
                  order.textType !== 'EDITORIAL_AND_TRANSLATION') ||
                (user && user.role !== ROLES.WRITER) ? null : textList.length >=
                  props.orderTextAmount ? (
                  <div className="bt-submit-disabled-y me-1">
                    {' '}
                    {t('Rédaction')}
                  </div>
                ) : (
                  <div
                    className="bt-submit-y me-1"
                    onClick={() => {
                      dispatch(setOrderId(order.id));
                      dispatch(updateForm(null));
                      dispatch(setOrderSiloName(order.nomSilo));

                      dispatch(writerSetTextType('NON_TRANSLATED'));
                      history.push('/Redacteur/TextDetails');
                    }}
                  >
                    {' '}
                    {t('Rédaction')}
                  </div>
                )}
              </div>
              <div>
                {(order.textType !== 'TRANSLATION' &&
                  order.textType !== 'EDITORIAL_AND_TRANSLATION') ||
                (user && user.role !== ROLES.WRITER) ? null : textList.length >=
                  props.orderTextAmount ? (
                  <div className="bt-submit-disabled-y me-1">
                    {' '}
                    {t('Traduction')}
                  </div>
                ) : (
                  <div
                    className="bt-submit-y me-1"
                    onClick={() => {
                      dispatch(setOrderId(order.id));
                      dispatch(updateForm(null));
                      dispatch(writerSetTextType('TRANSLATED'));
                      dispatch(setOrderSiloName(order.nomSilo));
                      history.push('/Redacteur/TextDetails');
                    }}
                  >
                    {' '}
                    {t('Traduction')}{' '}
                  </div>
                )}
              </div>
              <div>
                {order.textType !== 'EDITORIAL_AND_TRANSLATION' ||
                (user && user.role !== ROLES.WRITER) ? null : textList.length >=
                  props.orderTextAmount ? (
                  <div className="bt-submit-disabled-y me-1">
                    {' '}
                    {t('Rédaction')} + {t('Traduction')}
                  </div>
                ) : (
                  <div
                    className="bt-submit-y me-1"
                    onClick={() => {
                      dispatch(setOrderId(order.id));
                      dispatch(updateForm(null));
                      dispatch(writerSetTextType('COMBINED'));
                      dispatch(setOrderSiloName(order.nomSilo));
                      history.push('/Redacteur/TextDetails');
                    }}
                  >
                    {' '}
                    {t('Rédaction')} + {t('Traduction')}{' '}
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className="d-flex box-pagination">
          <div className="list-page-number me-3 ">
            {pageNumbers
              .slice(
                Math.max(0, currentPage - PAGENUMBERSTINYLIMIT),
                Math.min(currentPage + PAGENUMBERSTINYLIMIT, pageNumbers.length)
              )
              .map((pageNum, index) => (
                <span
                  key={index}
                  className={pageNum === currentPage ? 'active' : ''}
                  onClick={() => setCurrentPage(pageNum)}
                >
                  {pageNum}
                </span>
              ))}
          </div>
          <PageNbOptions
            elementsPerPage={textsPerPage}
            setElementsPerPage={setTextsPerPage}
          />
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        imageUrl={errorSuccessImg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        info={isInfo}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={() => handleValidation()}
      />
      <DownloadModal
        open={showDownloadModal}
        handleClose={() => setShowDownloadModel(false)}
      />
    </div>
  );
};
