export const italianTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Le linee di comando sono state aggiornate con successo!',
  'Le texte a été enregistré avec succès':
    'Il testo è stato registrato con successo',
  'La traduction a été enregistrée avec succès':
    'La traduzione è stata salvata con successo',
  'Texte + Traduction enregistré avec succès':
    'Testo + Traduzione è stata salvata con successo',
  'Nombre de mots': 'Numero di parole',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Nome e cognome del cliente',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'La data deve essere successiva a quella di oggi.',
  'Contenu rédigé': 'Contenuto scritto',
  'Nom/prénom': 'Cognome/nome',
  'Mot de passe incorrect': 'Password errata',
  Institutions: 'Istituzioni',
  'Veuillez donner un descriptif au document téléchargé':
    'Si prega di fornire una descrizione del documento scaricato',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Avanti',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Separare le parole chiave con un "enter".',
  Allemand: 'Tedesco',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    "è stata inviata al vostro indirizzo e-mail un'e-mail contenente un link per la reimpostazione della password",
  'Chef edito': 'Capo redattore',
  Croate: 'Croato',
  'Nb de textes': 'Numero di testi',
  Violet: 'Viola',
  'Ce champs doit être sup ou égale nombre de texte':
    'Questo campo deve essere maggiore o uguale al numero di testo',
  'a été activé avec succès': 'è stato attivato con successo',
  Népalais: 'Nepalese',
  'Nombre de FAQ': 'Numero di FAQ',
  Urdu: 'Urdu',
  'Analyses restantes': 'Analisi rimanenti',
  Indonésien: 'Indonesiano',
  Breton: 'Bretone',
  Livrés: 'Consegnato',
  Associations: 'Associazioni',
  'Le client': 'Il cliente',
  'Révision demandée': 'Richiesta di revisione',
  "Une erreur s'est produite, veuillez réessayer":
    'Si è verificato un errore, riprovare',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Aggiornamento non autorizzato in quanto i testi sono stati consegnati',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Per aggiungere testo (ottimizzare i contenuti esistenti)',
  Gaélique: 'Gaelico',
  'La ligne de commande pour': 'La riga di comando per',
  'Business/Management': 'Affari/Gestione',
  Serbe: 'Serbo',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Vi preghiamo di indicare qui di seguito le vostre richieste di modifica, tenendo conto delle nostre condizioni di stiratura',
  'rédacteurs trouvés': 'scrittori trovati',
  'Kirghiz ; Kirghiz': 'Kirghiz; Kirghiz',
  Différence: 'Differenza',
  'en cours': 'in corso',
  'Sami du Nord': 'Sami del Nord',
  Comparer: 'Confronto',
  Lituanien: 'Lituano',
  'Typix - Mon tableau de bord': 'Typix - Il mio cruscotto',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Deve essere lungo almeno 8 caratteri con una lettera maiuscola, un numero, un carattere speciale e non identico al nome.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Inserire campi validi',
  Basque: 'Basco',
  'Exporter .csv': 'Esportazione .csv',
  'Confirmer le nouveau mot de passe': 'Confermare la nuova password',
  "Pas d'options": 'Nessuna opzione',
  'Banque/Assurance': 'Banche/Assicurazioni',
  'Vos demandes': 'Le vostre richieste',
  'Coût d\u2019achat': 'Costo di acquisto',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès':
    'Il testo è stato cancellato con successo',
  'Mes Commandes': 'I miei ordini',
  Zoulou: 'Zulu',
  Afficher: 'Vista',
  Texte: 'Testo',
  Islandais: 'Islandese',
  Russe: 'Russo',
  'Tous les textes': 'Tutti i testi',
  Czech: 'Ceca',
  'Comparaison hors ligne': 'Confronto offline',
  Téléphonie: 'Telefonia',
  'Valider le texte': 'Convalidare il testo',
  'Votre text ne de doit pas dépasser 500 mots':
    'Il testo non deve superare le 500 parole',
  'Coquilles (double espace, « s »\u2026)':
    'Conchiglie (doppio spazio, "s"...)',
  'le consultant': 'il consulente',
  Coréen: 'Coreano',
  'Nb de Silo': 'Numero di silos',
  Validés: 'Convalidato',
  'non attribuées': 'non allocato',
  Néerlandais: 'Olandese',
  'Voulez-vous vraiment supprimer': 'Volete davvero cancellare',
  Rundi: 'Rundi',
  Regional: 'Regionale',
  'Compte client': 'Conto cliente',
  Global: 'Globale',
  'Tous les rôles': 'Tutti i ruoli',
  Musique: 'Musica',
  Perso: 'Personale',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Sembra che non abbiate più credito di analisi',
  Bambara: 'Bambara',
  Sortir: 'Esci',
  optionel: 'opzionale',
  'Temps restant': 'Tempo rimanente',
  Ukrainien: 'Ucraino',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'La richiesta di revisione è stata inviata.',
  'Typix - Créer rédacteur': 'Typix - Creare un scrittore',
  "n'existe pas": 'non esiste',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'non può essere cancellato perché è collegato a siti web',
  'Commandes en cours': 'Ordini in corso',
  'Textes livrés': 'Testi consegnati',
  Validation: 'Convalida',
  'Non attribuées': 'Non assegnato',
  'Voulez-vous vraiment désactiver': 'Si vuole davvero disattivare',
  'Catalan ; Valencien': 'Catalano; Valenciano',
  'Nombre de textes restants': 'Numero di testi rimanenti',
  'Ne doit pas contenir votre nom': 'Non deve contenere il vostro nome',
  Documentation: 'Documentazione',
  Kazakh: 'Kazako',
  'Numéro invalide': 'Numero non valido',
  'Veuillez saisir un texte ou importer un fichier.':
    'Inserire un testo o importare un file.',
  'Nb de FAQ': 'Numero di FAQ',
  Analyser: 'Analizzare',
  Turc: 'Turco',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Qualsiasi richiesta non specificata nel documento',
  Wallon: 'Vallonia',
  Birmane: 'Birmano',
  Irlandais: 'Irlandese',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Reimpostare la password',
  Cuisine: 'Cucina',
  'Haïtien ; créole haïtien': 'Haitiano; creolo haitiano',
  'Vous ne pouvez pas supprimer le rédacteur':
    "Non è possibile eliminare l'scrittore",
  Validé: 'Convalidato',
  "cet email n'existe pas": 'questa email non esiste',
  Historique: 'La storia',
  Confirmer: 'Confermare',
  'à cocher si le nombre de mots est différent par texte':
    'da spuntare se il numero di parole è diverso per ogni testo',
  'Fiche du site web ': 'Scheda del sito web',
  Désactiver: 'Disattivare',
  'Travaux/BTP': 'Lavori/Costruzioni',
  'La fiche du website': 'La scheda del sito web',
  'Si le contenu ne respecte pas les règles du brief':
    'Se il contenuto non rispetta le regole del brief',
  Norvégien: 'Norvegese',
  '0 rédacteurs': '0 scrittori',
  indisponible: 'non disponibile',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Testi rivisti',
  'Status texte': 'Stato del testo',
  'textes livrés en attente de validation':
    'testi consegnati in attesa di convalida',
  'Typix - Mon Profile': 'Typix - Il mio profilo',
  'le client': 'il cliente',
  Romanche: 'Romanche',
  'URL du site': 'URL del sito',
  'nouvelles notifications': 'nuove notifiche',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maldiviano',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Siete sicuri di voler salvare il testo',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Si prega di aggiungere un testo iniziale per salvare il testo come bozza',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Il contratto per il cliente',
  rédacteurs: 'scrittori',
  'Ce champ est obligatoire': 'Questo campo è obbligatorio',
  'Tarif/Mot': 'Tasso/parola',
  Tadjik: 'Tajik',
  'Réf devis': 'Citazione di riferimento',
  'Rechercher un rédacteur': 'Ricerca di uno scrittore',
  Albanais: 'Albanese',
  Galicien: 'Galiziano',
  Disponibilité: 'Disponibilità',
  'Les deux mots de passe ne sonts pas identiques':
    'Le due password non sono identiche',
  Tibétain: 'Tibetano',
  'Typix - Gestion des utilisateurs': 'Typix - Gestione degli utenti',
  'Ossétien ; Ossétique': 'Osseto; Ossetico',
  Tswana: 'Tswana',
  'Ajouter un client': 'Aggiungere un cliente',
  'Séparez les mots-clés par un " Enter ".':
    'Separare le parole chiave con un "Invio".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Temi',
  'Nb mots/texte': 'Numero di parole/testo',
  Commerce: 'Commercio',
  'Voir les commandes': 'Vedere gli ordini',
  'Nombre de mots par texte': 'Numero di parole per testo',
  'Lien drive...': 'Unità di collegamento...',
  'Hollandais ; Flamand': 'Olandese; fiammingo',
  'Mon profil': 'Il mio profilo',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Questo campo deve essere maggiore di 0 perché la fatturazione non è reale.',
  'Le texte a été validé avec succès':
    'Il testo è stato convalidato con successo',
  Jeux: 'Giochi',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Si vuole davvero rimuovere la linea di comando per',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Nord; Ndebele Nord',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Slavo ecclesiastico; Slavo antico; Slavo ecclesiastico; Bulgaro antico; Slavo ecclesiastico antico',
  Swahili: 'Swahili',
  Voyage: 'Viaggi',
  'Nombre de mot à ajouter': 'Numero di parole da aggiungere',
  'Liste des rédacteurs': 'Elenco dei scrittori',
  'le rédacteur': "l'scrittore",
  Kanuri: 'Kanuri',
  rédacteur: 'scrittore',
  Tatar: 'Tatar',
  'Roumain ; Moldave ; Moldovan': 'Rumeno; moldavo',
  'Le texte initial de': 'Il testo originale della',
  'Mail clients': 'Posta del cliente',
  'Le contrat de': 'Il contratto di',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'almeno una lettera minuscola e una maiuscola, un carattere speciale e un numero',
  Transport: 'Trasporto',
  'Nombre total de mots (existants et ajoutés) :':
    'Numero totale di parole (esistenti e aggiunte) :',
  'Rechercher par mail, prénom, nom, site...':
    'Ricerca per e-mail, nome, cognome, sito...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Numero di parole da aggiungere (Min/Max) :',
  Compte: 'Conto',
  'Tourisme/Loisirs': 'Turismo/tempo libero',
  'Nouveau texte': 'Nuovo testo',
  Max: 'Massimo',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; groenlandese',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Ci sono uno o più errori nella finestra del gap semantico. Si prega di controllare il contenuto per correggerlo/li e di inviare nuovamente il testo.',
  'ne peut pas être supprimé pour le moment':
    'non può essere cancellato in questo momento',
  'Le texte': 'Il testo',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    "Esiste già un utente con l'e-mail",
  Cree: 'Cree',
  Prénom: 'Nome',
  'commandes prête': 'ordini pronti',
  Réinitialiser: 'Reset',
  'Veuillez fournir une date valide': 'Indicare una data valida',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Lussemburghese; Letzeburgesch',
  Suédois: 'Svedese',
  'Confirmer le mot de passe': 'Confermare la password',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Questo campo è obbligatorio e può contenere solo numeri',
  Sciences: 'Scienza',
  Tchétchène: 'Ceceno',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    "è l'unico client per un sito, è necessario aggiungerne altri per rimuoverlo.",
  'Le mot de passe de confirmation ne correspond pas':
    'La password di conferma non corrisponde',
  Swati: 'Swati',
  Danois: 'Danese',
  Sauvegarder: 'Risparmiare',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Modificare le seguenti informazioni: titolo e/o parola chiave',
  'importer un document': 'importare un documento',
  Maltais: 'Maltese',
  'Nb de mots': 'Numero di parole',
  'Les données fournies sont invalides': 'I dati forniti non sono validi',
  'Livraison estimée': 'Consegna stimata',
  'Grec moderne (1453-)': 'Greco moderno (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Numero totale di parole aggiunte',
  'le contenu présente des inexactitudes': 'il contenuto contiene inesattezze',
  Finnois: 'Finlandese',
  'Ajouter un admin': 'Aggiungere un amministratore',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Modificare il consulente',
  'Liste des clients': 'Elenco dei clienti',
  'Nom client': 'Nome del cliente',
  Vert: 'Verde',
  'Mots clés manquants': 'Parole chiave mancanti',
  Lao: 'Lao',
  Somali: 'Somalo',
  'Nb de silo par texte': 'Numero di silos per testo',
  Italien: 'Italiano',
  'La commande a été mis à jour avec succès !':
    "L'ordine è stato aggiornato con successo!",
  Bosniaque: 'Bosniaco',
  'Thématiques d\u2019expertise': 'Temi di competenza',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Questa è la password che avete dimenticato',
  'a été désactivé avec succès': 'è stato disattivato con successo',
  Kongo: 'Kongo',
  'Postuler à la commande': "Richiedere l'ordine",
  'mot-clé': 'parola chiave',
  Abkhazien: 'Abcaso',
  Mongolien: 'Mongolo',
  'Typix - Fiche site web': 'Typix - Sito web',
  'Mail envoyé': 'Posta inviata',
  Géorgien: 'Georgiano',
  'Si des phrases ne sont pas dans un français correct':
    'Se le frasi non sono in francese corretto',
  'En cours': 'In corso',
  voir: 'vedere',
  "Date d'ajout": 'Data aggiunta',
  'Tarif au mot': 'Tariffa per parola',
  Thai: 'Thailandese',
  'Détails du texte': 'Dettagli del testo',
  'Commandes en retard': 'Ordini in ritardo',
  disponible: 'disponibile',
  Droit: 'Legge',
  Azerbaïdjanais: 'Azerbaigian',
  'Ajouter un consultant': 'Aggiungere un consulente',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Quando si riproducono i contenuti, saranno supportati solo i seguenti elementi',
  'doit comprendre au moins 8 charactères': 'deve contenere almeno 8 caratteri',
  'Titre de la commande': "Titolo dell'ordine",
  'Langues bihari': 'Lingue bihari',
  'Livraison estimé au': 'Consegna prevista entro',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    "Il numero di testi nell'ordine non può essere inferiore al numero di testi assegnati agli autori. Modificare prima le informazioni nella riga di comando",
  'Date de livraison': 'Data di consegna',
  'Voulez-vous vraiment supprimer le contrat de': 'Volete davvero abolire il',
  'Facturation au réel': 'Fatturazione effettiva',
  'Gap sémantique': 'Lacuna semantica',
  Estonien: 'Estone',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Costo massimo di acquisto/parola',
  'Détail de la commande': "Dettagli dell'ordine",
  'Export en cours de téléchargement\u2026':
    'Esportazione in corso di caricamento...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    "Questo campo è obbligatorio e deve essere inferiore o uguale al numero totale di testi dell'ordine.",
  Arabe: 'Arabo',
  Bislama: 'Bislama',
  'Mail client': 'Posta del cliente',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Titolo, parole chiave, url, tag, meta-descrizione)',
  'Typix - Nouvelle commande': 'Typix - Nuovo ordine',
  'Révision en attente': 'Recensione in corso',
  'Le consultant': 'Il consulente',
  Entreprise: 'Azienda',
  Aragonais: 'Aragonese',
  Kashmiri: 'Kashmiri',
  'a été activé': 'è stato attivato',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Associazione internazionale delle lingue ausiliarie)',
  Actualités: 'Notizie',
  'Votre décision a été prise en compte avec succès':
    'La vostra decisione è stata presa in considerazione con successo',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'contenuti in attesa di verifica da parte del cliente',
  'Fiche du rédacteur': "File dell'scrittore",
  Outillage: 'Strumenti',
  'Typix - Détails de la commande': "Typix - Dettagli dell'ordine",
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Aggiungete o completate il vostro contenuto qui sotto:',
  Ewe: 'Pecora',
  'contenu en attente de repasse rédacteur':
    "contenuto in attesa dell'scrittore di replay",
  'La commande a été supprimé avec succès':
    "L'ordine è stato cancellato con successo",
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Espressioni o frasi francesi che non vi si addicono',
  Corse: 'Corsica',
  Modifier: 'Modifica',
  'Nb de GMB': 'Numero di GMB',
  Oromo: 'Oromo',
  Polish: 'Polacco',
  'Insérez votre gap sémantique avec l’occurrence':
    "Inserite la vostra lacuna semantica con l'occorrenza",
  'Nb de textes à optimiser': 'Numero di testi da ottimizzare',
  Mode: 'Modalità',
  Akan: 'Akan',
  'Révisions effectués': 'Revisioni effettuate',
  'Modifier l\u2019admin': "Modificare l'amministratore",
  'Télécharger le document': 'Scarica il documento',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Note della scrittori',
  Quechua: 'Quechua',
  Féroïen: 'Faroese',
  'Modifier le rédacteur': "Cambiare l'scrittore",
  Mail: 'Posta',
  Rédacteurs: 'Scrittori',
  'Typix - Détails du texte': 'Typix - Dettagli sul testo',
  Hongrois: 'Ungherese',
  'Bon pour livraison': 'Buono per la consegna',
  'Au réel': 'Nel mondo reale',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Ebraico',
  'Nombre total de mots ajoutés :': 'Numero totale di parole aggiunte :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Deve essere inferiore al numero massimo',
  'Nom du texte': 'Nome del testo',
  'Ajouter un rédacteur': 'Aggiungere un scrittore',
  Ou: 'Oppure',
  Utilisateurs: 'Utenti',
  Heures: 'Orario',
  'Santé/Bien-être': 'Salute/Benessere',
  'Interlingue ; Occidental': 'Interlingua; occidentale',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Lettone',
  Anglais: 'Inglese',
  Envoyer: 'Inviare',
  'Tableau de bord': 'Cruscotto',
  'Demande de révision': 'Richiesta di revisione',
  'Résultat sémantique': 'Risultato semantico',
  'Chercher un rédacteur': 'Ricerca di un scrittore',
  'Ce champs est obligatoire': 'Questo campo è obbligatorio',
  Rédaction: 'scrittoreiale',
  'Modifier le client': 'Modificare il client',
  'Voulez-vous vraiment supprimer le rédacteur':
    "Si vuole davvero eliminare l'scrittore",
  Titre: 'Titolo',
  Langue: 'Lingua',
  'Les demandes suivantes ne seront pas prises en compte':
    'Non saranno prese in considerazione le seguenti domande',
  'délai dépassé': 'scadenza non rispettata',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Contratto esistente, modificare le informazioni',
  Igbo: 'Igbo',
  Yiddish: 'Yiddish',
  'Limburgan ; Limburger ; Limburgish': 'Limburghese; Limburger; Limburghese',
  'Commandes validées': 'Ordini convalidati',
  'listeRedacteur-': 'elencoRedattore-',
  Étape: 'Passo',
  'Energie/Environnement': 'Energia/Ambiente',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom':
    'non deve includere il proprio cognome/nome',
  'Typix - Fiche rédacteur': "Typix - Scheda dell'scrittore",
  'révisions effectuées en attentes de validation':
    'revisioni effettuate in attesa di convalida',
  'Textes validés': 'Testi convalidati',
  Art: 'Arte',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Versatile',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Numero totale di testi',
  'Nombre de mots gérés': 'Numero di parole gestite',
  "L'émail": 'Smalto',
  'Afficher les résultats': 'Mostra i risultati',
  'Frison occidental': 'Frisone occidentale',
  'Modification effectuée avec succès': 'Modifica completata con successo',
  'Balise titre': 'Tag del titolo',
  'Typix - Mon profil': 'Typix - Il mio profilo',
  'email invalide': 'e-mail non valida',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Si prega di aggiungere un url per poter salvare il testo come bozza',
  Macédonien: 'Macedone',
  'Votre candidature a bien été envoyée': 'La vostra domanda è stata inviata',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Aggiungete o completate il vostro contenuto qui sotto',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Aggiunta di parole chiave con frequenza residua',
  'Fiche du site web': 'Scheda del sito web',
  Contacter: 'Contatto',
  Fidjien: 'Figiana',
  Déconnexion: 'Disconnessione',
  email: 'e-mail',
  'Nombre de textes': 'Numero di testi',
  'Enregistrer et informer client': 'Registrazione e informazione dei clienti',
  'Date de validation': 'Data di convalida',
  'Tonga (Îles Tonga)': 'Tonga (Isole Tonga)',
  Javanais: 'Giavanese',
  Portugais: 'Portoghese',
  Biélorusse: 'Bielorusso',
  'Pour ajouter un nouveau texte': 'Per aggiungere un nuovo testo',
  'Enfant/Puéri': 'Bambino/Puperium',
  'Mots clés': 'Parole chiave',
  Emploi: 'Occupazione',
  'Voulez-vous vraiment supprimer la commande de':
    'Volete davvero rimuovere il',
  'Mot clé': 'Parola chiave',
  Consultants: 'Consulenti',
  Volapük: 'Volapük',
  Services: 'Servizi',
  'mot-clé (fréquence)': 'parola chiave (frequenza)',
  'Ne plus afficher ce message': 'Non visualizzare più questo messaggio',
  Consultant: 'Consulente',
  "Une erreur s'est produite": 'Si è verificato un errore',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Almeno 8 caratteri con una lettera maiuscola, un numero, un carattere speciale',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Francese',
  validées: 'convalidato',
  'Url du site': 'Url del sito',
  Précédent: 'Precedente',
  'car celui-ci a déjà rédigé des textes':
    "perché quest'ultimo ha già redatto dei testi",
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Bozze',
  Ido: 'Ido',
  'Contenu disponible': 'Contenuto disponibile',
  Enseignement: 'Insegnamento',
  'a été livré avec succès': 'è stato consegnato con successo',
  Chinois: 'Cinese',
  Vietnamien: 'Vietnamita',
  'Nouveau client': 'Nuovo cliente',
  'Votre profil a été mis à jour avec succès.':
    'Il vostro profilo è stato aggiornato con successo.',
  Consignes: 'Istruzioni',
  'Mot de passe': 'Password',
  Annuler: 'Annullamento',
  Non: 'No',
  Nom: 'Nome',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'ci sono ordini relativi al sito web',
  Score: 'Punteggio',
  'Mot de passe mis à jour avec succès':
    'La password è stata aggiornata con successo',
  Latin: 'Latino',
  Technologie: 'Tecnologia',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'è stato creato con successo',
  Roumain: 'Rumeno',
  Assamais: 'Assamese',
  'Khmer central': 'Khmer centrale',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Analisi/Limite',
  Grec: 'Greco',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'I miei progetti',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'parola chiave1 (2)\nparola chiave2 (1)\nparola chiave3 (1)\nparola chiave4 (2)\n...',
  'ajouter un texte': 'aggiungere un testo',
  'Détail de la prestation': 'Dettagli del servizio',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    "È possibile esportare il report di uno o più ordini. Per esportare tutti gli ordini di un cliente, è sufficiente fare clic sull'icona gialla di download.",
  'Gestion des utilisateurs': 'Gestione degli utenti',
  'livré et inférieur au nombre de texte non affecté':
    'consegnati e inferiore al numero di testi non assegnati',
  'Date deadline validation client': 'Termine di convalida del cliente',
  Aymara: 'Aymara',
  Validées: 'Convalidato',
  Notes: 'Note',
  'Nombre de GMB': 'Numero di Ogm',
  'résultats sur': 'risultati su',
  'Prénom / Nom du client': 'Nome / Nome del cliente',
  'Tout télécharger': 'Scaricare tutti',
  'Champs obligatoires': 'Campi obbligatori',
  Éditer: 'Modifica',
  'Coller le texte initial dans le champs ci-dessous':
    'Incollare il testo originale nel campo sottostante',
  'ID rédacteur': 'ID scrittore',
  Arménien: 'Armeno',
  'Grande distribution': 'Distribuzione su larga scala',
  Welsh: 'Gallese',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Arricchimento semantico',
  'Détail de la prestation du contenu': 'Dettagli sulla consegna dei contenuti',
  Noir: 'Nero',
  'Ajouter un texte': 'Aggiungere un testo',
  Traduction: 'Traduzione',
  'Immo/Defisc': 'Proprietà/Tasse',
  'Brief détaillé': 'Breve descrizione dettagliata',
  'Séparez les mots-clés par un Enter .':
    'Separate le parole chiave con un Invio .',
  Prix: 'Premi',
  'mot de passe oublié': 'Password dimenticata',
  'Ndebele, South ; South Ndebele': 'Ndebele, Sud; Ndebele meridionale',
  'Demander une révision': 'Richiedi una recensione',
  'Demander une repasse globale': 'Richiedere una revisione globale',
  'Sotho, Southern': 'Sotho, Sud',
  'Le titre de la commande existe déjà': "Il titolo dell'ordine esiste già",
  'Félicitations !': 'Congratulazioni!',
  Kurde: 'Curdo',
  Adresse: 'Indirizzo',
  Espagnol: 'Spagnolo',
  Ndonga: 'Ndonga',
  'En retard': 'In ritardo',
  'a été ajouté avec succès': 'è stato aggiunto con successo',
  Commandes: 'Ordini',
  'a été mis en brouillon': 'è stato redatto',
  'Rédacteur attribué': 'scrittore assegnato',
  Rechercher: 'Ricerca',
  'Nouvelle commande': 'Nuovo ordine',
  'Commandes non attribuées': 'Ordini non assegnati',
  'Le texte a été mis en brouillon': 'Il testo è stato redatto',
  'Contact edito': 'Contatto edito',
  Langues: 'Le lingue',
  Orange: 'Arancione',
  'Nynorsk norvégien': 'Norvegese nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turcomanni',
  'contenu repassé, en attente validation client':
    'contenuto stirato, in attesa della convalida del cliente',
  'Nombre de mots rédigés': 'Numero di parole scritte',
  Malay: 'Malese',
  'Nombre de Silo': 'Numero di silo',
  Oui: 'Sì',
  Automobile: 'Automotive',
  Résultats: 'Risultati',
  Afar: 'Afar',
  'Tâche - id tâche': "Attività - id dell'attività",
  'Ce champ est obligatoire doit être supérieur à 0':
    'Questo campo è obbligatorio e deve essere maggiore di 0.',
  'Vous êtes sûr': 'È sicuro che',
  'Réinitialiser votre mot de passe': 'Reimpostare la password',
  Sindhi: 'Sindhi',
  Bengali: 'Bengalese',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, norvegese; Bokmål norvegese',
  'Nombre de Jours': 'Numero di giorni',
  'Min/Max mots': 'Parole min/max',
  Slovène: 'Sloveno',
  Site: 'Sito web',
  'Sinhala ; Cingalais': 'Sinhala; singalese',
  Bulgare: 'Bulgaro',
  'Liste des commandes': 'Elenco degli ordini',
  "L'utilisateur": "L'utente",
  Uzbek: 'Uzbeko',
  'Titre du texte': 'Titolo del testo',
  Postuler: 'Candidarsi a',
  'Nb de textes par Silo': 'Numero di testi per silo',
  'Nombre total de mots (existants et ajoutés)':
    'Numero totale di parole (esistenti e aggiunte)',
  'Max.': 'Max.',
  Beauté: 'Bellezza',
  Humanitaire: 'Umanitario',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Ricerca per autore o testo',
  'Balise meta-description': 'Tag Meta description',
  Malgache: 'Malgascio',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Questo campo deve essere maggiore di 0 e minore di 1',
  'Le rédacteur': "L'scrittore",
  'a été mis à jour avec succès': 'è stato aggiornato con successo',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Deve essere lungo almeno 8 caratteri con una lettera maiuscola, un numero, un simbolo e non identico al nome.',
  'textes en plus': 'altri testi',
  'Format souhaité': 'Formato desiderato',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': "Dettaglio linea d'ordine",
  'Veuillez préciser la date': 'Specificare la data',
  Pali: 'Pali',
  Clients: 'Clienti',
  'Total facturé': 'Totale fatturato',
  'contenu validé par le client': 'contenuto convalidato dal cliente',
  'Occitan (post 1500)': 'Occitano (dopo il 1500)',
  Japonais: 'Giapponese',
  Avaric: 'Avarico',
  'Voulez-vous vraiment activer': 'Volete davvero attivare',
  Sanskrit: 'Sanscrito',
  'Toutes les notifications': 'Tutte le notifiche',
  Marathi: 'Marathi',
  Persan: 'Persiano',
  'La commande pour le client': "L'ordine per il cliente",
  'Intitulé de la commande': "Titolo dell'ordine",
  Samoan: 'Samoano',
  Autres: 'Altro',
  Sport: 'Lo sport',
  "il y'a des commandes liés au site web ":
    'ci sono ordini relativi al sito web',
  'Ajout de mots-clé avec la fréquence restante':
    'Aggiunta di parole chiave con frequenza residua',
  'a été supprimé avec succès': 'è stato rimosso con successo',
  Postulé: 'Inviato',
  Literie: 'Biancheria da letto',
  'Édité par le client': 'Modificato dal cliente',
  Cornouailles: 'Cornovaglia',
  Tigrinya: 'Tigrino',
  Textes: 'Testi',
  'Soumettre un nouveau texte': 'Invia un nuovo testo',
  Téléphone: 'Telefono',
  Deadline: 'Scadenza',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    "Si è verificato un errore. Controllare l'inserimento o riprovare più tardi.",
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'La richiesta di aggiunta del credito viene inviata',
  'Le rédacteur ': "L'scrittore",
  'Demander des crédits': 'Richiesta di credito',
  "Cette commande n'existe pas": 'Questo comando non esiste',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitiano',
  Admins: 'Amministratori',
  'Mettez des mots-clés avec fréquence':
    'Impostare le parole chiave con frequenza',
  Notifications: 'Notifiche',
  'en retard': 'in ritardo',
  "l'admin": "l'amministratore",
  'Mail rédacteurs': 'scrittori della posta',
  Amharique: 'Amarico',
  Societe: 'Azienda',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Numero di testi aggiunti',
  Société: 'Azienda',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès':
    'Il testo è stato consegnato con successo',
  'Mettez votre texte': 'Inserite il vostro testo',
  "Oups....! Une erreur s'est produite": 'Oops....! Si è verificato un errore',
  'Prénom/Nom': 'Nome/Cognome',
  'Max doit être sup à Min': 'Max deve essere maggiore di Min',
  'Importer un autre document': 'Importare un altro documento',
  'Votre demande a expirée .. veuillez refaire une autre':
    "La sua domanda è scaduta... la preghiamo di farne un'altra.",
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Correggere il formato della lacuna semantica per salvare il testo come bozza.',
  Exemple: 'Esempio',
  Slovaque: 'Slovacco',
  Finance: 'Finanza',
  Réactiver: 'Riattivare',
  'Nb textes': 'Numero di testi',
  'Intitulé commande': "Titolo dell'ordine",
  Supprimer: 'Cancellare',
  Brouillon: 'Bozza',
  'Mots clés restants': 'Parole chiave rimanenti',
  Date: 'Data',
  Rôle: 'Ruolo',
  'Ce champs doit être sup à 0': 'Questo campo deve essere maggiore di 0',
  'Maison/Déco': 'Casa/Decorazione',
  'Vous avez': 'Avete',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Questa riga di comando non può essere cancellata perché una parte del testo è stata consegnata',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'controlla la tua e-mail e clicca sul link per continuare!',
  Sarde: 'Sardegna',
  'Note rédacteur': "Nota dell'scrittore",
  'Uighur ; Uyghur': 'Uighur; Uyghur',
  'Name ': 'Nome',
  'Commandes intelligentes': 'Ordini smart',
  'Commandes avec révision': 'Ordini smart + revision',
  'Type de contenu': 'Tipo di contenuto',
  'Fiche produit': 'Scheda prodotto',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Redazione premium',
  'Rédaction avec révision': 'Redazione smart + revision',
  'Rédaction intelligente': 'Redazione smart',
  PREMIUM: 'PREMIUM',
  Type: 'Tipo',
  Editer: 'Modifica',
  'Tous les types': 'Tutti i tipi',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Fatture elaborate',
  'Factures à valider': 'Fatture da validare',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Impossibile generare la fattura! Si prega di riprovare',
  'Récapitulatif de facturation': 'Riepilogo della fatturazione',
  'Demander une modification': 'Richiedi una modifica',
  'Valider et envoyer la facture': 'Convalida e invia la fattura',
  'Demande de modification': 'Richiesta di modifica',
  'La facture a été validé avec succès!':
    'La fattura è stata validata con successo!',
  "Oups un erreur c'est produit!": 'Ops, si è verificato un errore!',
  'Nombre de texte': 'Numero di testi',
  'Nombre de mot par texte :': 'Numero di parole per testo:',
  Paiement: 'Pagamento',
  Virement: 'Bonifico',
  Délai: 'Termine',
  'Référence de votre facture': 'Riferimento della tua fattura',
  'Coût au mot': 'Costo per parola',
  'Montant global de la commande': "Importo totale dell'ordine",
  'Votre facture': 'La tua fattura',
  'Importer votre facture': 'Importa la tua fattura',
  'Pas de fichier disponible': 'Nessun file disponibile',
  Télécharger: 'Scarica',
  Relancer: 'Rispedisci',
  'Générer la facture': 'Genera la fattura',
  Payée: 'Pagata',
  'En cours de paiement': 'In attesa di pagamento',
  'En cours de validation': 'In attesa di convalida',
  'A envoyer': 'Da inviare',
  'Modification demandée': 'Modifica richiesta',
  'Référence de facture est obligatoire':
    'Il riferimento della fattura è obbligatorio',
  'Le coût au mot doit être un nombre':
    'Il costo per parola deve essere un numero',
  'Le coût au mot doit être un nombre positif':
    'Il costo per parola deve essere un numero positivo',
  'Le coût au mot est obligatoire': 'Il costo per parola è obbligatorio',
  'Le montant global de la commande doit être un nombre':
    "L'importo totale dell'ordine deve essere un numero",
  'Le montant global de la commande doit être un nombre positif':
    "L'importo totale dell'ordine deve essere un numero positivo",
  'Le montant global de la commande est obligatoire':
    "L'importo totale dell'ordine è obbligatorio",
  'Bill reference existe déja , veuillez réessayer':
    'Il riferimento della fattura esiste già, si prega di riprovare',
  'La facture a été envoyée avec succès!':
    'La fattura è stata inviata con successo!',

  'Montant estimé': 'Importo stimato',
  Action: 'Azione',
  Facture: 'Fattura',
  Statut: 'Stato',
  Factures: 'Fatture',
  'Valider la facture': 'Convalida la fattura',
  'La relance a été envoyée avec succès !':
    'Il sollecito è stato inviato con successo!',
  'La demande de modification a été envoyée avec succès !':
    'La richiesta di modifica è stata inviata con successo!',
  'Le cout est trop élevé': 'Il costo è troppo alto',
  'Le montant global de la commande est trop élevé ':
    "L'importo totale dell'ordine è troppo elevato",
  jours: 'giorni',
  'Factures à envoyer': 'Fatture da inviare',
  'Valider le paiement': 'Convalida pagamento',
  'Générer une facture': 'Genera una fattura',
  'Montant rédacteur': "Importo per l'autore",
  'Montant estimé par Typix': 'Importo stimato da Typix',
  'Voir la modification': 'Visualizza modifica',

  'Tag de Titre': 'Tag del titolo',
  'Meta description': 'Meta descrizione',
  'Nom du produit': 'Nome del prodotto',
  Avantages: 'Vantaggi',
  'Description détaillée': 'Descrizione dettagliata',
  'En cours de modification': 'In fase di modifica',
  'Mes Factures': 'Le mie fatture',
  'En attente de validation': 'In attesa di convalida',
  'les valeurs négatives ne sont pas autorisées':
    'I valori negativi non sono ammessi.',
  'Texte Seulement': 'Solo testo',
  'Traduction Seulement': 'Solo traduzione',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    "Il promemoria può essere inviato 20 giorni dopo l'invio della fattura",
  Instructions: 'Istruzioni',
  'Votre avis pour la commande': "Il tuo feedback per l'ordine",
  'Votre évaluation est précieuse pour nous ! Merci !':
    'La tua valutazione è preziosa per noi! Grazie!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    "Si è verificato un errore durante l'invio della tua valutazione. Riprova più tardi!",
  Evaluer: 'Valutare',
  Commentaire: 'Commento',
  'Votre commantaire': 'Il tuo commento',
  Note: 'Valutazione',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Il tuo feedback è prezioso per noi e ci aiuta a migliorare i nostri servizi.',
  'Verifier la facture': 'Verifica la fattura',
  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Il tuo feedback è prezioso per noi e ci aiuta a migliorare i nostri servizi. Grazie per aver condiviso la tua valutazione con noi!',
  'Notes rédacteur': "Note dell'autore",
  'Avis client': 'Recensioni dei clienti',
  'Pas de commantaire': 'Nessun commento',
  "Qu'avez vous pensé de votre commande?": 'Cosa ne pensi del tuo ordine?',
  'Evaluation de la commande': "Valutazione dell'ordine",
  'Rédiger un commentaire': 'Scrivi un commento',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Contribuisci a consentirci di offrirti servizi di redazione di altissima qualità condividendo i tuoi commenti.',
  'Que pouvons-nous améliorer?': 'Cosa possiamo migliorare?',
  Continuer: 'Continua',
  'Le dernier texte a été validé avec succès':
    "L'ultimo testo è stato convalidato con successo",
  'Rechercher par rédacteur, commandes, et montant':
    'Cerca per autore, ordini e importo',
  obligatoire: 'obbligatorio',
  'Note admin': 'Nota amministratore',
  'Note client': 'Nota cliente',
  Partie: 'Parte',
  'Vous venez de valider tous les contenus rédigés par ':
    'Hai appena convalidato tutti i contenuti scritti da ',
  "Qu'en avez vous pensé": 'Cosa ne pensi',
  'La facture a été générée avec succès !':
    'La fattura è stata generata con successo!',
  'La taille du titre et/ou la méta-description est trop courte':
    'La lunghezza del titolo e/o della meta-descrizione è troppo breve',

  'Demande de révision globale': 'Richiedere una revisione globale',
  Valider: 'Convalidare',
  'Obligatoire en cas d’optimisation ou traduction':
    'Obbligatorio in caso di ottimizzazione o traduzione',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Il tuo commento...',
  'Ex : "Contenus de très bonne qualité':
    'Esempio: "Contenuti di ottima qualità"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Potresti per favore assegnare questo scrittore per gli ordini futuri?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Questo pulsante serve a informare il cliente che il testo originale conteneva già un eccesso di parole chiave',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Modifica il testo',
  Ajouter: 'Aggiungi',
  'Demande examen': 'Richiesta di esame',
  'Recherche de mots clés à haute densité': 'Ricerca di keyword stuffing',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Ops, sembra che ci sia un keyword stuffing nel tuo testo: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Ops, sembra che ci sia keyword stuffing (troppo numero di occorrenze di una o più parole chiave) nel testo originale.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    "Per favore assicuratevi che la densità delle parole chiave sia ottimizzata per l'SEO (un massimo di una occorrenza ogni 50 parole).",
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Per questo, per favore non modificare il testo esistente e gentilmente inviare una richiesta di esame al supporto.',
  'Votre demande a été bien envoyée !':
    'La tua richiesta è stata inviata con successo!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    "Si è verificato un problema durante l'invio della tua richiesta.",
  'Veuillez attendez pour le  keyword Stuffing analyse':
    "Attendere per l'analisi del keyword stuffing.",
  'Inserez le maillage interne': 'Inserisci la rete interna',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    "Assicurati che la densità delle parole chiave sia ottimizzata per l'ottimizzazione sui motori di ricerca (massimo una occorrenza ogni 50 parole).\nSe incontri una parola che può avere più significati (Esempio: Former / Formation / Form o Matelas / Matelassé), puoi richiedere una revisione al supporto.",
  'Mots clés à haute densité dans le texte actuel':
    'Trefwoorden met hoge dichtheid in de huidige tekst',
  'Mots clés à haute densité dans le texte initial':
    'Trefwoorden met hoge dichtheid in de oorspronkelijke tekst',
  'Texte en cours de rédaction !': 'Testo in fase di redazione !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Sei sicuro/a di voler confermare il testo? Assicurati prima di aver salvato le modifiche.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    "Informazione dell'ultimo minuto! Il tuo saldo di crediti sarà presto esaurito :( Per anticipare, non esitare a richiedere più crediti ora per completare il tuo ordine.",
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    "Vuoi confermare l'URL attuale o modificarlo?",
  'Veuillez fournir une URL valide': 'Si prega di fornire un URL valido.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    "Per favore, indica gentilmente l'URL della pagina dove è stato integrato il contenuto di seguito:",
  'Contenu en ligne': 'Contenuto online',
  'Télécharger le document de révision': 'Scarica il documento di revisione',
  'Date d’envoi': 'Data di invio',
  Information: 'Informazione',
  Enregistrer: 'Salva',
  'Textes en ligne': 'Testi online',
  'Livrer les textes': 'Consegnare i testi',
  Indisponible: 'Non disponibile',
  Disponible: 'Disponibile',
  'Les textes ont été livrés avec succès.':
    'I testi sono stati consegnati con successo.',
  'Le texte a été livré avec succès.':
    'Il testo è stato consegnato con successo.',
  'Les textes ont été validés avec succès.':
    'I testi sono stati convalidati con successo.',
  'Le texte a été validé avec succès.':
    'Il testo è stato convalidato con successo.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Vuoi davvero convalidare i testi dell ordine?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Vuoi davvero consegnare i testi dell ordine?',
  'Fiche produit longue': 'Scheda prodotto lunga',
  'Fiche produit courte': 'Scheda prodotto corta',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Un testo è troppo lungo e non può essere esportato su Excel.',
  'Mettre un texte en brouillon en livré':
    'Cambia un testo da bozza a consegnato',
  'Remettre un texte validé à livré':
    'Een gevalideerde tekst naar geleverd veranderen',
  'Invalider le texte': 'Invalidare il testo',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Vorrei cambiare la mia password: come faccio?',
  'Comment mettre un contenu en bon pour livraison ?':
    'Come impostare il contenuto pronto per la consegna?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'Non posso salvare il mio contenuto come "bozza"',
  'Typix n’a pas enregistré mes dernières modifications':
    'Typix non ha salvato le mie ultime modifiche',
  'Comment rédiger une création de contenu ?':
    'Come scrivere una creazione di contenuti?',
  'Comment rédiger une optimisation de contenu ?':
    'Come scrivere un ottimizzazione dei contenuti?',
  'Je ne vois plus ma commande – que faire ?':
    'Non vedo più il mio ordine – cosa fare?',
  'Comment générer une facture ?': 'Come generare una fattura?',
  'Je ne peux pas generer ma facture : que faire ?':
    'Non posso generare la mia fattura: cosa fare?',
  'Tuto process Typix global': 'Processo globale Typix - tutorial',
  'Tuto mise en ligne des contenus': 'Come caricare contenuti - tutorial',
  'Modification mot de passe': 'Modifica della password',
  'Le test ne me convient pas': 'Il test non mi va bene',
  'Comment valider un contenu dans Typix ?':
    'Come validare i contenuti in Typix?',
  'Tuto Facturation': 'Tutorial di fatturazione',
  'Le statut du contenu a bien été modifié':
    'Lo stato del contenuto è stato modificato con successo',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Il divario semantico di questa parola chiave non è ancora stato convalidato, contatta il supporto Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Questa parola chiave non esiste su MONETORING, verifica con il supporto Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Recuperare il divario',
  'Le statut du contenu a bien été modifié':
    'Lo stato del contenuto è stato modificato con successo',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Il divario semantico di questa parola chiave non è ancora stato convalidato, contatta il supporto Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Questa parola chiave non esiste su MONETORING, verifica con il supporto Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Recuperare il divario',
  'Traduction premium': 'Traduzione premium',
  'Rédaction et/ou Traduction intelligente':
    'Scrittura e/o Traduzione intelligente',
  'Rédaction et/ou Traduction premium': 'Scrittura e/o Traduzione premium',
  'Rédaction et/ou Traduction avec révision':
    'Scrittura e/o Traduzione con revisione',
  'Rédaction et/ou Traduction intelligente':
    'Scrittura e/o Traduzione intelligente',
  'Traduction avec révision': 'Traduzione con revisione',
  'Traduction intelligente': 'Traduzione intelligente',
  'Le nombre de mots commandé est supérieur à celui vendu':
    'Il numero di parole ordinate è superiore a quelle vendute',
  'Rédaction et/ou Traduction premium': 'Scrittura e/o traduzione premium',
  'Rédaction et/ou Traduction smart + review':
    'Scrittura e/o traduzione intelligente + revisione',
  'Rédaction et/ou Traduction smart': 'Scrittura e/o traduzione intelligente',
  'Nom Silo': 'Nome Silo',
  'Remettre en brouillon': 'Riporta a bozza',
  'Bon pour Livraison Vérifié': 'Verificato per consegna',
  'Voulez-vous vraiment remettre en brouillon les Textes de la commande':
    'Sei sicuro di voler riportare i testi dell’ordine a bozza',
  'La vérification du texte a été annulée.':
    'La verifica del testo è stata annullata.',
  'Voulez-vous vraiment annuler la vérification du texte?':
    'Vuoi davvero annullare la verifica del testo?',
  'Etes-vous sûr(e) de vouloir regénérer tous les contenus ?':
    'Sei sicuro/a di voler rigenerare tutti i contenuti?',
  'Je suis sûr(e)': 'Sono sicuro/a',
  'Non, revenir en arrière': 'No, torna indietro',
  'à cocher si la commande a l’intention d’avoir un rédacteur exclusif pour le client':
    'selezionare se l’ordine intende avere un redattore esclusivo per il cliente',
  Gouvernance: 'Governance',
  'Modification à valider': 'Modifica da convalidare',
  Rédacteur: 'Redattore',
};
