export const polishTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Linie poleceń zostały pomyślnie zaktualizowane!',
  'Le texte a été enregistré avec succès':
    'Tekst został pomyślnie zarejestrowany',
  'La traduction a été enregistrée avec succès':
    'Tłumaczenie zostało pomyślnie zapisane',
  'Texte + Traduction enregistré avec succès':
    'Tekst + Tłumaczenie zostało pomyślnie zapisane',
  'Nombre de mots': 'Liczba słów',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Imię/ nazwisko klienta',
  Bashkir: 'Baszkir',
  "La date doit être ultérieure à aujourd'hui.":
    'Data musi być późniejsza niż dzisiejsza.',
  'Contenu rédigé': 'Treść napisana',
  'Nom/prénom': 'Nazwisko/imię',
  'Mot de passe incorrect': 'Nieprawidłowe hasło',
  Institutions: 'Instytucje',
  'Veuillez donner un descriptif au document téléchargé':
    'Proszę podać opis pobranego dokumentu',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Następny',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Słowa kluczowe oddzielamy "enterem".',
  Allemand: 'Niemiecki',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'na Twój adres e-mail została wysłana wiadomość zawierająca link do resetowania hasła',
  'Chef edito': 'Redaktor naczelny',
  Croate: 'Chorwacki',
  'Nb de textes': 'Liczba tekstów',
  Violet: 'Violet',
  'Ce champs doit être sup ou égale nombre de texte':
    'Pole to musi być większe lub równe liczbie tekstu',
  'a été activé avec succès': 'został pomyślnie aktywowany',
  Népalais: 'Nepalskie',
  'Nombre de FAQ': 'Ilość FAQ',
  Urdu: 'Urdu',
  'Analyses restantes': 'Pozostałe analizy',
  Indonésien: 'Indonezyjski',
  Breton: 'Bretoński',
  Livrés: 'Dostarczone',
  Associations: 'Stowarzyszenia',
  'Le client': 'Klient',
  'Révision demandée': 'Przegląd wymagany',
  "Une erreur s'est produite, veuillez réessayer":
    'Wystąpił błąd, proszę spróbować ponownie',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Nieautoryzowana aktualizacja po dostarczeniu tekstów',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Aby dodać tekst (optymalizacja istniejących treści)',
  Gaélique: 'Gaelic',
  'La ligne de commande pour': 'Linia poleceń dla',
  'Business/Management': 'Biznes/Zarządzanie',
  Serbe: 'Serbski',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Proszę wskazać poniżej swoje prośby o modyfikacje, biorąc pod uwagę nasze warunki prasowania',
  'rédacteurs trouvés': 'odnalezieni autorzy',
  'Kirghiz ; Kirghiz': 'Kirgiz; Kirgiz',
  Différence: 'Różnica',
  'en cours': 'w trakcie',
  'Sami du Nord': 'Północni Sami',
  Comparer: 'Porównaj',
  Lituanien: 'Litwa',
  'Typix - Mon tableau de bord': 'Typix - Moja tablica rozdzielcza',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Musi mieć długość co najmniej 8 znaków z dużą literą, cyfrą, znakiem specjalnym i nie może być identyczna z nazwą',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Proszę wpisać prawidłowe pola',
  Basque: 'Baska',
  'Exporter .csv': 'Eksportuj .csv',
  'Confirmer le nouveau mot de passe': 'Potwierdź nowe hasło',
  "Pas d'options": 'Brak opcji',
  'Banque/Assurance': 'Bankowość/ubezpieczenia',
  'Vos demandes': 'Twoje prośby',
  'Coût d\u2019achat': 'Koszt zakupu',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'Tekst został pomyślnie usunięty.',
  'Mes Commandes': 'Moje zamówienia',
  Zoulou: 'Zulu',
  Afficher: 'Zobacz',
  Texte: 'Tekst',
  Islandais: 'Islandzki',
  Russe: 'Rosyjski',
  'Tous les textes': 'Wszystkie teksty',
  Czech: 'Czeski',
  'Comparaison hors ligne': 'Porównanie w trybie offline',
  Téléphonie: 'Telefonia',
  'Valider le texte': 'Zatwierdzenie tekstu',
  'Votre text ne de doit pas dépasser 500 mots':
    'Twój tekst nie powinien przekraczać 500 słów',
  'Coquilles (double espace, « s »\u2026)':
    'Skorupki (podwójna spacja, "s"...)',
  'le consultant': 'konsultant',
  Coréen: 'Koreański',
  'Nb de Silo': 'Liczba silosów',
  Validés: 'Zatwierdzone',
  'non attribuées': 'nieprzydzielony',
  Néerlandais: 'Holenderski',
  'Voulez-vous vraiment supprimer': 'Czy naprawdę chcesz usunąć',
  Rundi: 'Rundi',
  Regional: 'Regionalny',
  'Compte client': 'Konto klienta',
  Global: 'Global',
  'Tous les rôles': 'Wszystkie role',
  Musique: 'Muzyka',
  Perso: 'Osobisty',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Wygląda na to, że nie masz już kredytu na analizę',
  Bambara: 'Bambara',
  Sortir: 'Wysiadaj',
  optionel: 'opcjonalnie',
  'Temps restant': 'Pozostały czas',
  Ukrainien: 'Ukraińska',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Twoja prośba o recenzję została wysłana.',
  'Typix - Créer rédacteur': 'Typix - tworzenie autorzy',
  "n'existe pas": 'nie istnieje',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'nie może zostać usunięty, ponieważ jest powiązany ze stronami internetowymi',
  'Commandes en cours': 'Zamówienia w toku',
  'Textes livrés': 'Dostarczone teksty',
  Validation: 'Walidacja',
  'Non attribuées': 'Nie przydzielono',
  'Voulez-vous vraiment désactiver': 'Czy naprawdę chcesz dezaktywować',
  'Catalan ; Valencien': 'kataloński; walencki',
  'Nombre de textes restants': 'Liczba pozostałych tekstów',
  'Ne doit pas contenir votre nom': 'Nie może zawierać Twojego nazwiska',
  Documentation: 'Dokumentacja',
  Kazakh: 'Kazachski',
  'Numéro invalide': 'Nieprawidłowy numer',
  'Veuillez saisir un texte ou importer un fichier.':
    'Proszę wprowadzić tekst lub zaimportować plik.',
  'Nb de FAQ': 'Nr FAQ',
  Analyser: 'Analizuj',
  Turc: 'Turecki',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Wszelkie wnioski niewymienione w briefie',
  Wallon: 'Walonia',
  Birmane: 'Birmańska',
  Irlandais: 'Irlandzki',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Zresetuj swoje hasło',
  Cuisine: 'Kuchnia',
  'Haïtien ; créole haïtien': 'Haitańczyk; kreolski haitański',
  'Vous ne pouvez pas supprimer le rédacteur': 'Nie można usunąć autorzy',
  Validé: 'Zatwierdzone',
  "cet email n'existe pas": 'ten e-mail nie istnieje',
  Historique: 'Historia',
  Confirmer: 'Potwierdzić',
  'à cocher si le nombre de mots est différent par texte':
    'należy zaznaczyć, jeśli liczba słów jest różna dla danego tekstu',
  'Fiche du site web ': 'Arkusz strony internetowej',
  Désactiver: 'Dezaktywuj',
  'Travaux/BTP': 'Prace/Konstrukcja',
  'La fiche du website': 'Karta strony internetowej',
  'Si le contenu ne respecte pas les règles du brief':
    'Jeśli treść nie respektuje zasad briefu',
  Norvégien: 'Norweski',
  '0 rédacteurs': '0 autorzy',
  indisponible: 'niedostępna',
  Venda: 'Venda',
  Maori: 'Maorysi',
  'Textes revisés': 'Teksty zmienione',
  'Status texte': 'Status tekstu',
  'textes livrés en attente de validation':
    'teksty dostarczone oczekujące na zatwierdzenie',
  'Typix - Mon Profile': 'Typix - Mój profil',
  'le client': 'klient',
  Romanche: 'Romanche',
  'URL du site': 'URL strony',
  'nouvelles notifications': 'nowe powiadomienia',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; malediwski',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Czy na pewno chcesz zapisać tekst',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Proszę dodać tekst początkowy, aby zapisać tekst jako wersję roboczą',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Umowa dla klienta',
  rédacteurs: 'autorzy',
  'Ce champ est obligatoire': 'To pole jest obowiązkowe',
  'Tarif/Mot': 'Stawka/słowo',
  Tadjik: 'Tadżyk',
  'Réf devis': 'Cytat Ref',
  'Rechercher un rédacteur': 'Poszukiwanie autorzy',
  Albanais: 'Albański',
  Galicien: 'Galicyjska',
  Disponibilité: 'Dostępność',
  'Les deux mots de passe ne sonts pas identiques':
    'Dwa hasła nie są identyczne',
  Tibétain: 'Tybetański',
  'Typix - Gestion des utilisateurs': 'Typix - Zarządzanie użytkownikami',
  'Ossétien ; Ossétique': 'osetyjski; osetyjski',
  Tswana: 'Tswana',
  'Ajouter un client': 'Dodaj klienta',
  'Séparez les mots-clés par un " Enter ".':
    'Słowa kluczowe oddzielamy znakiem "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Tematy',
  'Nb mots/texte': 'Liczba słów/tekstu',
  Commerce: 'Handel',
  'Voir les commandes': 'Zobacz zamówienia',
  'Nombre de mots par texte': 'Liczba słów w tekście',
  'Lien drive...': 'Napęd linkowy...',
  'Hollandais ; Flamand': 'niderlandzki; flamandzki',
  'Mon profil': 'Mój profil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'To pole musi być większe niż 0, ponieważ fakturowanie nie jest prawdziwe',
  'Le texte a été validé avec succès': 'Tekst został pomyślnie zatwierdzony',
  Jeux: 'Gry',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Czy naprawdę chcesz usunąć linię poleceń dla',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Północ; Ndebele Północ',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Język słowiański kościelny; język starosłowiański; język słowiański kościelny; język starobułgarski; język starosłowiański',
  Swahili: 'Swahili',
  Voyage: 'Podróż',
  'Nombre de mot à ajouter': 'Liczba słów do dodania',
  'Liste des rédacteurs': 'Wykaz autorzy',
  'le rédacteur': 'autorzy',
  Kanuri: 'Kanuri',
  rédacteur: 'autorzy',
  Tatar: 'Tatar',
  'Roumain ; Moldave ; Moldovan': 'rumuński; mołdawski',
  'Le texte initial de': 'Tekst pierwotny',
  'Mail clients': 'Poczta od klienta',
  'Le contrat de': 'Umowa z dnia',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'co najmniej jedną małą i jedną wielką literę, jeden znak specjalny i jedną cyfrę',
  Transport: 'Transport',
  'Nombre total de mots (existants et ajoutés) :':
    'Całkowita liczba słów (istniejących i dodanych) :',
  'Rechercher par mail, prénom, nom, site...':
    'Szukaj według e-maila, imienia, nazwiska, strony...',
  'Nombre de mot à ajouter (Min/Max) : ': 'Liczba słów do dodania (Min/Max) :',
  Compte: 'Konto',
  'Tourisme/Loisirs': 'Turystyka/rekreacja',
  'Nouveau texte': 'Nowy tekst',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; grenlandzki',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Ups... W oknie luki semantycznej znajduje się jeden lub więcej błędów. Proszę sprawdzić jego zawartość, aby je poprawić i ponownie przesłać swój tekst.',
  'ne peut pas être supprimé pour le moment':
    'nie może zostać usunięty w tym momencie',
  'Le texte': 'Tekst',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Istnieje już użytkownik z adresem e-mail',
  Cree: 'Cree',
  Prénom: 'Imię i nazwisko',
  'commandes prête': 'zamówienia gotowe',
  Réinitialiser: 'Reset',
  'Veuillez fournir une date valide': 'Proszę podać ważną datę',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luksemburski; Letzeburgesch',
  Suédois: 'Szwedzki',
  'Confirmer le mot de passe': 'Potwierdź hasło',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'To pole jest obowiązkowe i może zawierać tylko liczby',
  Sciences: 'Nauka',
  Tchétchène: 'Czeczenia',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'jest jedynym klientem dla strony, inni muszą być dodani, aby go usunąć',
  'Le mot de passe de confirmation ne correspond pas':
    'Hasło potwierdzające nie pasuje',
  Swati: 'Swati',
  Danois: 'Duński',
  Sauvegarder: 'Zapisz',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Proszę zmienić następujące informacje: tytuł i/lub słowo kluczowe',
  'importer un document': 'importować dokument',
  Maltais: 'Maltański',
  'Nb de mots': 'Liczba słów',
  'Les données fournies sont invalides': 'Podane dane są nieważne',
  'Livraison estimée': 'Szacowana dostawa',
  'Grec moderne (1453-)': 'Greka nowożytna (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Całkowita liczba dodanych słów',
  'le contenu présente des inexactitudes': 'treść zawiera nieścisłości',
  Finnois: 'Fińska',
  'Ajouter un admin': 'Dodaj administratora',
  'Min/Max': 'Min/Maks.',
  'Modifier le consultant': 'Modyfikacja konsultanta',
  'Liste des clients': 'Lista klientów',
  'Nom client': 'Nazwa klienta',
  Vert: 'Zielona',
  'Mots clés manquants': 'Brakujące słowa kluczowe',
  Lao: 'Lao',
  Somali: 'Somalijski',
  'Nb de silo par texte': 'Liczba silosów na tekst',
  Italien: 'Włoski',
  'La commande a été mis à jour avec succès !':
    'Zamówienie zostało pomyślnie zaktualizowane!',
  Bosniaque: 'Bośniacki',
  'Thématiques d\u2019expertise': 'Tematy dotyczące wiedzy specjalistycznej',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'To jest hasło, które zapomniałeś.',
  'a été désactivé avec succès': 'został pomyślnie dezaktywowany',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Zgłoś się po zamówienie',
  'mot-clé': 'słowo kluczowe',
  Abkhazien: 'Abchazja',
  Mongolien: 'Mongolski',
  'Typix - Fiche site web': 'Typix - Strona internetowa',
  'Mail envoyé': 'Poczta wysłana',
  Géorgien: 'Gruzińska',
  'Si des phrases ne sont pas dans un français correct':
    'Jeśli zdania nie są w poprawnym języku francuskim',
  'En cours': 'W trakcie realizacji',
  voir: 'zob.',
  "Date d'ajout": 'Data dodania',
  'Tarif au mot': 'Stawka za słowo',
  Thai: 'Tajlandia',
  'Détails du texte': 'Szczegóły dotyczące tekstu',
  'Commandes en retard': 'Spóźnione zamówienia',
  disponible: 'dostępny',
  Droit: 'Prawo',
  Azerbaïdjanais: 'Azerbejdżan',
  'Ajouter un consultant': 'Dodaj konsultanta',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Podczas odtwarzania zawartości obsługiwane będą tylko następujące elementy',
  'doit comprendre au moins 8 charactères':
    'musi zawierać co najmniej 8 znaków',
  'Titre de la commande': 'Tytuł zamówienia',
  'Langues bihari': 'Języki Bihari',
  'Livraison estimé au': 'Przewidywana dostawa do',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Liczba tekstów w zamówieniu nie może być mniejsza niż liczba tekstów przypisanych do autorzy/pisarzy. Proszę najpierw zmienić informacje w linii poleceń',
  'Date de livraison': 'Termin dostawy',
  'Voulez-vous vraiment supprimer le contrat de':
    'Czy naprawdę chcesz zlikwidować',
  'Facturation au réel': 'Rzeczywiste rozliczenie',
  'Gap sémantique': 'Luka semantyczna',
  Estonien: 'Estońska',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Maksymalny koszt zakupu/słowo',
  'Détail de la commande': 'Szczegóły zamówienia',
  'Export en cours de téléchargement\u2026': 'Eksport jest przesyłany...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'To pole jest obowiązkowe i musi być mniejsze lub równe Łączna liczba tekstów w zamówieniu',
  Arabe: 'arabski',
  Bislama: 'Bislama',
  'Mail client': 'Poczta od klienta',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Title, keywords, url, tag, meta-description)',
  'Typix - Nouvelle commande': 'Typix - Nowe zamówienie',
  'Révision en attente': 'Przegląd w toku',
  'Le consultant': 'Konsultant',
  Entreprise: 'Firma',
  Aragonais: 'Aragonese',
  Kashmiri: 'Kaszmir',
  'a été activé': 'został aktywowany',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Międzynarodowe Stowarzyszenie Języków Pomocniczych)',
  Actualités: 'Aktualności',
  'Votre décision a été prise en compte avec succès':
    'Państwa decyzja została pomyślnie uwzględniona',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'zawartość oczekująca na weryfikację klienta',
  'Fiche du rédacteur': 'Plik autorzy',
  Outillage: 'Narzędzia',
  'Typix - Détails de la commande': 'Typix - Szczegóły zamówienia',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Dodaj lub uzupełnij swoją treść poniżej:',
  Ewe: 'Ewe',
  'contenu en attente de repasse rédacteur':
    'autorzy powtórzeń w oczekiwaniu na treść',
  'La commande a été supprimé avec succès':
    'Zlecenie zostało pomyślnie usunięte.',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Francuskie wyrażenia lub zwroty, które nie pasują do Ciebie',
  Corse: 'Korsyka',
  Modifier: 'Edytuj',
  'Nb de GMB': 'Liczba GMB',
  Oromo: 'Oromo',
  Polish: 'Polska',
  'Insérez votre gap sémantique avec l’occurrence':
    'Wstaw swoją lukę semantyczną z wystąpieniem',
  'Nb de textes à optimiser': 'Liczba tekstów do zoptymalizowania',
  Mode: 'Tryb',
  Akan: 'Akan',
  'Révisions effectués': 'Wprowadzone zmiany',
  'Modifier l\u2019admin': 'Edycja administratora',
  'Télécharger le document': 'Pobierz dokument',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Uwagi autorzy',
  Quechua: 'Quechua',
  Féroïen: 'Wyspa Faro',
  'Modifier le rédacteur': 'Zmiana autorzy',
  Mail: 'Mail',
  Rédacteurs: 'autorzy',
  'Typix - Détails du texte': 'Typix - szczegóły dotyczące tekstu',
  Hongrois: 'Węgierski',
  'Bon pour livraison': 'Dobre dla dostawy',
  'Au réel': 'W świecie rzeczywistym',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebrajski',
  'Nombre total de mots ajoutés :': 'Całkowita liczba dodanych słów :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Musi być mniejsza niż maksymalna liczba',
  'Nom du texte': 'Nazwa tekstu',
  'Ajouter un rédacteur': 'Dodaj autorzy',
  Ou: 'Albo',
  Utilisateurs: 'Użytkownicy',
  Heures: 'Godziny',
  'Santé/Bien-être': 'Zdrowie/życie',
  'Interlingue ; Occidental': 'Międzyjęzykowy; Zachodni',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Łotwa',
  Anglais: 'Angielski',
  Envoyer: 'Wyślij',
  'Tableau de bord': 'Tablica rozdzielcza',
  'Demande de révision': 'Wniosek o dokonanie przeglądu',
  'Résultat sémantique': 'Wynik semantyczny',
  'Chercher un rédacteur': 'Poszukiwanie autorzy',
  'Ce champs est obligatoire': 'To pole jest obowiązkowe',
  Rédaction: 'Redakcja',
  'Modifier le client': 'Modyfikacja klienta',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Czy naprawdę chcesz usunąć autorzy',
  Titre: 'Tytuł',
  Langue: 'Język',
  'Les demandes suivantes ne seront pas prises en compte':
    'Nie będą rozpatrywane następujące wnioski',
  'délai dépassé': 'niedotrzymany termin',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Istniejąca umowa, proszę o modyfikację informacji',
  Igbo: 'Igbo',
  Yiddish: 'Yiddish',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgish',
  'Commandes validées': 'Zatwierdzone zamówienia',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Krok',
  'Energie/Environnement': 'Energia/środowisko',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom': 'nie może zawierać nazwiska/imienia',
  'Typix - Fiche rédacteur': 'Typix - Plik dla autorzy',
  'révisions effectuées en attentes de validation':
    'zmiany dokonane w oczekiwaniu na zatwierdzenie',
  'Textes validés': 'Teksty zatwierdzone',
  Art: 'Art.',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Wszechstronny',
  Sundanese: 'Sundański',
  'Nb total de textes': 'Całkowita liczba tekstów',
  'Nombre de mots gérés': 'Liczba zarządzanych słów',
  "L'émail": 'Emalia',
  'Afficher les résultats': 'Wyniki pokazu',
  'Frison occidental': 'język zachodni fryzyjski',
  'Modification effectuée avec succès': 'Modyfikacja zakończona sukcesem',
  'Balise titre': 'Znacznik tytułu',
  'Typix - Mon profil': 'Typix - Mój profil',
  'email invalide': 'nieprawidłowy e-mail',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Proszę dodać adres url, aby można było zapisać tekst jako wersję roboczą',
  Macédonien: 'Macedońska',
  'Votre candidature a bien été envoyée': 'Twój wniosek został wysłany',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Dodaj lub uzupełnij swoją treść poniżej',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Dodawanie słów kluczowych z pozostałą częstotliwością',
  'Fiche du site web': 'Arkusz strony internetowej',
  Contacter: 'Kontakt',
  Fidjien: 'Fidżi',
  Déconnexion: 'Rozłączenie',
  email: 'email',
  'Nombre de textes': 'Liczba tekstów',
  'Enregistrer et informer client': 'Rejestrowanie i informowanie klientów',
  'Date de validation': 'Data walidacji',
  'Tonga (Îles Tonga)': 'Tonga (Wyspy Tonga)',
  Javanais: 'Javanese',
  Portugais: 'Portugalski',
  Biélorusse: 'Białoruska',
  'Pour ajouter un nouveau texte': 'Aby dodać nowy tekst',
  'Enfant/Puéri': 'Dziecko/Puperium',
  'Mots clés': 'Słowa kluczowe',
  Emploi: 'Zatrudnienie',
  'Voulez-vous vraiment supprimer la commande de': 'Czy naprawdę chcesz usunąć',
  'Mot clé': 'Słowo kluczowe',
  Consultants: 'Konsultanci',
  Volapük: 'Volapük',
  Services: 'Usługi',
  'mot-clé (fréquence)': 'słowo kluczowe (częstotliwość)',
  'Ne plus afficher ce message': 'Nie wyświetlaj tego komunikatu ponownie',
  Consultant: 'Konsultant',
  "Une erreur s'est produite": 'Wystąpił błąd',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Co najmniej 8 znaków z wielką literą, cyfrą, znakiem specjalnym',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Francuski',
  validées: 'zatwierdzony',
  'Url du site': 'Url strony',
  Précédent: 'Poprzedni',
  'car celui-ci a déjà rédigé des textes':
    'ponieważ ta ostatnia przygotowała już teksty',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Projekty',
  Ido: 'Ido',
  'Contenu disponible': 'Dostępne treści',
  Enseignement: 'Nauczanie',
  'a été livré avec succès': 'został pomyślnie dostarczony',
  Chinois: 'Chiński',
  Vietnamien: 'Wietnamski',
  'Nouveau client': 'Nowy klient',
  'Votre profil a été mis à jour avec succès.':
    'Twój profil został pomyślnie zaktualizowany.',
  Consignes: 'Instrukcje',
  'Mot de passe': 'Hasło',
  Annuler: 'Anuluj',
  Non: 'Nie',
  Nom: 'Nazwa',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'istnieją zamówienia związane ze stroną internetową',
  Score: 'Wynik',
  'Mot de passe mis à jour avec succès':
    'Hasło zostało pomyślnie zaktualizowane',
  Latin: 'łac.',
  Technologie: 'Technologia',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'został pomyślnie utworzony',
  Roumain: 'Rumuński',
  Assamais: 'Assamese',
  'Khmer central': 'Centralny Khmer',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Pendżabski; Pendżabski',
  'Analyse/Limite': 'Analiza/ograniczenie',
  Grec: 'Grecka',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Moje projekty',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'słowo kluczowe1 (2)\nsłowo kluczowe2 (1)\nsłowo kluczowe3 (1)\nsłowo kluczowe4 (2)\n...',
  'ajouter un texte': 'dodać tekst',
  'Détail de la prestation': 'Szczegóły dotyczące usługi',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Masz możliwość wyeksportowania raportu jednego lub wielu zamówień. Aby wyeksportować wszystkie zamówienia danego klienta, wystarczy kliknąć na żółtą ikonę pobierania',
  'Gestion des utilisateurs': 'Zarządzanie użytkownikami',
  'livré et inférieur au nombre de texte non affecté':
    'dostarczonych i mniejsza niż liczba tekstów nieprzypisanych',
  'Date deadline validation client': 'Termin zatwierdzenia przez klienta',
  Aymara: 'Aymara',
  Validées: 'Zatwierdzone',
  Notes: 'Uwagi',
  'Nombre de GMB': 'Liczba GMB',
  'résultats sur': 'wyniki na',
  'Prénom / Nom du client': 'Imię / Nazwisko klienta',
  'Tout télécharger': 'Pobierz wszystko',
  'Champs obligatoires': 'Wymagane pola',
  Éditer: 'Edytuj',
  'Coller le texte initial dans le champs ci-dessous':
    'Wklej oryginalny tekst do pola poniżej',
  'ID rédacteur': 'Identyfikator autorzy',
  Arménien: 'Ormiańska',
  'Grande distribution': 'Dystrybucja na dużą skalę',
  Welsh: 'Walijski',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Wzbogacanie semantyczne',
  'Détail de la prestation du contenu':
    'Szczegóły dotyczące dostarczania treści',
  Noir: 'Czarny',
  'Ajouter un texte': 'Dodaj tekst',
  Traduction: 'Tłumaczenie',
  'Immo/Defisc': 'Nieruchomości/Podatki',
  'Brief détaillé': 'Szczegółowy brief',
  'Séparez les mots-clés par un Enter .': 'Słowa kluczowe oddzielamy Enterem .',
  Prix: 'Nagrody',
  'mot de passe oublié': 'Zapomniane hasło',
  'Ndebele, South ; South Ndebele': 'Ndebele, południowe; południowe Ndebele',
  'Demander une révision': 'Poproś o recenzję',
  'Demander une repasse globale': 'Poproś o kompleksową rewizję',
  'Sotho, Southern': 'Sotho, południowe',
  'Le titre de la commande existe déjà': 'Tytuł zamówienia już istnieje',
  'Félicitations !': 'Gratulacje!',
  Kurde: 'Kurdyjski',
  Adresse: 'Adres',
  Espagnol: 'Hiszpański',
  Ndonga: 'Ndonga',
  'En retard': 'Późno',
  'a été ajouté avec succès': 'został pomyślnie dodany',
  Commandes: 'Zamówienia',
  'a été mis en brouillon': 'został sporządzony',
  'Rédacteur attribué': 'Przydzielony autorzy',
  Rechercher: 'Szukaj',
  'Nouvelle commande': 'Nowe zamówienie',
  'Commandes non attribuées': 'Zamówienia nieprzydzielone',
  'Le texte a été mis en brouillon': 'Tekst został opracowany',
  'Contact edito': 'Kontakt z edito',
  Langues: 'Języki',
  Orange: 'Orange',
  'Nynorsk norvégien': 'Norweski Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmen',
  'contenu repassé, en attente validation client':
    'wyprasowana zawartość, czeka na zatwierdzenie przez klienta',
  'Nombre de mots rédigés': 'Liczba napisanych słów',
  Malay: 'Malaj',
  'Nombre de Silo': 'Liczba silosów',
  Oui: 'Tak',
  Automobile: 'Automotive',
  Résultats: 'Wyniki',
  Afar: 'Afar',
  'Tâche - id tâche': 'Zadanie - identyfikator zadania',
  'Ce champ est obligatoire doit être supérieur à 0':
    'To pole jest obowiązkowe i musi być większe niż 0',
  'Vous êtes sûr': 'Czy na pewno',
  'Réinitialiser votre mot de passe': 'Zresetuj swoje hasło',
  Sindhi: 'Sindhi',
  Bengali: 'Bengali',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, norweski; norweski Bokmål',
  'Nombre de Jours': 'Liczba dni',
  'Min/Max mots': 'Min/Max słowa',
  Slovène: 'Słowenia',
  Site: 'Strona internetowa',
  'Sinhala ; Cingalais': 'Sinhala; syngaleski',
  Bulgare: 'Bułgarski',
  'Liste des commandes': 'Wykaz zamówień',
  "L'utilisateur": 'Użytkownik',
  Uzbek: 'Uzbek',
  'Titre du texte': 'Tytuł tekstu',
  Postuler: 'Aplikuj na',
  'Nb de textes par Silo': 'Liczba tekstów na silos',
  'Nombre total de mots (existants et ajoutés)':
    'Całkowita liczba słów (istniejących i dodanych)',
  'Max.': 'Max.',
  Beauté: 'Beauty',
  Humanitaire: 'Humanitarne',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Wyszukiwanie według autora lub tekstu',
  'Balise meta-description': 'Meta description tag',
  Malgache: 'Malgasze',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Pole to musi być większe niż 0 i mniejsze niż 1',
  'Le rédacteur': 'autorzy',
  'a été mis à jour avec succès': 'został pomyślnie zaktualizowany',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Musi mieć długość co najmniej 8 znaków z dużą literą, cyfrą, symbolem i nie może być identyczna z nazwą.',
  'textes en plus': 'więcej tekstów',
  'Format souhaité': 'Pożądany format',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Szczegóły dotyczące linii zamówienia',
  'Veuillez préciser la date': 'Proszę podać datę',
  Pali: 'Pali',
  Clients: 'Klienci',
  'Total facturé': 'Suma zafakturowana',
  'contenu validé par le client': 'treść zatwierdzona przez klienta',
  'Occitan (post 1500)': 'Occitan (po 1500 roku)',
  Japonais: 'Japońska',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Czy naprawdę chcesz aktywować',
  Sanskrit: 'Sanskryt',
  'Toutes les notifications': 'Wszystkie powiadomienia',
  Marathi: 'Marathi',
  Persan: 'Perski',
  'La commande pour le client': 'Zamówienie dla klienta',
  'Intitulé de la commande': 'Tytuł zamówienia',
  Samoan: 'Samoańczyk',
  Autres: 'Inne',
  Sport: 'Sport',
  "il y'a des commandes liés au site web ":
    'istnieją zamówienia związane ze stroną internetową',
  'Ajout de mots-clé avec la fréquence restante':
    'Dodawanie słów kluczowych z pozostałą częstotliwością',
  'a été supprimé avec succès': 'został pomyślnie usunięty',
  Postulé: 'Posted',
  Literie: 'Pościel',
  'Édité par le client': 'Edytowane przez klienta',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Teksty',
  'Soumettre un nouveau texte': 'Prześlij nowy tekst',
  Téléphone: 'Telefon',
  Deadline: 'Termin:',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Wystąpił błąd. Proszę sprawdzić swoje zgłoszenie lub spróbować ponownie później.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Wniosek o dodanie kredytu jest wysyłany',
  'Le rédacteur ': 'autorzy',
  'Demander des crédits': 'Ubieganie się o kredyt',
  "Cette commande n'existe pas": 'To polecenie nie istnieje',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitian',
  Admins: 'Administratorzy',
  'Mettez des mots-clés avec fréquence':
    'Ustaw słowa kluczowe z częstotliwością',
  Notifications: 'Powiadomienia',
  'en retard': 'późno',
  "l'admin": 'administrator',
  'Mail rédacteurs': 'autorzy poczty',
  Amharique: 'Amharic',
  Societe: 'Firma',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Liczba dodanych tekstów',
  Société: 'Firma',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'Tekst został dostarczony z powodzeniem',
  'Mettez votre texte': 'Umieść swój tekst',
  "Oups....! Une erreur s'est produite": 'Ups....! Wystąpił błąd',
  'Prénom/Nom': 'Imię/ nazwisko',
  'Max doit être sup à Min': 'Max musi być większe niż Min',
  'Importer un autre document': 'Importuj inny dokument',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Twój wniosek stracił ważność ... proszę zrobić kolejny',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Proszę poprawić format luki semantycznej, aby zapisać tekst jako projekt',
  Exemple: 'Przykład',
  Slovaque: 'Słowacki',
  Finance: 'Finanse',
  Réactiver: 'Reaktywuj',
  'Nb textes': 'Liczba tekstów',
  'Intitulé commande': 'Tytuł zamówienia',
  Supprimer: 'Usuń',
  Brouillon: 'Projekt',
  'Mots clés restants': 'Pozostałe słowa kluczowe',
  Date: 'Data',
  Rôle: 'Rola',
  'Ce champs doit être sup à 0': 'Pole to musi być większe niż 0',
  'Maison/Déco': 'Dom/Dekoracja',
  'Vous avez': 'Masz',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Ten wiersz poleceń nie może być usunięty, ponieważ część jego tekstu została dostarczona',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'sprawdź swój e-mail i kliknij na link, aby kontynuować!',
  Sarde: 'Sardynia',
  'Note rédacteur': 'Uwaga autorzy',
  'Uighur ; Uyghur': 'Ujgur; Ujgur',
  Name: 'Nazwa',
  'Commandes intelligentes': 'Smart polecenia',
  'Commandes avec révision': 'Smart + review polecenia',
  'Type de contenu': 'Typ treści',
  'Fiche produit': 'Karta produktu',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Redakcja premium',
  'Rédaction avec révision': 'Redakcja smart + review',
  'Rédaction intelligente': 'Redakcja smart',
  PREMIUM: 'PREMIUM',
  Type: 'Typ',
  Editer: 'Edytuj',
  'Tous les types': 'Wszystkie typy',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Przetworzone faktury',
  'Factures à valider': 'Faktury do zatwierdzenia',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Nie można wygenerować faktury! Proszę spróbować ponownie',
  'Récapitulatif de facturation': 'Podsumowanie fakturacji',
  'Demander une modification': 'Poprosić o modyfikację',
  'Valider et envoyer la facture': 'Zatwierdzić i wysłać fakturę',
  'Demande de modification': 'Prośba o modyfikację',
  'La facture a été validé avec succès!':
    'Faktura została pomyślnie zatwierdzona!',
  "Oups un erreur c'est produit!": 'Ups, wystąpił błąd!',
  'Nombre de texte': 'Liczba tekstów',
  'Nombre de mot par texte :': 'Liczba słów na tekst:',
  Paiement: 'Płatność',
  Virement: 'Przelew',
  Délai: 'Termin',
  'Référence de votre facture': 'Numer faktury',
  'Coût au mot': 'Koszt za słowo',
  'Montant global de la commande': 'Całkowita wartość zamówienia',
  'Votre facture': 'Twoja faktura',
  'Importer votre facture': 'Importować fakturę',
  'Pas de fichier disponible': 'Brak dostępnego pliku',
  Télécharger: 'Pobierz',
  Relancer: 'Ponownie wysłać',
  'Générer la facture': 'Generować fakturę',
  Payée: 'Zapłacone',
  'En cours de paiement': 'W trakcie płatności',
  'En cours de validation': 'W trakcie zatwierdzania',
  'A envoyer': 'Do wysłania',
  'Modification demandée': 'Żądanie modyfikacji',
  'Référence de facture est obligatoire': 'Wymagany jest numer faktury',
  'Le coût au mot doit être un nombre': 'Koszt za słowo musi być liczbą',
  'Le coût au mot doit être un nombre positif':
    'Koszt za słowo musi być liczbą dodatnią',
  'Le coût au mot est obligatoire': 'Koszt za słowo jest wymagany',
  'Le montant global de la commande doit être un nombre':
    'Całkowita wartość zamówienia musi być liczbą',
  'Le montant global de la commande doit être un nombre positif':
    'Całkowita wartość zamówienia musi być liczbą dodatnią',
  'Le montant global de la commande est obligatoire':
    'Całkowita wartość zamówienia jest wymagana',
  'Bill reference existe déja , veuillez réessayer':
    'Referencja faktury już istnieje, proszę spróbować ponownie',
  'La facture a été envoyée avec succès!': 'Faktura została pomyślnie wysłana!',

  'Montant estimé': 'Szacowana kwota',
  Action: 'Akcja',
  Facture: 'Faktura',
  Statut: 'Status',
  Factures: 'Faktury',
  'Valider la facture': 'Zatwierdź fakturę',
  'La relance a été envoyée avec succès !':
    'Przesyłka została pomyślnie wysłana!',
  'La demande de modification a été envoyée avec succès !':
    'Żądanie modyfikacji zostało pomyślnie wysłane!',
  'Le cout est trop élevé': 'Koszt jest zbyt wysoki',
  'Le montant global de la commande est trop élevé ':
    'Całkowita kwota zamówienia jest zbyt wysoka',
  jours: 'dni',
  'Factures à envoyer': 'Faktury do wysłania',
  'Valider le paiement': 'Potwierdź płatność',
  'Générer une facture': 'Generuj fakturę',
  'Montant rédacteur': 'Kwota dla autora',
  'Montant estimé par Typix': 'Szacowana kwota przez Typix',
  'Voir la modification': 'Wyświetl modyfikację',

  'Tag de Titre': 'Tag tytułu',
  'Meta description': 'Opis meta',
  'Nom du produit': 'Nazwa produktu',
  Avantages: 'Korzyści',
  'Description détaillée': 'Szczegółowy opis',
  'En cours de modification': 'W trakcie modyfikacji',
  'Mes Factures': 'Moje faktury',
  'En attente de validation': 'Oczekuje na zatwierdzenie',
  'les valeurs négatives ne sont pas autorisées':
    'Ujemne wartości nie są dozwolone.',
  'Texte Seulement': 'Tylko tekst',
  'Traduction Seulement': 'Tylko tłumaczenie',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Przypomnienie można wysłać 20 dni po złożeniu faktury',
  Instructions: 'Instrukcje',
  'Votre avis pour la commande': 'Twoja opinia o zamówieniu',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Twoja ocena jest dla nas cenna! Dziękujemy!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Wystąpił błąd podczas wysyłania Twojej oceny. Spróbuj ponownie później!',
  Evaluer: 'Oceniać',
  Commentaire: 'Komentarz',
  'Votre commantaire': 'Twój komentarz',
  Note: 'Ocena',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Twoja opinia jest dla nas cenna i pomaga nam ulepszać nasze usługi.',
  'Verifier la facture': 'Zweryfikuj fakturę',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Twoja opinia jest dla nas cenna i pomaga nam ulepszać nasze usługi. Dziękujemy za podzielenie się Twoją oceną z nami!',
  'Notes rédacteur': 'Uwagi autora',
  'Avis client': 'Opinie klientów',
  'Pas de commantaire': 'Brak komentarza',
  "Qu'avez vous pensé de votre commande?": 'Co myślałeś o swoim zamówieniu?',
  'Evaluation de la commande': 'Ocena zamówienia',
  'Rédiger un commentaire': 'Napisz komentarz',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Przyczynij się do tego, abyśmy mogli oferować Ci usługi redakcyjne najwyższej jakości, dzieląc się swoimi komentarzami.',
  'Que pouvons-nous améliorer?': 'Co możemy poprawić?',
  Continuer: 'Kontynuuj',
  'Le dernier texte a été validé avec succès':
    'Ostatni tekst został pomyślnie zweryfikowany',
  'Rechercher par rédacteur, commandes, et montant':
    'Wyszukaj według autora, zamówień i kwoty',
  obligatoire: 'obowiązkowy',
  'Note admin': 'Notatka administratora',
  'Note client': 'Notatka klienta',
  Partie: 'Część',
  'Vous venez de valider tous les contenus rédigés par ':
    'Właśnie zatwierdziłeś całą zawartość napisaną przez ',
  "Qu'en avez vous pensé": 'Co o tym sądzisz',
  'La facture a été générée avec succès !':
    'Faktura została wygenerowana pomyślnie!',
  'La taille du titre et/ou la méta-description est trop courte':
    'Długość tytułu i/lub opisu meta jest za krótka',

  'Demande de révision globale': 'Richiedere una revisione globale',
  Valider: 'Zatwierdzić',
  'Obligatoire en cas d’optimisation ou traduction':
    'Wymagane w przypadku optymalizacji lub tłumaczenia',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Twój komentarz...',
  'Ex : "Contenus de très bonne qualité':
    'Przykład: "Treść bardzo dobrej jakości"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Czy mógłbyś/aś proszę przypisać tego/redaktora/dkę do przyszłych zamówień?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Ten przycisk ma informować klienta, że oryginalny tekst zawierał już nadużywanie słów kluczowych',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Edytuj tekst',
  Ajouter: 'Dodaj',
  'Demande examen': 'Wniosek o egzamin',
  'Recherche de mots clés à haute densité': 'Badanie keyword stuffing',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Ups, wygląda na to, że w tekście jest keyword stuffing: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Ups, wygląda na to, że w oryginalnym tekście jest keyword stuffing (zbyt wiele wystąpień jednego lub więcej słów kluczowych).',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Proszę upewnić się, że gęstość słów kluczowych jest zoptymalizowana pod kątem SEO (maksymalnie jedno wystąpienie co 50 słów).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'W tym celu proszę nie edytować istniejącego tekstu i uprzejmie przesłać prośbę o badanie do wsparcia.',
  'Votre demande a été bien envoyée !':
    'Twoje zapytanie zostało pomyślnie wysłane!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Wystąpił problem podczas wysyłania twojego zapytania.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Proszę czekać na analizę keyword stuffing.',
  'Inserez le maillage interne': 'Wstaw wewnętrzną siatkę',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Upewnij się, że gęstość słów kluczowych jest zoptymalizowana pod kątem SEO (maksymalnie jedno wystąpienie co 50 słów).\nJeśli napotkasz termin, który może mieć wiele znaczeń (Przykład: Former / Formation / Form lub Matelas / Matelassé), możesz poprosić o przegląd u wsparcia.',
  'Mots clés à haute densité dans le texte actuel':
    'Słowa kluczowe o wysokiej gęstości w bieżącym tekście',
  'Mots clés à haute densité dans le texte initial':
    'Słowa kluczowe o wysokiej gęstości w tekście początkowym',
  'Texte en cours de rédaction !': 'Tekst w trakcie redakcji !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Czy na pewno chcesz potwierdzić tekst? Upewnij się najpierw, że zapisałeś(aś) zmiany.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Ostatnia chwila informacji! Twój stan kredytów wkrótce będzie wyczerpany :( Aby się zabezpieczyć, śmiało poproś o więcej kredytów teraz, aby zakończyć zamówienie.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Czy chcesz potwierdzić aktualny adres URL czy go zmienić?',
  'Veuillez fournir une URL valide': 'Proszę podać prawidłowy adres URL.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Proszę podać poniżej adres URL strony, na której zawartość została zintegrowana:',
  'Contenu en ligne': 'Zawartość online',
  'Télécharger le document de révision': 'Pobierz dokument rewizji',
  'Date d’envoi': 'Data wysłania',
  Information: 'Informacja',
  Enregistrer: 'Zapisz',
  'Textes en ligne': 'Teksty online',
  'Livrer les textes': 'Dostarczyć teksty',
  Indisponible: 'Niedostępne',
  Disponible: 'Dostępne',
  'Les textes ont été livrés avec succès.':
    'Teksty zostały pomyślnie dostarczone.',
  'Le texte a été livré avec succès.': 'Tekst został pomyślnie dostarczony.',
  'Les textes ont été validés avec succès.':
    'Teksty zostały pomyślnie zweryfikowane.',
  'Le texte a été validé avec succès.': 'Tekst został pomyślnie zweryfikowany.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Czy naprawdę chcesz zatwierdzić teksty zamówienia?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Czy naprawdę chcesz dostarczyć teksty zamówienia?',
  'Fiche produit longue': 'Długa karta produktu',
  'Fiche produit courte': 'Krótka karta produktu',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Tekst jest za długi i nie można go wyeksportować do Excela.',
  'Mettre un texte en brouillon en livré':
    'Zmień tekst z wersji roboczej na dostarczony',
  'Remettre un texte validé à livré': 'Zmień tekst zatwierdzony na dostarczony',
  'Invalider le texte': 'Unieważnij tekst',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Chciał(a)bym zmienić moje hasło: jak to zrobić?',
  'Comment mettre un contenu en bon pour livraison ?':
    'Jak ustawić treść gotową do dostawy?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'Nie mogę zapisać swojej treści jako "szkic"?',
  'Typix n’a pas enregistré mes dernières modifications':
    'Typix nie zapisał moich ostatnich zmian',
  'Comment rédiger une création de contenu ?': 'Jak napisać tworzenie treści?',
  'Comment rédiger une optimisation de contenu ?':
    'Jak napisać optymalizację treści?',
  'Je ne vois plus ma commande – que faire ?':
    'Nie widzę już mojego zamówienia – co robić?',
  'Comment générer une facture ?': 'Jak wygenerować fakturę?',
  'Je ne peux pas generer ma facture : que faire ?':
    'Nie mogę wygenerować mojej faktury: co robić?',
  'Tuto process Typix global': 'Globalny proces Typix - samouczek',
  'Tuto mise en ligne des contenus': 'Jak przesyłać treści - samouczek',
  'Modification mot de passe': 'Zmiana hasła',
  'Le test ne me convient pas': 'Test mi nie odpowiada',
  'Comment valider un contenu dans Typix ?': 'Jak zwalidować treść w Typix?',
  'Tuto Facturation': 'Poradnik fakturowania',
  'Le statut du contenu a bien été modifié':
    'Status treści został pomyślnie zmieniony',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Luka semantyczna tego słowa kluczowego nie została jeszcze zatwierdzona, proszę skontaktować się z pomocą Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'To słowo kluczowe nie istnieje na MONETORING, sprawdź proszę z pomocą Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Odzyskaj lukę',
  'Le statut du contenu a bien été modifié':
    'Status treści został pomyślnie zmieniony',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Luka semantyczna tego słowa kluczowego nie została jeszcze zatwierdzona, proszę skontaktować się z pomocą Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'To słowo kluczowe nie istnieje na MONETORING, sprawdź proszę z pomocą Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Odzyskaj lukę',
  'Traduction premium': 'Tłumaczenie premium',
  'Rédaction et/ou Traduction intelligente':
    'Inteligentne pisanie i/lub tłumaczenie',
  'Rédaction et/ou Traduction premium': 'Pismo premium i/lub tłumaczenie',
  'Rédaction et/ou Traduction avec révision':
    'Pismo i/lub tłumaczenie z korektą',
  'Rédaction et/ou Traduction intelligente':
    'Inteligentne pisanie i/lub tłumaczenie',
  'Traduction avec révision': 'Tłumaczenie z korektą',
  'Traduction intelligente': 'Inteligentne tłumaczenie',
  'Le nombre de mots commandé est supérieur à celui vendu':
    'Liczba zamówionych słów jest większa niż sprzedanych',
  'Rédaction et/ou Traduction premium': 'Pisemna i/lub premium tłumaczenie',
  'Rédaction et/ou Traduction smart + review':
    'Inteligentne pisanie i/lub tłumaczenie + przegląd',
  'Rédaction et/ou Traduction smart': 'Inteligentne pisanie i/lub tłumaczenie',
  'Nom Silo': 'Nazwa Silo',
  'Remettre en brouillon': 'Przywróć do wersji roboczej',
  'Bon pour Livraison Vérifié': 'Potwierdzone do dostarczenia',
  'Voulez-vous vraiment remettre en brouillon les Textes de la commande':
    'Czy na pewno chcesz przywrócić teksty zamówienia do wersji roboczej',
  'La vérification du texte a été annulée.':
    'Weryfikacja tekstu została anulowana.',
  'Voulez-vous vraiment annuler la vérification du texte?':
    'Czy na pewno chcesz anulować weryfikację tekstu?',
  'Etes-vous sûr(e) de vouloir regénérer tous les contenus ?':
    'Czy na pewno chcesz wygenerować całą zawartość ponownie?',
  'Etes-vous sûr(e) de vouloir regénérer tous les contenus ?':
    'Czy na pewno chcesz wygenerować całą zawartość ponownie?',
  'Je suis sûr(e)': 'Jestem pewny/a',
  'Non, revenir en arrière': 'Nie, wróć',
  'à cocher si la commande a l’intention d’avoir un rédacteur exclusif pour le client':
    'zaznacz, jeśli zamówienie ma zamiar mieć wyłącznego redaktora dla klienta',
  Gouvernance: 'Zarządzanie',
  'Modification à valider': 'Modyfikacja do zatwierdzenia',
  Rédacteur: 'Redaktor',
};
