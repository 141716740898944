import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axiosPrivate from 'config/axiosPrivate';

import { writerOrderListSelector } from 'store/Writer/OrderList';
import { writerProjectListSelector } from 'store/Writer/ProjectList';

import { UserSessionSelector, updateShowTutorial } from 'store/UserSession';
import { fetchAllOrders } from 'store/Writer/OrderList/features';
import { fetchProjectList } from 'store/Writer/ProjectList/features';

import UserOrderProgressCards from 'components/shared/UserOrderProgressCards';
import UserProgressTable from 'components/shared/UserProgressTable';

import { VideoInstructionsModal } from 'components/shared/modal/VideoInstructionsModal';

function DashboardPage() {
  const dispatch = useDispatch();
  const {
    inProgressOrders,
    delayedOrders,
    validatedOrders,
    nonAttributedCommands,
    smartWithReviewOrders,
    isLoading,
  } = useSelector(writerOrderListSelector);

  const { user, showTutorial, flag } = useSelector(UserSessionSelector);

  const { projects, isLoadingProjects } = useSelector(
    writerProjectListSelector
  );
  const [showInstructions, setShowInstructions] = useState(true);
  const [isTutorialVisible, setIsTutorialVisible] = useState(false);

  useEffect(() => {
    document.title = 'Typix - Mon tableau de bord';
    window.dispatchEvent(new Event('locationchange'));
    dispatch(fetchAllOrders());
    dispatch(fetchProjectList());
  }, [dispatch]);

  useEffect(() => {
    if (showTutorial && flag) {
      setIsTutorialVisible(true);
    } else {
      setIsTutorialVisible(false);
    }
  }, [flag, showTutorial]);

  const handleOnchange = () => {
    axiosPrivate.put('/user/deactivateTutorial').then(() => {
      setIsTutorialVisible(false);
      dispatch(updateShowTutorial(false));
    });
  };

  const column = [{ heading: 'Name', value: 'urlSite' }];

  const navigationPath = {
    ordersDisplay: '/Redacteur/AffichageDesCommandes',
    orderProgressCards: {
      validatedOrders: '/Redacteur/CommandesValides',
      inProgressOrders: '/Redacteur/CommandesEnCours',
      delayedOrders: '/Redacteur/CommandesEnRetard',
      nonAttributedCommands: '/Redacteur/CommandesNonAttribuée',
      reviewOrders: '/Redacteur/CommandesReview',
    },
  };

  return (
    <div>
      <div className="content-center">
        <div className="mb-2">
          <h1>Dashboard</h1>
        </div>
        <div className="row">
          <UserProgressTable
            column={column}
            userData={projects}
            isLoading={isLoadingProjects}
            path={navigationPath.ordersDisplay}
          />
          <UserOrderProgressCards
            inProgressOrders={inProgressOrders}
            delayedOrders={delayedOrders}
            validatedOrders={validatedOrders}
            nonAttributedCommands={nonAttributedCommands}
            smartWithReviewOrders={smartWithReviewOrders}
            isLoading={isLoading}
            path={navigationPath.orderProgressCards}
            user={user}
          />
        </div>
        {isTutorialVisible && (
          <VideoInstructionsModal
            showInstruction={showInstructions}
            setShowInstruction={() => setShowInstructions(false)}
            showInfoBanner={true}
            user={user}
            handleOnchange={handleOnchange}
          />
        )}
      </div>
    </div>
  );
}

export default DashboardPage;
