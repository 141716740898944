import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'reactstrap';
import * as Yup from 'yup';
import { Form } from '@unform/web';

import { classes } from './modalClasses';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import DateInputField from 'components/shared/InputField/DateInputField';
import InputFieldWithArrow from '../InputField/InputFieldWithArrow';

import axiosPrivate from 'config/axiosPrivate';
import { applicationSchema } from 'utils/schemas';
import { fetchAllOrders } from 'store/Writer/OrderList/features';

function ApplicationModal({
  showApplication,
  handleClose,
  orderId,
  orderTitle,
  totalText,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [textAmount, setTextAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [minWords, setMinWords] = useState(0);
  const [maxWords, setMaxWords] = useState(0);
  const [deadline, setDeadline] = useState(null);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const formRef = useRef(null);

  async function handleSubmit(data) {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      await applicationSchema.validate(data, {
        context: { total: totalText },
        abortEarly: false,
      });

      // Validation passed
      const formattedDeadline = new Date(deadline).toISOString();
      const applicationData = {
        deadline: formattedDeadline,
        maxWordsAmount: parseFloat(data.Max),
        minWordsAmount: parseFloat(data.Min),
        price: parseFloat(data.price),
        textAmount: parseFloat(data.textAmount),
      };

      axiosPrivate
        .post(`/commands/${orderId}/apply`, applicationData)
        .then(() => {
          dispatch(fetchAllOrders());
          handleClose();
          setErrorSuccessMsg(t(`Votre candidature a bien été envoyée`));
          setIsSuccess(true);
          setErrorSuccess(true);
        })
        .catch(() => {
          handleClose();
          setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
          setIsSuccess(false);
          setErrorSuccess(true);
        });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = t(error.message);
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }
  return (
    <div>
      <Modal style={classes.lmodalCustom} isOpen={showApplication}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div style={classes.modalHeader} className="modal-header">
            <h5 style={classes.title} className="modal-title">
              {t('Postuler à la commande')}:
              <br /> {orderTitle}
            </h5>
            <button
              type="button"
              style={classes.btnClose}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>
          <div style={classes.modalPad} className="modal-body">
            <div className="row row-mb-lg">
              <div className="col-12 col-md-6">
                <div className="label-title">{t('Nombre de textes')}</div>
                <InputFieldWithArrow
                  type="number"
                  name="textAmount"
                  value={textAmount}
                  onChange={(e) => setTextAmount(e.target.value)}
                />
              </div>

              <div className="col-12 col-md-6">
                <div className="label-title">{t('Tarif au mot')}</div>
                <InputFieldWithArrow
                  name="price"
                  type="number"
                  className="form-control form-ct"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="row row-mb-lg">
              <div className="label-title">{t('Nombre de mots par texte')}</div>
              <div className="col-12 col-md-6">
                <InputFieldWithArrow
                  name="Min"
                  type="number"
                  className="form-control form-ct"
                  value={minWords}
                  onChange={(e) => setMinWords(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputFieldWithArrow
                  name="Max"
                  type="number"
                  className="form-control form-ct"
                  value={maxWords}
                  onChange={(e) => setMaxWords(e.target.value)}
                />
              </div>
            </div>

            <div className="row row-mb-lg">
              <div className="label-title">{t('Livraison estimé au')} :</div>
              <div className="col-12 col-md-12">
                <DateInputField
                  type="date"
                  name="deadline"
                  className="form-control form-ct"
                  onChange={(e) => setDeadline(e.target.value)}
                />
              </div>
            </div>

            <div style={classes.boxBtConfirmRight}>
              <button
                style={{ backgroundColor: '#ee3900' }}
                className="confirm me-2"
                onClick={handleClose}
              >
                {t('Annuler')}
              </button>
              <button
                style={{ backgroundColor: '#43b929' }}
                className="confirm"
                type="submit"
              >
                {t('Postuler')}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}

export default ApplicationModal;
